import useStore from '@/store/use-store';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid, Stack, TextField } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import TranslationForm from '../../../TranslationForm';
// ----------------------------------------------------------------------
EngineTitleForm.propTypes = {
  submitCallback: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  engineTitle: PropTypes.object.isRequired
};

export default function EngineTitleForm({ engineTitle, submitCallback, onCancel }) {
  const { t } = useTranslation();
  const { createEngineTitle, updateEngineTitle } = useStore((state) => state);

  const EngineTitleSchema = Yup.object().shape({
    title: Yup.string().required(t('required'))
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: engineTitle.id || '',
      title: engineTitle.title || ''
    },
    validationSchema: EngineTitleSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const action = values.id ? updateEngineTitle : createEngineTitle;
      action(values).then((res) => submitCallback(res));
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <>
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  label={t('engine_title.title')}
                  {...getFieldProps('title')}
                  error={Boolean(touched?.title && errors?.title)}
                  helperText={touched?.title && errors?.title}
                />
              </Stack>
              <Grid container spacing={3} padding={3}>
                <Grid item xs={12} md={6}>
                  <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-start' }}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="error"
                      onClick={() => onCancel()}
                    >
                      {t('cancel')}
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                      {t('save')}
                    </LoadingButton>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
      <TranslationForm
        parentEntity={engineTitle}
        parentEntityType="EngineName"
        translationGroup="engine_title"
      />
    </>
  );
}
