import PulsatingDots from '@/components/PulsatingDots';
import useStore from '@/store/use-store';
import {
  Card,
  CardHeader,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fDate, fDateTime } from '../../../../../utils/formatTime';

RequestHistory.propTypes = {
  request: PropTypes.object,
  loading: PropTypes.bool
};

export default function RequestHistory({ request, loading }) {
  const { t } = useTranslation();
  const [actions, setActions] = useState([]);
  const [shipActions, setShipActions] = useState([]);
  const { getLabelRequestHistory, loadingLabelRequestHistory, getShipActions } = useStore(
    (state) => state
  );
  console.log(shipActions);
  useEffect(() => {
    if (request) {
      getLabelRequestHistory(request.id).then((res) => setActions(res));
      getShipActions(request.ship.id).then((res) => setShipActions(res));
    }
  }, [getLabelRequestHistory, getShipActions, request]);

  if (loading || loadingLabelRequestHistory) {
    return (
      <Card>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <PulsatingDots size={15} />
        </div>
      </Card>
    );
  }

  return (
    <Grid key="requestDocuments" container spacing={3}>
      <Grid item xs={12}>
        <Stack spacing={3}>
          <Card>
            <CardHeader style={{ paddingBottom: '1rem' }} title={t('request.label_history')} />
            <TableContainer sx={{ minWidth: 800, padding: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('action')}</TableCell>
                    <TableCell>{t('performer')}</TableCell>
                    <TableCell>{t('date')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {actions.map((action) => (
                    <TableRow hover key={action.id} tabIndex={-1}>
                      <TableCell>{action.description}</TableCell>
                      <TableCell>{action.performer.name}</TableCell>
                      <TableCell>{fDate(action.timestamp)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={3}>
          <Card>
            <CardHeader style={{ paddingBottom: '1rem' }} title={t('request.history')} />
            <TableContainer sx={{ minWidth: 800, padding: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('action')}</TableCell>
                    <TableCell>{t('performer')}</TableCell>
                    <TableCell>{t('date')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {shipActions.map((action) => (
                    <TableRow hover key={action.id} tabIndex={-1}>
                      <TableCell>{action.description}</TableCell>
                      <TableCell>{action.performer.name}</TableCell>
                      <TableCell>{fDateTime(action.timestamp)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Stack>
      </Grid>
    </Grid>
  );
}
