import { Box, Checkbox, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import PropTypes from 'prop-types';

UserListHead.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  headLabel: PropTypes.array,
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func
};

export default function UserListHead({
  order,
  orderBy,
  rowCount,
  headLabel,
  numSelected,
  onRequestSort,
  onSelectAllClick
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow
        sx={{
          border: '1px solid grey',
          borderRadius: '4px',
          overflow: 'hidden'
        }}
      >
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {headLabel.map((headCell) => {
          const isActive = orderBy === headCell.id;

          return (
            <TableCell
              key={headCell.id}
              align={headCell.alignRight ? 'right' : 'left'}
              sortDirection={isActive ? order : false}
              sx={{
                fontWeight: 'bold',
                bgcolor: isActive ? 'grey.200' : 'transparent',
                transition: 'background-color 0.3s'
              }}
            >
              <TableSortLabel
                hideSortIcon
                active={isActive}
                direction={isActive ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                sx={{
                  '&.Mui-active': {
                    fontWeight: 'bold',
                    textDecoration: 'underline'
                  },
                  '&:focus-visible': {
                    outline: '2px solid #1976d2',
                    outlineOffset: '2px',
                    borderRadius: '4px'
                  }
                }}
              >
                {headCell.label}
                {isActive ? (
                  <Box sx={{ ...visuallyHidden }}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}
