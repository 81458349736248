import { MoreMenuButton } from '@/components/MoreMenuButton';
import useStore from '@/store/use-store';
import clockOutline from '@iconify/icons-eva/clock-outline';
import editFill from '@iconify/icons-eva/edit-fill';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import { Icon } from '@iconify/react';
// material
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------
EngineMoreMenu.propTypes = {
  id: PropTypes.number,
  editEngine: PropTypes.func,
  showEngineHours: PropTypes.func
};

export default function EngineMoreMenu({ id, editEngine, showEngineHours }) {
  const ref = useRef(null);
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { removeEngine } = useStore((state) => state);

  const onDeleteClick = () => {
    removeEngine(id);
    setIsOpen(false);
  };

  return (
    <>
      <MoreMenuButton isOpen={isOpen} setIsOpen={setIsOpen} menuId="engine-more-menu" ref={ref} />

      <Menu
        open={isOpen}
        id="engine-more-menu"
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem sx={{ color: 'text.secondary' }} onClick={() => editEngine(id)}>
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary={t('edit')} primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        {showEngineHours && (
          <MenuItem sx={{ color: 'text.secondary' }} onClick={() => showEngineHours(id)}>
            <ListItemIcon>
              <Icon icon={clockOutline} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary={t('show_hours')} primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
        <MenuItem sx={{ color: 'text.secondary' }} onClick={() => onDeleteClick()}>
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary={t('delete')} primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
}
