import useStore from '@/store/use-store';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { Icon } from '@iconify/react';
import { LoadingButton } from '@mui/lab';
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';

export default function LoginForm() {
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();
  const login = useStore((state) => state.login);
  const authenticating = useStore((state) => state.authenticating);
  const [error, setError] = useState<boolean>(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email(t('email_invalid')).required(t('email_required')),
    password: Yup.string().required(t('password_required'))
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validateOnBlur: true,
    validateOnChange: false,
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      setError(false); // Reset error state
      login(values.email, values.password, values.remember).catch(() => setError(true)); // Set error on login failure
    }
  });

  if (!formik.isSubmitting && authenticating) formik.setSubmitting(true);
  if (formik.isSubmitting && !authenticating) formik.setSubmitting(false);

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            id="email"
            autoComplete="email"
            type="email"
            autoFocus
            label={t('email_address')}
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
            aria-label={t('email_address')}
          />

          <TextField
            fullWidth
            id="password"
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label={t('password')}
            aria-label={t('password')}
            {...getFieldProps('password')}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleShowPassword}
                      aria-label={t(showPassword ? 'hide_password' : 'show_password')}
                      edge="end"
                    >
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }
            }}
          />

          {error && (
            <Typography role="alert" aria-live="assertive" color="error" sx={{ mt: 2 }}>
              {t('login_failed')}{' '}
            </Typography>
          )}
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={
              <Checkbox
                {...getFieldProps('remember')}
                checked={values.remember}
                inputProps={{ 'aria-label': t('remember_me') }}
              />
            }
            label={t('remember_me')}
          />

          <Link
            component={RouterLink}
            variant="subtitle2"
            to={'/forgot'}
            aria-label={t('forgot_password')}
          >
            {t('forgot_password')}
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          aria-label={t('login')}
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          {t('login')}
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
