import PulsatingDots from '@/components/PulsatingDots';
import useStore from '@/store/use-store';
import cloudDownloadOutline from '@iconify/icons-eva/cloud-download-outline';
import { Icon } from '@iconify/react';
import { Link } from '@mui/material';
import i18n from 'i18next';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { DownloadCell } from '../layouts/styles';

export default function useDownloadList() {
  const [downloadList, setDownloadList] = useState([]);
  const getFile = useStore((state) => state.getFile);

  function downloadFile(file) {
    setDownloadList([...downloadList, file]);
    getFile(file)
      .catch(() => {
        toast.error(i18n.t('something_wrong'));
      })
      .finally(() => {
        const downloads = downloadList.filter((element) => element !== file);
        setDownloadList(downloads);
      });
  }

  function downloadIcon(fileName) {
    if (downloadList.includes(fileName)) return <PulsatingDots key={fileName} size={5} />;

    return (
      <Icon
        key={fileName}
        sx={{ padding: '2rem' }}
        onClick={() => downloadFile(fileName)}
        icon={cloudDownloadOutline}
      />
    );
  }

  function downloadLink(fileName, title) {
    return (
      <Link underline="hover" color="inherit" href="#" onClick={() => downloadFile(fileName)}>
        {title}
      </Link>
    );
  }

  function downloadCell(fileName) {
    return (
      <DownloadCell onClick={() => downloadFile(fileName)}>
        {downloadList.includes(fileName) ? (
          <PulsatingDots size={5} />
        ) : (
          <Icon icon={cloudDownloadOutline} />
        )}
      </DownloadCell>
    );
  }

  return {
    downloadCell,
    downloadFile,
    downloadLink,
    downloadButton: downloadIcon
  };
}
