import { useRef, useState } from 'react';
import { alpha, styled } from '@mui/material/styles';
import { Box, MenuItem, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import useLocales from '../hooks/useLocales';
import { MIconButton } from './@material-extend';
import MenuPopover from './MenuPopover';
import { fStringCapitalizeFirstLetter } from '../utils/formatString';

const RootStyle = styled('div')(({ theme }) => ({
  zIndex: 9,
  width: '100%',
  marginBottom: 15,
  backgroundColor: theme.palette.background.paper
}));

LanguageBar.propTypes = {
  onChangeLanguage: PropTypes.func.isRequired,
  language: PropTypes.object.isRequired,
  title: PropTypes.string
};

export default function LanguageBar({ onChangeLanguage, language, title }) {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { allLang } = useLocales();
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeLang = (value) => {
    onChangeLanguage(value);
    handleClose();
  };

  return (
    <RootStyle>
      <Stack direction="row" justifyContent="space-between" spacing={{ xs: 0.5, sm: 1.5 }}>
        <Typography variant="h5" sx={{ pt: 1 }}>
          {fStringCapitalizeFirstLetter(title)}
        </Typography>
        <MIconButton
          ref={anchorRef}
          onClick={handleOpen}
          sx={{
            padding: 0,
            width: 44,
            height: 44,
            ...(open && {
              bgcolor: (theme) =>
                alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
            })
          }}
        >
          <img src={language.icon} alt={language.label} />
        </MIconButton>
        <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ py: 1 }}>
          {allLang.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === language.value}
              onClick={() => handleChangeLang(option)}
              sx={{ py: 1, px: 2.5 }}
            >
              <ListItemIcon>
                <Box component="img" alt={option.label} src={option.icon} />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                {option.label}
              </ListItemText>
            </MenuItem>
          ))}
        </MenuPopover>
      </Stack>
    </RootStyle>
  );
}
