import { Box, Card, Container, Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import flashOutline from '@iconify/icons-eva/flash-outline';
import { capitalCase } from 'change-case';
import Page from '../../components/Page';
import { PATH_ADMIN } from '../../routes/paths';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import FuelsCover from '../../components/FuelsCover';
import FuelTypeList from '../../components/_dashboard/admin/fuels/FuelTypeList';
import FuelList from '../../components/_dashboard/admin/fuels/FuelList';

const TabsWrapperStyle = styled('div')(({ theme }) => ({
  zIndex: 9,
  bottom: 0,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.up('sm')]: {
    justifyContent: 'center'
  },
  [theme.breakpoints.up('md')]: {
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(3)
  }
}));

export default function AdminFuels() {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState('fueltype');

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const TABS = [
    {
      value: 'fueltype',
      title: t('fuel_type.type'),
      enabled: true,
      icon: <Icon icon={flashOutline} width={20} height={20} />,
      component: <FuelTypeList />
    },
    {
      value: 'fuel',
      title: t('fuels.fuel'),
      enabled: true,
      icon: <Icon icon={flashOutline} width={20} height={20} />,
      component: <FuelList />
    }
  ];

  return (
    <Page title={t('fuels.fuel')}>
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading={t('fuels.fuel')}
          links={[{ name: 'Dashboard', href: PATH_ADMIN.root }, { name: t('fuels.fuel') }]}
        />
        <Card
          sx={{
            mb: 3,
            height: 280,
            position: 'relative'
          }}
        >
          <FuelsCover title={t('fuels.fuel')} />

          <TabsWrapperStyle>
            <Tabs
              value={currentTab}
              scrollButtons="auto"
              variant="scrollable"
              allowScrollButtonsMobile
              onChange={handleChangeTab}
            >
              {TABS.map((tab) => (
                <Tab
                  disabled={!tab.enabled}
                  disableRipple
                  key={tab.value}
                  value={tab.value}
                  icon={tab.icon}
                  label={capitalCase(tab.title)}
                />
              ))}
            </Tabs>
          </TabsWrapperStyle>
        </Card>

        {TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </Container>
    </Page>
  );
}
