import Loader from '@/components/Loader';
import ReportForm from '@/components/_dashboard/ship/report/report-form';
import type { WizardFormMethods, WizardStepComponentProps } from '@/components/wizard/wizard';
import useStore from '@/store/use-store';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import type React from 'react';
import { useRef } from 'react';
import { useCallback } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const YearReportStep: React.FC<Partial<WizardStepComponentProps>> = ({
  registerBeforeNext,
  triggerPrevious,
  setCanProceed,
  setSkipNext
}) => {
  const { t } = useTranslation();
  const { wizardShip, getReportByYear, loadingReportByYear, getEngines } = useStore(
    (state) => state
  );
  const [report, setReport] = useState(null);
  const year = new Date().getFullYear() - 1;
  const formRef = useRef<WizardFormMethods>(null);

  useEffect(() => {
    setCanProceed?.(true);
    setSkipNext?.(false);
  }, [setCanProceed, setSkipNext]);

  useEffect(() => {
    getReportByYear(wizardShip.id, year).then((res) => {
      if (res) setReport(res);
    });
  }, [wizardShip, getReportByYear, year]);

  useEffect(() => {
    if (registerBeforeNext) {
      registerBeforeNext(async () => {
        try {
          if (formRef.current) {
            return await formRef.current.submit();
          }
          return false;
        } catch (error) {
          console.error('Error during form submission:', error);
          return false;
        }
      });
    }
  }, [registerBeforeNext]);

  const handleFormStateChange = useCallback(
    ({
      isValid
    }: {
      isValid: boolean;
    }) => {
      setCanProceed?.(isValid);
    },
    [setCanProceed]
  );

  return (
    <Grid container spacing={3} sx={{ p: 3 }}>
      <Grid size={12}>
        <Typography variant="h4" gutterBottom>
          {t('introduction.report')}
        </Typography>
        {loadingReportByYear ? (
          <Loader text={t('report.loading_report')} />
        ) : (
          <ReportForm
            onStateChange={handleFormStateChange}
            showOnlyEssential={report !== null}
            onCancel={triggerPrevious}
            ship={wizardShip}
            cancelButtonLabel={t('previous')}
            submitButtonLabel={t('next')}
            report={report}
            ref={formRef}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default YearReportStep;
