import searchFill from '@iconify/icons-eva/search-fill';
import { Icon } from '@iconify/react';
import { Box, InputAdornment, TextField, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

ShipListToolbar.propTypes = {
  filterName: PropTypes.string,
  onFilterName: PropTypes.func
};

export default function ShipListToolbar({ filterName, onFilterName }) {
  const { t } = useTranslation();
  const [isFocussed, setIsFocussed] = useState(true);
  return (
    <RootStyle>
      <TextField
        value={filterName}
        onChange={onFilterName}
        placeholder={t('search_ship')}
        label={t('search_ship')}
        sx={(theme) => ({
          width: isFocussed ? 320 : 240,
          transition: theme.transitions.create(['box-shadow', 'width'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter
          }),
          boxShadow: isFocussed && theme.customShadows.z8
        })}
        onFocus={() => setIsFocussed(true)}
        onBlur={() => setIsFocussed(false)}
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            )
          }
        }}
      />
    </RootStyle>
  );
}
