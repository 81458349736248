import type { WizardStepComponentProps } from '@/components/wizard/wizard';
import { PATH_CLIENT } from '@/routes/paths';
import useStore from '@/store/use-store';
import { Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';
import type React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import EngineInfo from '../../admin/request/overview/info/engine-info';
import ShipInfo from '../../admin/request/overview/info/ship-info';
import AnnualReportOverview from '../../info/ship/AnnualReportOverview';

const RequestOverviewStep: React.FC<Partial<WizardStepComponentProps>> = ({
  setCanProceed,
  registerBeforeNext
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { wizardShip, createRequest, savingRequest } = useStore((state) => state);

  const currentYear = new Date().getFullYear();

  // Registreer de handleRequest functie als beforeNext action
  useEffect(() => {
    registerBeforeNext(handleRequest);
    setCanProceed(true);

    return () => {
      // Cleanup: reset canProceed bij unmount
      setCanProceed(false);
    };
  }, [registerBeforeNext, setCanProceed]);

  const handleRequest = async (): Promise<boolean> => {
    try {
      await createRequest(wizardShip);

      const successMessage = t('request.added');
      toast.success(successMessage);

      navigate(PATH_CLIENT.request);
      return true;
    } catch (error) {
      const errorMessage = t('generic_error');
      toast.error(errorMessage);
      console.error('Request creation failed:', error);
      return false;
    }
  };

  return (
    <section aria-labelledby="request-info-title" className="mt-5">
      <h1 id="request-info-title" className="sr-only">
        {t('request.info_title')}
      </h1>
      <Grid container spacing={3}>
        <Grid size={12}>
          <Stack spacing={3} className="mb-5">
            <section aria-labelledby="ship-info-title">
              <h2 id="ship-info-title" className="sr-only">
                {t('ship.info_title')}
              </h2>
              <ShipInfo ship={wizardShip} loading={false} />
            </section>

            <section aria-labelledby="engine-info-title">
              <h2 id="engine-info-title" className="sr-only">
                {t('engine.info_title')}
              </h2>
              <EngineInfo ship={wizardShip} />
            </section>

            {/*<section aria-labelledby="engine-hours-title">*/}
            {/*  <h2 id="engine-hours-title" className="sr-only">*/}
            {/*    {t('engine.hours_title')}*/}
            {/*  </h2>*/}
            {/*  <EngineHoursTable ship={wizardShip} year={currentYear - 1} />*/}
            {/*</section>*/}

            <section aria-labelledby="annual-report-title">
              <h2 id="annual-report-title" className="sr-only">
                {t('report.annual_title')}
              </h2>
              <AnnualReportOverview ship={wizardShip} year={currentYear - 1} />
            </section>
          </Stack>
        </Grid>
      </Grid>
    </section>
  );
};

export default RequestOverviewStep;
