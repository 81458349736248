import useStore, { type StoreState } from '@/store/use-store';
import { LoadingButton } from '@mui/lab';
import { Stack, TextField } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

interface ForgotPasswordFormValues {
  email: string;
}

export default function ForgotPasswordForm() {
  const { t } = useTranslation();
  const { passwordReset, resetting } = useStore((state: StoreState) => state);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email(t('email_invalid')).required(t('email_required'))
  });

  const formik = useFormik<ForgotPasswordFormValues>({
    initialValues: {
      email: ''
    },
    validationSchema: LoginSchema,
    onSubmit: (values: ForgotPasswordFormValues) => {
      passwordReset(values.email);
    }
  });

  if (!formik.isSubmitting && resetting) formik.setSubmitting(true);
  if (formik.isSubmitting && !resetting) formik.setSubmitting(false);

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            id="email"
            autoComplete="username"
            autoFocus
            type="email"
            label={t('email_adress')}
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
            aria-label={t('email_adress')}
          />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            aria-label={t('reset_password')}
          >
            {t('reset')}
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
