import { fData } from '@/utils/formatNumber';
import closeFill from '@iconify/icons-eva/close-fill';
import fileFill from '@iconify/icons-eva/file-fill';
import { Icon } from '@iconify/react';
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  type SxProps,
  type Theme,
  Typography
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { isString } from 'lodash';
import type React from 'react';
import { type DropzoneOptions, type FileRejection, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { UploadIllustration } from '../../_assets';

interface CustomFile extends File {
  originalName?: string;
  path?: string;
  preview?: string;
}

interface BaseUploadFileProps extends DropzoneOptions {
  error?: boolean;
  sx?: SxProps<Theme>;
  subtext?: string;
  helperText?: string;
  onRemove: (file: File | CustomFile) => void;
}

interface SingleFileProps extends BaseUploadFileProps {
  singleFile: true;
  file: File | CustomFile;
}

interface MultiFileProps extends BaseUploadFileProps {
  singleFile: false;
  files: (File | CustomFile)[];
  onRemoveAll: () => void;
}

type UploadFileProps = SingleFileProps | MultiFileProps;

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(1, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.grey[200],
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': {
    opacity: 0.72,
    cursor: 'pointer',
    backgroundColor: theme.palette.action.hover
  },
  '&:focus-within': {
    outline: `2px solid ${theme.palette.primary.main}`,
    outlineOffset: '2px'
  },
  [theme.breakpoints.up('md')]: {
    textAlign: 'left',
    flexDirection: 'row'
  }
}));

export default function UploadFile(props: UploadFileProps) {
  const { error = false, onRemove, sx = {}, subtext, helperText, ...other } = props;
  const isSingleFile = (props: UploadFileProps): props is SingleFileProps => {
    return props.singleFile === true;
  };

  const hasFile = isSingleFile(props) ? Boolean(props.file) : props.files.length > 0;

  const { t } = useTranslation();

  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: !isSingleFile,
    ...other
  });

  const ShowRejectionItems = () => (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        mt: 3,
        borderColor: 'error.light',
        bgcolor: (theme) => alpha(theme.palette.error.main, 0.08)
      }}
      role="alert"
      aria-live="polite"
    >
      {fileRejections.map(({ file, errors }: FileRejection) => {
        const { name, webkitRelativePath: path } = file;
        return (
          <Box key={path} sx={{ my: 1 }}>
            <Typography variant="subtitle2" noWrap>
              {name}
            </Typography>
            {errors.map((e) => (
              <Typography key={e.code} variant="caption" component="p" color="error">
                - {e.message}
              </Typography>
            ))}
          </Box>
        );
      })}
    </Paper>
  );

  const getFileName = (file: string | File | CustomFile): string => {
    return isString(file)
      ? file.toString()
      : 'originalName' in file
        ? file.originalName
        : file.name;
  };

  const getFileSize = (file: string | CustomFile): string => {
    return isString(file) ? '' : fData(file.size);
  };

  const getFileId = (file: File | CustomFile, index: number): string => {
    const fileName = getFileName(file);
    const fileSize = file.size;
    return `${fileName}-${fileSize}-${index}`;
  };

  const renderFilePreview = () => {
    if (!hasFile) return null;

    const filesToMap = isSingleFile(props) ? [props.file] : props.files;
    return (
      <List disablePadding sx={{ ...(hasFile && { my: 3 }) }} aria-label={t('upload.file_list')}>
        {filesToMap.map((file, index) => (
          <ListItem
            key={getFileId(file, index)}
            sx={{
              my: 1,
              py: 0.75,
              px: 2,
              borderRadius: 1,
              border: (theme) => `solid 1px ${theme.palette.divider}`,
              bgcolor: 'background.paper'
            }}
            secondaryAction={
              <IconButton
                edge="end"
                size="small"
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation();
                  onRemove(file);
                }}
                aria-label={`${t('remove')} ${getFileName(file)}`}
              >
                <Icon icon={closeFill} />
              </IconButton>
            }
          >
            <ListItemIcon>
              <Icon icon={fileFill} width={28} height={28} aria-hidden="true" />
            </ListItemIcon>
            <ListItemText
              primary={getFileName(file)}
              secondary={getFileSize(file)}
              primaryTypographyProps={{ variant: 'subtitle2' }}
              secondaryTypographyProps={{ variant: 'caption' }}
            />
          </ListItem>
        ))}
      </List>
    );
  };

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter'
          })
        }}
        aria-label={t('upload.drop')}
        onKeyDown={(event) => {
          if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            event.stopPropagation();
            // Trigger the file input
            const fileInput = document.querySelector('input[type="file"]');
            if (fileInput) {
              (fileInput as HTMLElement).click();
            }
          }
        }}
      >
        <input {...getInputProps()} aria-label={t('upload.input_label')} />

        <UploadIllustration sx={{ width: 220 }} role="img" aria-label={t('upload.illustration')} />

        <Box sx={{ width: '100%', p: 0, ml: { md: 2 } }}>
          <Typography gutterBottom variant="h5" component="h2">
            {t('upload.drop')}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {subtext}
          </Typography>
          {error && helperText && (
            <Typography variant="body2" sx={{ color: 'text.error' }}>
              {helperText}
            </Typography>
          )}

          {renderFilePreview()}
        </Box>
      </DropZoneStyle>

      {fileRejections.length > 0 && <ShowRejectionItems />}

      {hasFile && !isSingleFile(props) && (
        <Stack direction="row" justifyContent="flex-end">
          <Button onClick={props.onRemoveAll} sx={{ mr: 1.5 }} aria-label={t('remove_all')}>
            {t('remove_all')}
          </Button>
        </Stack>
      )}
    </Box>
  );
}
