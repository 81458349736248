import Loader from '@/components/Loader';
import { DetailCard } from '@/layouts/styles';
import type { Ship } from '@/types/ship';
import { CardHeader, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useTranslation } from 'react-i18next';

interface ShipInfoProps {
  ship?: Ship | null;
  loading?: boolean;
}

interface ShipDetailRowProps {
  label: string;
  value: string | number;
  unit?: string;
}

// Helper component voor consistente detail rijen
const ShipDetailRow: React.FC<ShipDetailRowProps> = ({ label, value, unit }) => (
  <>
    <Grid size={3}>
      <Typography variant="body2" component="dt" sx={{ fontWeight: 'medium' }}>
        {label}:
      </Typography>
    </Grid>
    <Grid size={9}>
      <Typography variant="body2" component="dd" sx={{ margin: 0 }}>
        {value}
        {unit && ` ${unit}`}
      </Typography>
    </Grid>
  </>
);

const ShipInfo: React.FC<ShipInfoProps> = ({ ship, loading = false }) => {
  const { t } = useTranslation();

  if (loading) {
    return (
      <DetailCard>
        <CardHeader
          title={t('request.ship_info')}
          component="h2"
          aria-label={t('request.ship_info_loading')}
        />
        <Loader text={t('ship.loading_deatils')} />
      </DetailCard>
    );
  }

  if (!ship) {
    return (
      <DetailCard>
        <CardHeader title={t('request.ship_info')} component="h2" />
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: '100px' }}
        >
          <Grid size={3}>
            <Typography variant="body2" role="alert" aria-live="polite">
              {t('no_data')}
            </Typography>
          </Grid>
        </Grid>
      </DetailCard>
    );
  }

  return (
    <DetailCard>
      <CardHeader title={t('request.ship_info')} component="h2" id="ship-info-title" />
      <Stack spacing={2} sx={{ p: 3 }} component="dl" aria-labelledby="ship-info-title">
        <Stack direction="row">
          <Grid container>
            <ShipDetailRow label={t('ship.title')} value={ship.title} />
            <ShipDetailRow label={t('ship.type')} value={ship.type.description} />
            <ShipDetailRow label={t('ship.eni')} value={ship.eni} />
            <ShipDetailRow label={t('ship.length')} value={ship.length} unit="m" />
            <ShipDetailRow label={t('ship.width')} value={ship.width} unit="m" />
            <ShipDetailRow label={t('ship.payloadTonne')} value={ship.payloadTonne} />
            <ShipDetailRow label={t('ship.payloadTEU')} value={ship.payloadTEU} />
            <ShipDetailRow label={t('ship.payloadQubicMeters')} value={ship.payloadQubicMeters} />
            <ShipDetailRow label={t('ship.payloadPassengers')} value={ship.payloadPassengers} />
            <ShipDetailRow label={t('ship.year')} value={ship.year} />
          </Grid>
        </Stack>
      </Stack>
    </DetailCard>
  );
};

export default ShipInfo;
