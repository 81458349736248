import useStore from '@/store/use-store';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { Icon } from '@iconify/react';
import { LoadingButton } from '@mui/lab';
import { IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

interface RegisterFormProps {
  invite?: string;
}

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  roles: string[];
  invitation?: string;
}

export default function RegisterForm({ invite = '' }: RegisterFormProps) {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { t } = useTranslation();
  const register = useStore((state) => state.register);
  const registering = useStore((state) => state.registering);
  const navigate = useNavigate();

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, t('too_short'))
      .max(50, t('too_long'))
      .required(t('first_name_required')),
    lastName: Yup.string()
      .min(2, t('too_short'))
      .max(50, t('too_long'))
      .required(t('last_name_required')),
    email: Yup.string().email(t('email_invalid')).required(t('email_required')),
    password: Yup.string()
      .min(8, t('too_short'))
      .test('regex', t('special_char'), (val) => {
        const regExp = /^.*[!@#$%^&*()_+\-=\[\]{};\':"\\|,.<>\\/?].*$/;
        return regExp.test(val || '');
      })
      .required(t('password_required')),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], t('password_match'))
      .required(t('required')),
    roles: Yup.array().min(1).required()
  });

  const formik = useFormik<FormValues>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      roles: ['ROLE_USER'],
      invitation: invite
    },
    validationSchema: RegisterSchema,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: async (values) => {
      try {
        await register(
          values.email,
          values.password,
          values.firstName,
          values.lastName,
          values.roles,
          true,
          values.invitation
        );
        toast.success(t('account_created'));
        navigate('/login', { replace: true });
      } catch (e) {
        const message = e.detail === 'Username taken' ? t('email_taken') : t('generic_error');
        toast.error(message);
        console.error(e);
      }
    }
  });

  if (!formik.isSubmitting && registering) formik.setSubmitting(true);
  if (formik.isSubmitting && !registering) formik.setSubmitting(false);

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={handleSubmit}>
        <Stack component="section" aria-labelledby="register-title" spacing={3}>
          <Typography variant="h2" id="register-title" className="sr-only">
            {t('register_form_title')}
          </Typography>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              id="firstName"
              autoComplete="given-name"
              label={t('first_name')}
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
              aria-required="true"
              aria-invalid={Boolean(touched.firstName && errors.firstName)}
            />

            <TextField
              fullWidth
              id="lastName"
              label={t('last_name')}
              autoComplete="family-name"
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
              aria-required="true"
              aria-invalid={Boolean(touched.lastName && errors.lastName)}
            />
          </Stack>

          <TextField
            fullWidth
            id="email"
            autoComplete="email"
            type="email"
            label={t('email_address')}
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
            aria-required="true"
            aria-invalid={Boolean(touched.email && errors.email)}
          />

          <TextField
            fullWidth
            id="password"
            autoComplete="new-password"
            type={showPassword ? 'text' : 'password'}
            label={t('password')}
            {...getFieldProps('password')}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
            aria-required="true"
            aria-invalid={Boolean(touched.password && errors.password)}
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => setShowPassword((prev) => !prev)}
                      aria-label={t(showPassword ? 'hide_password' : 'show_password')}
                    >
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} aria-hidden="true" />
                    </IconButton>
                  </InputAdornment>
                )
              }
            }}
          />

          <TextField
            fullWidth
            id="confirmPassword"
            autoComplete="new-password"
            type={showConfirmPassword ? 'text' : 'password'}
            label={t('confirm_password')}
            {...getFieldProps('confirmPassword')}
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            helperText={touched.confirmPassword && errors.confirmPassword}
            aria-required="true"
            aria-invalid={Boolean(touched.confirmPassword && errors.confirmPassword)}
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => setShowConfirmPassword((prev) => !prev)}
                      aria-label={t(showConfirmPassword ? 'hide_password' : 'show_password')}
                    >
                      <Icon icon={showConfirmPassword ? eyeFill : eyeOffFill} aria-hidden="true" />
                    </IconButton>
                  </InputAdornment>
                )
              }
            }}
          />

          <TextField
            select
            fullWidth
            id="userType"
            label={t('user.type.info')}
            onChange={(event) => setFieldValue('roles', [event.target.value])}
            value={formik.values.roles[0]}
            aria-required="true"
            slotProps={{
              select: { native: true }
            }}
          >
            <option key={1} value="ROLE_USER">
              {t('user.type.shipmaster')}
            </option>
            <option key={2} value="ROLE_AUTHORITY">
              {t('user.type.authority')}
            </option>
          </TextField>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {t('register')}
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
