import type React from 'react';

/**
 * Represents the sort order direction
 */
export type Order = 'asc' | 'desc';

/**
 * Utility type for nested object paths
 * Example: NestedKeyOf<{ a: { b: { c: string } } }> = 'a' | 'a.b' | 'a.b.c'
 */
export type NestedKeyOf<ObjectType extends object> = {
  [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object
    ? `${Key}` | `${Key}.${NestedKeyOf<ObjectType[Key]>}`
    : `${Key}`;
}[keyof ObjectType & (string | number)];

/**
 * Gets a value from an object using a string path
 * @param obj The object to get the value from
 * @param path The path to the value (e.g., 'user.address.street')
 * @returns The value at the path or undefined if not found
 */
export const getNestedValue = (obj: unknown, path: string): unknown => {
  return path.split('.').reduce((current, key) => current?.[key], obj);
};

/**
 * Compares two objects for descending sort
 * @param a First object
 * @param b Second object
 * @param orderBy Property path to sort by
 * @returns -1, 0, or 1 for sorting
 */
export const descendingComparator = <T extends Record<string, unknown>>(
  a: T,
  b: T,
  orderBy: NestedKeyOf<T>
): number => {
  const aValue = getNestedValue(a, orderBy);
  const bValue = getNestedValue(b, orderBy);

  // Handle null/undefined values
  if (aValue === null || aValue === undefined) return 1;
  if (bValue === null || bValue === undefined) return -1;

  // Handle different types of values
  if (typeof aValue === 'number' && typeof bValue === 'number') {
    return bValue - aValue;
  }

  if (typeof aValue === 'string' && typeof bValue === 'string') {
    return bValue.localeCompare(aValue);
  }

  if (aValue instanceof Date && bValue instanceof Date) {
    return bValue.getTime() - aValue.getTime();
  }

  if (bValue < aValue) return -1;
  if (bValue > aValue) return 1;
  return 0;
};

/**
 * Creates a comparator function for sorting
 * @param order Sort direction ('asc' or 'desc')
 * @param orderBy Property path to sort by
 * @returns Comparator function
 */
export const getComparator = <T extends Record<string, unknown>>(
  order: Order,
  orderBy: NestedKeyOf<T>
): ((a: T, b: T) => number) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

/**
 * Sorts an array while maintaining index stability
 * @param array Array to sort
 * @param comparator Comparison function
 * @param filterFn Optional filter function
 * @returns Sorted array
 */
export const applySortFilter = <T extends Record<string, unknown>>(
  array: T[],
  comparator: (a: T, b: T) => number,
  filterFn?: (item: T) => boolean
): T[] => {
  if (!array.length) return array;

  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  const sortedArray = stabilizedThis.map((el) => el[0]);

  return filterFn ? sortedArray.filter(filterFn) : sortedArray;
};

export interface TableHeadItem<T extends Record<string, unknown>> {
  id: NestedKeyOf<T> | '';
  label: string;
  alignRight?: boolean;
  sortable?: boolean;
}

export interface TableSortProps<T extends Record<string, unknown>> {
  order: Order;
  orderBy: NestedKeyOf<T>;
  onRequestSort: (property: NestedKeyOf<T>) => void;
}

/**
 * Creates a sort handler for table headers
 * @param property Property to sort by
 * @param onRequestSort Sort callback function
 * @returns Event handler function
 */
export const createSortHandler =
  <T extends Record<string, unknown>>(
    property: NestedKeyOf<T>,
    onRequestSort: (property: NestedKeyOf<T>) => void
  ) =>
  (event: React.MouseEvent<unknown>) => {
    event.preventDefault();
    onRequestSort(property);
  };

/**
 * Calculates empty rows for pagination
 * @param page Current page number
 * @param rowsPerPage Rows per page
 * @param totalItems Total number of items
 * @returns Number of empty rows needed
 */
export const calculateEmptyRows = (
  page: number,
  rowsPerPage: number,
  totalItems: number
): number => {
  return page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalItems) : 0;
};
