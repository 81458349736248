import PulsatingDots from '@/components/PulsatingDots';
import { fDate } from '@/utils/formatTime';
import { Card, CardHeader, Grid, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

RequestInfo.propTypes = {
  request: PropTypes.object,
  loading: PropTypes.bool
};

export default function RequestInfo({ request, loading }) {
  const { t } = useTranslation();

  if (loading) {
    return (
      <Card sx={{ py: 3 }}>
        <CardHeader title={t('request.request_info')} />
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '100px' }}
        >
          <Grid item xs={3}>
            <PulsatingDots size={15} />
            <Typography variant="body2">{t('request.loading_details')}</Typography>
          </Grid>
        </Grid>
      </Card>
    );
  }

  if (!request) {
    return (
      <Card sx={{ py: 3 }}>
        <CardHeader title={t('request.request_info')} />
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '100px' }}
        >
          <Grid item xs={3}>
            <Typography variant="body2">{t('no_data')}</Typography>
          </Grid>
        </Grid>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader title={t('request.request_info')} />
      <Stack spacing={2} sx={{ p: 3 }}>
        <Stack direction="row">
          <Grid container>
            <Grid item xs={3}>
              <Typography variant="body2">{t('request.status')}:</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body2">{request.status}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2">{t('request.date')}:</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body2">{fDate(request.createdOn)}</Typography>
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </Card>
  );
}
