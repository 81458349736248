import useStore from '@/store/use-store';
import {
  type AnnualShipReport,
  type AnnualShipReportFormValues,
  type EngineOperatingHoursFormValues,
  transformToCreateAnnualShipReportDTO
} from '@/types/annual-ship-report';
import type { Ship } from '@/types/ship';
import { Box, TextField, Typography } from '@mui/material';
import { Form, Formik, type FormikProps } from 'formik';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

const generateOperatingHoursValues = (
  report: AnnualShipReport,
  ship: Ship
): EngineOperatingHoursFormValues[] => {
  return ship.engines
    .filter((engine) => engine.isActive)
    .map((engine) => {
      // Zoek de operating hours voor deze engine in het rapport
      const engineHours = report?.engineOperatingHours?.find(
        (hours) => hours.engine.id === engine.id
      );

      return {
        engine: {
          id: engine.id,
          title: engine.title || `Engine ${engine.id}` // Fallback titel als title null is
        },
        engineOperatingHours: engineHours?.operatingHours || 0
      };
    });
};

export interface ReportFormMethods {
  submit: () => Promise<boolean>;
}

interface ReportFormProps {
  ship: Ship;
  report?: AnnualShipReport | null;
  onSubmitSuccess?: (report: AnnualShipReport) => void;
  onCancel?: () => void;
  onStateChange?: (state: { isValid: boolean }) => void;
  showButtons?: boolean;
  showOnlyEssential?: boolean;
  submitButtonLabel?: string;
  cancelButtonLabel?: string;
}

const ReportForm = forwardRef<ReportFormMethods, ReportFormProps>((props, ref) => {
  const formikRef = useRef<FormikProps<AnnualShipReportFormValues>>(null);
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();
  const { createReport } = useStore((state) => state);

  const { report, ship, onStateChange } = props;

  const validationSchema = Yup.object().shape({
    year: Yup.number().required(t('field_required')),
    unitsTransported: Yup.number().nullable(),
    distanceTraveled: Yup.number().nullable(),
    tonKilometerPerformance: Yup.number().nullable(),
    engineOperatingHours: Yup.array().of(
      Yup.object().shape({
        engine: Yup.object().shape({
          id: Yup.number(),
          title: Yup.string()
        }),
        engineOperatingHours: Yup.number().required(t('field_required'))
      })
    )
  });

  const initialValues: AnnualShipReportFormValues = {
    ship: ship.id,
    year: report?.year || new Date().getFullYear() - 1,
    unitsTransported: report?.unitsTransported || null,
    distanceTraveled: report?.distanceTraveled || null,
    tonKilometerPerformance: report?.tonKilometerPerformance || null,
    engineOperatingHours: generateOperatingHoursValues(report, ship)
  };

  useImperativeHandle(ref, () => ({
    submit: async () => {
      if (formikRef.current) {
        try {
          await formikRef.current.validateForm();

          if (!formikRef.current.isValid) {
            return false;
          }
          const values = formikRef.current.values;
          return await handleSubmit(values);
        } catch (error) {
          console.error('Form submission error:', error);
          return false;
        }
      }
      return false;
    }
  }));

  const handleSubmit = async (values: AnnualShipReportFormValues) => {
    try {
      // Transform Form Values to DTO.
      const dto = transformToCreateAnnualShipReportDTO(values);
      const result = await createReport(dto);

      // Announce successful submission to screen readers
      const successMessage = document.createElement('div');
      successMessage.setAttribute('role', 'status');
      successMessage.setAttribute('aria-live', 'polite');
      successMessage.textContent = t('form_success.submission_successful');
      document.body.appendChild(successMessage);
      setTimeout(() => document.body.removeChild(successMessage), 1000);

      props.onSubmitSuccess?.(result);
      return true;
    } catch (error) {
      console.error('Error submitting form:', error);
      setError(t('errors.submission_failed'));
      return false;
    }
  };

  if (error) {
    return (
      <div role="alert" className="text-red-500 p-4" aria-live="assertive">
        {error}
      </div>
    );
  }

  return (
    <main aria-label={t('form_labels.report_form')}>
      <h1 className="sr-only">{t('form_labels.report_for_ship', { shipName: ship.title })}</h1>

      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        validateOnMount
      >
        {({ errors, isValid, touched, values, getFieldProps }) => {
          useEffect(() => {
            onStateChange?.({
              isValid
            });
          }, [isValid]);

          const getFieldError = (index: number) => {
            const error = errors?.engineOperatingHours?.[index];
            if (typeof error === 'object') {
              return error.engineOperatingHours;
            }
            return error;
          };

          return (
            <Form
              noValidate
              className="space-y-6"
              aria-label={t('form_labels.report_inputs')}
              aria-describedby={error ? 'form-error' : undefined}
            >
              <Typography component="h2" align="left">
                {t('engine.running_hours_year', { year: values.year })}
              </Typography>

              <fieldset className="sr-fieldset">
                <legend className="sr-only">{t('form_labels.engine_hours_section')}</legend>
                {values.engineOperatingHours.map((rh, index) => (
                  <Box key={rh.engine.id} sx={{ my: 4 }}>
                    <TextField
                      fullWidth
                      id={`engine-hours-${rh.engine.id}`}
                      label={rh.engine.title}
                      {...getFieldProps(`engineOperatingHours.${index}.engineOperatingHours`)}
                      type="number"
                      slotProps={{
                        input: {
                          'aria-describedby': `engine-hours-${rh.engine.id}-error engine-hours-${rh.engine.id}-description`
                        },
                        inputLabel: {
                          htmlFor: `engine-hours-${rh.engine.id}`,
                          required: true,
                          className: 'required-label'
                        }
                      }}
                      onFocus={(e) => {
                        e.target.addEventListener(
                          'wheel',
                          (e) => {
                            e.preventDefault();
                          },
                          { passive: false }
                        );
                      }}
                      error={Boolean(
                        touched?.engineOperatingHours?.[index]?.engineOperatingHours &&
                          getFieldError(index)
                      )}
                      helperText={
                        touched?.engineOperatingHours?.[index]?.engineOperatingHours &&
                        getFieldError(index)
                      }
                    />

                    <Box
                      id={`engine-hours-${rh.engine.id}-description`}
                      sx={{ mt: 0.5, fontWeight: 'light', fontSize: 'small' }}
                    >
                      {t('form_help.engine_hours_description', {
                        year: values.year,
                        engineName: rh.engine.title
                      })}
                    </Box>
                  </Box>
                ))}
              </fieldset>
            </Form>
          );
        }}
      </Formik>
    </main>
  );
});

export default ReportForm;
