import PulsatingDots from '@/components/PulsatingDots';
import useStore from '@/store/use-store';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Icon } from '@iconify/react';
import { Button, Card, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReportForm from './ReportForm';
import ReportTable from './ReportTable';

ReportList.propTypes = {
  ship: PropTypes.object.isRequired
};

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary
}));

const DetailCard = styled(Card)(({ theme }) => ({
  ...theme.typography.h4,
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(1),
  textAlign: 'center'
}));

const CardHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(4)
}));

export default function ReportList({ ship }) {
  const { t } = useTranslation();
  const [viewMode, setViewMode] = useState('overview');
  const [reports, setReports] = useState([]);
  const [report, setReport] = useState([]);
  const { getReport, loadingReport } = useStore((state) => state);
  const { getReports, loadingReports } = useStore((state) => state);

  useEffect(() => {
    getReports(ship.id).then((res) => setReports(res));
  }, [ship.id, getReports]);

  const finishedEditing = (report) => {
    const list = reports.filter((e) => e.id !== report.id);
    list.push(report);
    setReports(list);
    setViewMode('overview');
  };

  const editReport = (id) => {
    getReport(id).then((res) => {
      setReport(res);
      setViewMode('edit');
    });
  };

  if (loadingReports || loadingReport) {
    return (
      <Card sx={{ py: 3 }}>
        <CardHeader title={t('engine')} />
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '100px' }}
        >
          <PulsatingDots size={15} />
          <Typography variant="body2">
            {loadingReports ? t('report.loading_reports') : t('report.loading_reports')}
          </Typography>
        </Grid>
      </Card>
    );
  }

  if (viewMode === 'create') {
    return (
      <DetailCard sx={{ p: 3 }}>
        <CardHeader>
          <LabelStyle>{t('report.new')}</LabelStyle>
        </CardHeader>
        <ReportForm
          onCancel={() => setViewMode('overview')}
          ship={ship}
          submitCallback={(report) => finishedEditing(report)}
        />
      </DetailCard>
    );
  }

  if (viewMode === 'edit') {
    return (
      <DetailCard sx={{ p: 3 }}>
        <CardHeader>
          <LabelStyle>{t('report.edit')}</LabelStyle>
        </CardHeader>
        <ReportForm
          onCancel={() => setViewMode('overview')}
          report={report}
          ship={ship}
          submitCallback={(engine) => finishedEditing(engine)}
        />
      </DetailCard>
    );
  }

  return (
    <DetailCard sx={{ p: 3 }}>
      <CardHeader>
        <LabelStyle>{t('reports')}</LabelStyle>
        <Button
          variant="contained"
          startIcon={<Icon icon={plusFill} />}
          onClick={() => setViewMode('create')}
        >
          {t('report.new')}
        </Button>
      </CardHeader>
      {!loadingReports && <ReportTable reports={reports} editReport={(id) => editReport(id)} />}
    </DetailCard>
  );
}
