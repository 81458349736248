import UploadFile from '@/components/upload/upload-file';
import type { Fuel, FuelConsumption, FuelType } from '@/types/fuel';
import { getFieldError } from '@/utils/form-helpers';
import closeFill from '@iconify/icons-eva/close-fill';
import { Icon } from '@iconify/react';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Fab,
  FormControl,
  FormHelperText,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { DatePicker } from '@mui/x-date-pickers';
import { FieldArray, type FormikErrors, type FormikTouched, useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface FormValues {
  ship: number;
  year: number;
  consumptions: FuelConsumption[];
}

interface FuelInputProps {
  fuelTypes: FuelType[];
}

export const DEFAULT_CONSUMPTION = (year?: number): FuelConsumption => ({
  quantity: '',
  date: year ? `${year}-01-01` : null,
  fuel: '',
  uom: '',
  title: '',
  files: [],
  id: ''
});

export default function FuelInput({ fuelTypes }: FuelInputProps) {
  const maxUploadSize = +import.meta.env.VITE_APP_MAX_UPLOAD;
  const { t } = useTranslation();
  const { values, setFieldValue, errors, touched } = useFormikContext<FormValues>();

  // Initialize with one consumption per fuel type
  useEffect(() => {
    if (values.consumptions.length === 0) {
      setFieldValue('consumptions', [{ ...DEFAULT_CONSUMPTION(values.year) }]);
    }
  }, [setFieldValue, values.consumptions.length, values.year]);

  const getAllUoM = () => {
    const uniqueUoms = new Set(
      fuelTypes.flatMap((fuelType) => fuelType.fuels.map((fuel) => fuel.uom))
    );
    return Array.from(uniqueUoms).join('/');
  };

  if (fuelTypes.length === 0) {
    return <CircularProgress color="inherit" size={16} />;
  }

  return (
    <FieldArray name="consumptions">
      {({ push, remove }) => (
        <Box component="section" aria-label={t('fuel_consumption_entries')}>
          {values.consumptions.map((consumption, index) => {
            const fieldPrefix = `consumptions.${index}`;
            const consumptionErrors = errors.consumptions?.[index] as
              | FormikErrors<FuelConsumption>
              | undefined;
            const consumptionTouched = touched.consumptions?.[index] as
              | FormikTouched<FuelConsumption>
              | undefined;

            return (
              <Card
                // biome-ignore lint: index must be used here
                key={index}
                sx={{ p: 2, mb: 2, position: 'relative' }}
                component="section"
                aria-label={t('fuel_consumption_entry', { index: index + 1 })}
              >
                <Grid
                  container
                  spacing={2}
                  component="fieldset"
                  sx={{ border: 'none' }}
                  aria-label={t('fuel_consumption_details')}
                >
                  <legend className="sr-only">{t('report.fuel_consumption_details')}</legend>
                  <Grid size={{ xs: 12, md: 4 }}>
                    <DatePicker
                      label={t('date')}
                      value={new Date(consumption.date)}
                      onChange={(newDate) => {
                        setFieldValue(`${fieldPrefix}.date`, newDate?.toISOString() || '');
                      }}
                      minDate={new Date(values.year, 0, 1)}
                      maxDate={new Date(values.year, 11, 31)}
                      sx={{ width: 1 }}
                      slotProps={{
                        textField: {
                          error: consumptionErrors?.date && consumptionTouched?.date,
                          helperText: getFieldError(consumptionErrors, consumptionTouched, 'date'),
                          'aria-label': t('select_date'),
                          'aria-invalid': Boolean(
                            consumptionErrors?.date && consumptionTouched?.date
                          ),
                          'aria-describedby': consumptionErrors?.date
                            ? `${fieldPrefix}-date-error`
                            : undefined
                        }
                      }}
                    />
                  </Grid>

                  <Grid size={{ xs: 6, md: 4 }}>
                    <TextField
                      label={t('quantity')}
                      id={`${fieldPrefix}-quantity`}
                      name={`${fieldPrefix}.quantity`}
                      type="number"
                      value={consumption.quantity}
                      onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
                      onChange={(e) => {
                        const value = e.target.value;
                        const intValue = value ? Number.parseInt(value, 10) : '';

                        if (value === '' || !Number.isNaN(intValue)) {
                          setFieldValue(`${fieldPrefix}.quantity`, intValue);
                        } else {
                          setFieldValue(`${fieldPrefix}.quantity`, '3');
                        }
                      }}
                      slotProps={{
                        htmlInput: {
                          step: 1,
                          min: 0
                        },
                        input: {
                          endAdornment: consumption.uom || getAllUoM(),
                          'aria-label': t('enter_quantity'),
                          'aria-invalid': Boolean(
                            consumptionErrors?.quantity && consumptionTouched?.quantity
                          ),
                          'aria-describedby': consumptionErrors?.quantity
                            ? `${fieldPrefix}-quantity-error`
                            : undefined
                        }
                      }}
                      error={consumptionErrors?.quantity && consumptionTouched?.quantity}
                      helperText={getFieldError(consumptionErrors, consumptionTouched, 'quantity')}
                      fullWidth
                    />
                  </Grid>

                  <Grid size={{ xs: 6, md: 4 }}>
                    <FormControl fullWidth>
                      <InputLabel id="fuel-label">{t('fuel_type.type')}</InputLabel>
                      <Select
                        labelId="fuel-label"
                        value={consumption.fuel || ''}
                        id={`${fieldPrefix}-fuel`}
                        fullWidth
                        onChange={(e) => {
                          const fuelId = e.target.value;
                          let selectedFuel: Fuel;

                          for (const fuelType of fuelTypes) {
                            selectedFuel = fuelType.fuels.find((f) => f.id.toString() === fuelId);
                            if (selectedFuel) break;
                          }

                          setFieldValue(`${fieldPrefix}.fuel`, fuelId);
                          setFieldValue(`${fieldPrefix}.uom`, selectedFuel?.uom || '');
                          setFieldValue(`${fieldPrefix}.title`, selectedFuel?.fuel || '');
                        }}
                        label={t('fuel_type.type')}
                        variant="outlined"
                        aria-label={t('select_fuel_type')}
                        aria-invalid={consumptionErrors?.fuel && consumptionTouched?.fuel}
                        aria-describedby={
                          consumptionErrors?.fuel ? `${fieldPrefix}-fuel-error` : undefined
                        }
                        error={consumptionErrors?.fuel && consumptionTouched?.fuel}
                      >
                        {fuelTypes.flatMap((fuelType) => [
                          <ListSubheader key={`header-${fuelType.id}`}>
                            <em>{fuelType.description}</em>
                          </ListSubheader>,
                          ...fuelType.fuels.map((fuel) => (
                            <MenuItem key={fuel.id} value={fuel.id}>
                              {fuel.fuel}
                            </MenuItem>
                          ))
                        ])}
                      </Select>
                      <FormHelperText error={consumptionErrors?.fuel && consumptionTouched?.fuel}>
                        {getFieldError(consumptionErrors, consumptionTouched, 'fuel')}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <UploadFile
                    error={
                      Array.isArray(consumptionTouched?.files) &&
                      typeof consumptionErrors?.files === 'string'
                    }
                    helperText={getFieldError(consumptionErrors, consumptionTouched, 'files')}
                    singleFile={false}
                    files={consumption.files}
                    onDrop={(files) => {
                      setFieldValue(`${fieldPrefix}.files`, [...consumption.files, ...files]);
                    }}
                    onRemove={(file) => {
                      const newFiles = consumption.files.filter((f) => f !== file);
                      setFieldValue(`${fieldPrefix}.files`, newFiles);
                    }}
                    onRemoveAll={() => {
                      setFieldValue(`${fieldPrefix}.files`, []);
                    }}
                    maxSize={maxUploadSize}
                  />
                </Grid>

                <Fab
                  sx={{
                    position: 'absolute',
                    top: 5,
                    right: 5,
                    zIndex: 1
                  }}
                  onClick={() => remove(index)}
                  color="error"
                  aria-label={t('remove_fuel_consumption_entry', { index: index + 1 })}
                >
                  <Icon width={40} icon={closeFill} aria-hidden="true" />
                </Fab>
              </Card>
            );
          })}
          <Button
            variant="outlined"
            fullWidth
            onClick={() => push({ ...DEFAULT_CONSUMPTION(values.year) })}
            aria-label={t('add_new_fuel_consumption')}
          >
            {t('report.add_fuel_consumption')}
          </Button>
        </Box>
      )}
    </FieldArray>
  );
}
