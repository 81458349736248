import type { WizardStepComponentProps } from '@/components/wizard/wizard';
import useStore from '@/store/use-store';
import type { Ship } from '@/types/ship.ts';
import { Box, Button, CircularProgress, Stack, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import type React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ShipForm } from '../../ship';

export const ShipInfoStep: React.FC<Partial<WizardStepComponentProps>> = ({
  setCanProceed,
  triggerNext
}) => {
  const { t } = useTranslation();
  const [ships, setShips] = useState<Ship[]>([]);
  const [showForm, setShowForm] = useState(false);

  const { wizardShip, setWizardShip } = useStore((state) => state);
  const { getShip, loadingShip } = useStore((state) => state);
  const { getShips, loadingShips } = useStore((state) => state);

  // Fetch ships on mount
  useEffect(() => {
    const fetchShips = async () => {
      const fetchedShips = await getShips();
      setShips(fetchedShips);

      // Auto-select ship if only one exists
      if (fetchedShips.length === 1) {
        const ship = await getShip(fetchedShips[0].id);
        setWizardShip(ship);
      }
    };

    fetchShips();
  }, [getShips, getShip, setWizardShip]);

  // Update canProceed based on wizardShip selection
  useEffect(() => {
    if (!setCanProceed) return;

    const shouldProceed = Boolean(wizardShip?.id) && !showForm;
    setCanProceed(shouldProceed);
  }, [wizardShip?.id, showForm, setCanProceed]); // Only depend on wizardShip.id instead of the entire object

  const handleShipSelect = async (
    event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const shipId = event.target.value;
    setShowForm(false);

    if (shipId) {
      const ship = await getShip(shipId);
      setWizardShip(ship);
    } else {
      setWizardShip(null);
    }
  };

  const handleNewShip = () => {
    setWizardShip(null);
    setShowForm(true);
  };

  const handleShipCreated = async (ship: Ship) => {
    setWizardShip(ship);
    setShowForm(false);
    if (triggerNext) {
      await triggerNext();
    }
  };

  if (loadingShips) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Grid container spacing={3} padding={3}>
      {ships.length > 0 && (
        <Grid container spacing={2} alignItems="baseline" sx={{ width: '100%' }}>
          <Grid size={{ xs: 12, md: 6 }}>
            <Stack spacing={3}>
              <TextField
                select
                fullWidth
                value={wizardShip?.id || ''}
                disabled={loadingShips}
                slotProps={{ select: { native: true } }}
                label={t('ship.ship')}
                onChange={handleShipSelect}
              >
                {!wizardShip && ships.length > 1 && <option value="">{t('select_ship')}</option>}
                {ships.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.title}
                  </option>
                ))}
              </TextField>
            </Stack>
          </Grid>

          <Grid size={{ xs: 12, md: 1 }} display="flex" justifyContent="center" alignItems="center">
            <Typography>-{t('or')}-</Typography>
          </Grid>

          <Grid size={{ xs: 12, md: 5 }}>
            <Button fullWidth variant="contained" color="primary" onClick={handleNewShip}>
              {t('new_ship')}
            </Button>
          </Grid>
        </Grid>
      )}

      {(showForm || ships.length === 0) && (
        <Grid size={12} sx={{ mt: 3 }}>
          <Typography variant="h4" gutterBottom>
            {t('new_ship')}
          </Typography>
          <ShipForm submitCallback={handleShipCreated} />
        </Grid>
      )}
    </Grid>
  );
};

export default ShipInfoStep;
