import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface LoaderProps {
  text?: string;
  size?: number;
  ariaLabel?: string;
}

export default function Loader({ text, size = 34, ariaLabel }: LoaderProps) {
  const { t } = useTranslation();
  ariaLabel = ariaLabel || t('loading');
  return (
    <div
      aria-live="polite"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '12px',
        width: '100%'
      }}
    >
      <CircularProgress size={size} aria-label={ariaLabel} />
      {text && (
        <p
          className="text-sm text-gray-600"
          style={{
            color: '#4B5563'
          }}
        >
          {text}
        </p>
      )}
    </div>
  );
}
