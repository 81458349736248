import Loader from '@/components/Loader';
import fuelConsumptionValidationSchema from '@/components/_dashboard/fuelConsumption/fuel-consumption-validation-schema';
import FuelInput from '@/components/_dashboard/fuelConsumption/fuel-input';
import type { WizardFormMethods } from '@/components/wizard/wizard';
import useStore from '@/store/use-store';
import type { File } from '@/types/file';
import type { CreateFuelConsumptionDTO, FuelConsumption, FuelType } from '@/types/fuel';
import type { Ship } from '@/types/ship';
import { Alert, Button } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Form, Formik, type FormikProps } from 'formik';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface FuelConsumptionFormProps {
  ship: Ship;
  onSubmitSuccess?: () => void;
  onCancel?: () => void;
  year?: number;
  onStateChange?: (state: { isValid: boolean; isDirty: boolean; values: FormValues }) => void;
  showButtons?: boolean;
  consumptions?: FuelConsumption[];
}

interface FormValues {
  ship: number;
  year: number;
  consumptions: FuelConsumption[];
}

const FuelConsumptionForm = forwardRef<WizardFormMethods, FuelConsumptionFormProps>(
  (
    { ship, onSubmitSuccess, onCancel, year, onStateChange, consumptions, showButtons = true },
    ref
  ) => {
    const formikRef = useRef<FormikProps<FormValues>>(null);

    const { getShipFuelTypes, loadingFuelTypes } = useStore((state) => state);
    const [fuelTypes, setFuelTypes] = useState<FuelType[]>([]);
    const [error, setError] = useState<string | null>(null);
    const { t } = useTranslation();

    const { addFuelConsumption, uploadFile } = useStore((state) => state);

    const currentYear = new Date().getFullYear();
    const selectedYear = year || currentYear - 1;
    const maxUploadSize = +import.meta.env.VITE_APP_MAX_UPLOAD;

    useImperativeHandle(ref, () => ({
      submit: async () => {
        if (formikRef.current) {
          try {
            await formikRef.current.validateForm();

            if (!formikRef.current.isValid) {
              return false;
            }

            await formikRef.current.submitForm();
            return true;
          } catch (error) {
            console.error('Form submission error:', error);
            return false;
          }
        }
        return false;
      }
    }));

    const initialValues: FormValues = {
      ship: ship.id,
      year: selectedYear,
      consumptions: consumptions || []
    };

    useEffect(() => {
      let isSubscribed = true;

      const loadFuelTypes = async () => {
        try {
          const response = await getShipFuelTypes(ship.id);
          if (isSubscribed) {
            setFuelTypes(response);
            setError(null);
          }
        } catch (error) {
          if (isSubscribed) {
            console.error('Error loading fuel types:', error);
            setError(t('errors.loading_fuel_types'));
          }
        }
      };

      loadFuelTypes();

      return () => {
        isSubscribed = false;
      };
    }, [ship.id, getShipFuelTypes, t]);

    const handleSubmit = async (values: FormValues) => {
      try {
        const { consumptions } = values;

        // Process all consumptions sequentially
        for (const consumption of consumptions) {
          // Handle file uploads
          const promises: Promise<File>[] = [];
          const existingFiles: string[] = [];

          // Process each file
          for (const file of consumption.files) {
            if (file instanceof File) {
              promises.push(uploadFile(file));
            } else {
              existingFiles.push(file);
            }
          }

          // Wait for all file uploads to complete
          const newFiles = await Promise.all(promises);

          // Remove empty id
          const processedConsumption: CreateFuelConsumptionDTO = {
            ...consumption,
            id: consumption.id || null,
            files: [...existingFiles, ...newFiles.map((file) => file.fileName)]
          };

          // Add the consumption
          await addFuelConsumption(processedConsumption, values.ship);
        }

        // Announce successful submission to screen readers
        const successMessage = document.createElement('div');
        successMessage.setAttribute('role', 'status');
        successMessage.setAttribute('aria-live', 'polite');
        successMessage.textContent = t('form_success.submission_successful');
        document.body.appendChild(successMessage);
        setTimeout(() => document.body.removeChild(successMessage), 1000);

        onSubmitSuccess?.();

        return true;
      } catch (error) {
        console.error('Error submitting form:', error);
        setError(t('errors.submission_failed'));
        return false;
      }
    };

    if (loadingFuelTypes) {
      return <Loader text={t('loading_states.loading_fuel_types')} />;
    }

    if (error) {
      return (
        <div role="alert" className="text-red-500 p-4" aria-live="assertive">
          {error}
        </div>
      );
    }

    return (
      <main aria-label={t('form_labels.fuel_consumption_form')}>
        <h1 className="sr-only">
          {t('form_labels.fuel_consumption_for_ship', { shipName: ship.title, year: selectedYear })}
        </h1>

        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={fuelConsumptionValidationSchema(t, maxUploadSize, fuelTypes)}
          onSubmit={handleSubmit}
          enableReinitialize
          validateOnMount
        >
          {({ isSubmitting, errors, isValid, dirty, values }) => {
            // Call onStateChange whenever form state changes
            useEffect(() => {
              onStateChange?.({
                isValid: isValid,
                isDirty: dirty,
                values: values
              });
            }, [isValid, dirty, values, onStateChange]);

            return (
              <Form
                noValidate
                className="space-y-6"
                aria-label={t('form_labels.fuel_consumption_inputs')}
              >
                {isSubmitting ? (
                  <Loader size={24} text={t('buttons.saving')} />
                ) : (
                  <FuelInput fuelTypes={fuelTypes} />
                )}

                {/* Show error if not all fuel types are covered */}
                {typeof errors.consumptions === 'string' && (
                  <Alert severity="warning" sx={{ mt: 2 }}>
                    {errors.consumptions}
                  </Alert>
                )}

                {showButtons && (
                  <Grid
                    container
                    spacing={2}
                    component="fieldset"
                    sx={{ border: 'none' }}
                    aria-label={t('fuel_consumption_details')}
                    className="flex justify-end space-x-4"
                  >
                    <legend className="sr-only">{t('form_labels.form_actions')}</legend>
                    {onCancel && (
                      <Button
                        type="button"
                        color="secondary"
                        onClick={onCancel}
                        disabled={isSubmitting}
                      >
                        {t('buttons.cancel')}
                      </Button>
                    )}
                    <Button type="submit" disabled={isSubmitting} aria-busy={isSubmitting}>
                      {isSubmitting ? t('buttons.saving') : t('buttons.save')}
                    </Button>
                  </Grid>
                )}
              </Form>
            );
          }}
        </Formik>
      </main>
    );
  }
);

export default FuelConsumptionForm;
