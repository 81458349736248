import useStore from '@/store/use-store';
import { Box, Button, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
// material
import { alpha, styled, useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NavLink as RouterLink, matchPath, useLocation, useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.body2,
    height: 48,
    position: 'relative',
    textTransform: 'capitalize',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(2.5),
    color: theme.palette.text.secondary,
    '&:before': {
      top: 0,
      right: 0,
      width: 3,
      bottom: 0,
      content: "''",
      display: 'none',
      position: 'absolute',
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      backgroundColor: theme.palette.primary.main
    }
  })
);

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

const LogOut = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  marginTop: 'auto'
});

const LogOutButton = styled(Button)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3)
}));

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
  onNavigate: PropTypes.func
};

function NavItem({ item, active, onNavigate }) {
  const theme = useTheme();
  const isActiveRoot = active(item.path);
  const { title, path, icon, info } = item;
  const { t } = useTranslation();
  const displayTitle = t(title);
  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    '&:before': { display: 'block' }
  };

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle)
      }}
      onClick={() => {
        if (onNavigate) onNavigate();
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      <ListItemText disableTypography primary={displayTitle} />
      {info && info}
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array,
  onNavigate: PropTypes.func,
  adminNavConfig: PropTypes.array
};

export default function NavSection({ navConfig, onNavigate, ...other }) {
  const { pathname } = useLocation();
  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);
  const { t } = useTranslation();
  const logout = useStore((state) => state.logout);
  const navigate = useNavigate();

  const onLogoutClick = () => {
    logout();
    navigate('/');
  };

  return (
    <Box {...other}>
      <List disablePadding>
        {navConfig.map((item) => (
          <NavItem key={item.title} item={item} active={match} onNavigate={onNavigate} />
        ))}
      </List>
      <LogOut>
        <p />
        <LogOutButton onClick={() => onLogoutClick()} variant="contained" color="secondary">
          {t('logout')}
        </LogOutButton>
      </LogOut>
    </Box>
  );
}
