import PropTypes from 'prop-types';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { Icon } from '@iconify/react';

MoreMenuItem.propTypes = {
  icon: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired
};

export default function MoreMenuItem({ icon, onClick, label }) {
  return (
    <MenuItem sx={{ color: 'text.secondary' }} onClick={onClick}>
      <ListItemIcon>
        <Icon icon={icon} width={24} height={24} />
      </ListItemIcon>
      <ListItemText primary={label} primaryTypographyProps={{ variant: 'body2' }} />
    </MenuItem>
  );
}
