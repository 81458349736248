import { styled } from '@mui/material/styles';

export const SrOnly = styled('span')({
  position: 'absolute',
  width: '1px',
  height: '1px',
  padding: 0,
  margin: '-1px',
  overflow: 'hidden',
  // clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap',
  border: 0
});

export const WizardProgress = styled('nav')(({ theme }) => ({
  marginBottom: theme.spacing(4) // 2rem in MUI spacing
}));
