import RegisterForm from '@/components/authentication/register/register-form';
import { Box, Card, Container, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import backgroundImage from '../_assets/login_image.png';
import { MHidden } from '../components/@material-extend';
import Page from '../components/Page';
import AuthLayout from '../layouts/AuthLayout';
import LanguagePopover from '../layouts/dashboard/LanguagePopover';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)({
  width: '100%',
  maxWidth: 520,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'cover',
  borderRadius: '0px',
  role: 'img'
});

const ContentStyle = styled('main')(({ theme }) => ({
  maxWidth: 520,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

export default function Register() {
  const { t } = useTranslation();

  return (
    <RootStyle title={t('register')}>
      <AuthLayout>
        <LanguagePopover />
        <Typography
          variant="body2"
          component="p"
          sx={{
            mt: { md: -2 }
          }}
        >
          {t('have_account')}? &nbsp;
          <Link
            underline="none"
            variant="subtitle2"
            component={RouterLink}
            to="/login"
            aria-label={t('navigate_to_login')}
          >
            {t('login')}
          </Link>
        </Typography>
      </AuthLayout>

      <MHidden width="mdDown">
        <SectionStyle aria-label={t('register_background_image')} />
      </MHidden>

      <Container component="section">
        <ContentStyle>
          <Box component="header" sx={{ mb: 5 }}>
            <Typography
              variant="h2"
              component="h1"
              sx={{
                color: 'text.primary',
                fontWeight: 'bold'
              }}
              gutterBottom
            >
              {t('register')}
            </Typography>
          </Box>

          <RegisterForm />

          <Box component="footer">
            <Typography
              variant="body2"
              align="center"
              component="p"
              sx={{ color: 'text.secondary', mt: 3 }}
            >
              {t('accept_policy.part1')}&nbsp;
              <Link
                target="_blank"
                component={RouterLink}
                to="https://sabni.nl/images/downloads/Algemenevoorwaarden.pdf"
                underline="always"
                sx={{ color: 'text.primary' }}
                aria-label={t('terms_conditions_link')}
              >
                {t('accept_policy.part2')}
              </Link>
              &nbsp;{t('accept_policy.part3')}&nbsp;
              <Link
                target="_blank"
                component={RouterLink}
                underline="always"
                sx={{ color: 'text.primary' }}
                to="https://sabni.nl/privacy/"
                aria-label={t('privacy_policy_link')}
              >
                {t('accept_policy.part4')}
              </Link>
              .
            </Typography>

            <MHidden width="smUp">
              <Typography variant="subtitle2" component="p" sx={{ mt: 3, textAlign: 'center' }}>
                {t('have_account')}?&nbsp;
                <Link to="/login" component={RouterLink} aria-label={t('navigate_to_login')}>
                  {t('login')}
                </Link>
              </Typography>
            </MHidden>
          </Box>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
