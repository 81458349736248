import useStore from '@/store/use-store';
import attachOutline from '@iconify/icons-eva/attach-outline';
import awardOutline from '@iconify/icons-eva/award-outline';
import flashOutline from '@iconify/icons-eva/flash-outline';
import roundPermMedia from '@iconify/icons-ic/round-perm-media';
import { Icon } from '@iconify/react';
import { Box, Card, Container, Tab, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';
import { capitalCase } from 'change-case';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { PATH_ADMIN } from '../../../../../routes/paths';
import HeaderBreadcrumbs from '../../../../HeaderBreadcrumbs';
import Page from '../../../../Page';
import RequestCover from './RequestCover';
import RequestDetails from './RequestDetails';
import RequestDocuments from './RequestDocuments';
import RequestEngines from './RequestEngines';
import RequestHistory from './RequestHistory';
import RequestLabel from './RequestLabel';

const TabsWrapperStyle = styled('div')(({ theme }) => ({
  zIndex: 9,
  bottom: 0,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.up('sm')]: {
    justifyContent: 'center'
  },
  [theme.breakpoints.up('md')]: {
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(3)
  }
}));

export default function RequestOverview() {
  const { t } = useTranslation();
  const { id } = useParams();
  const [currentTab, setCurrentTab] = useState('info');
  const [request, setRequest] = useState(null);
  const { getRequest, loadingRequest } = useStore((state) => state);

  useEffect(() => {
    getRequest(id).then((res) => {
      setRequest(res);
    });
  }, [id, getRequest, setRequest]);

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const PROFILE_TABS = [
    {
      value: 'info',
      title: t('request.info'),
      icon: <Icon icon={roundPermMedia} width={20} height={20} />,
      component: <RequestDetails loading={loadingRequest} request={request} />
    },
    {
      value: 'engines',
      title: t('request.engine_info'),
      icon: <Icon icon={flashOutline} width={20} height={20} />,
      component: <RequestEngines loading={loadingRequest} request={request} />
    },
    {
      value: 'documents',
      title: t('request.documents'),
      icon: <Icon icon={attachOutline} width={20} height={20} />,
      component: <RequestDocuments loading={loadingRequest} request={request} />
    },
    {
      value: 'history',
      title: t('request.history'),
      icon: <Icon icon={attachOutline} width={20} height={20} />,
      component: <RequestHistory loading={loadingRequest} request={request} />
    },
    {
      value: 'label',
      title: t('request.label'),
      icon: <Icon icon={awardOutline} width={20} height={20} />,
      component: <RequestLabel loading={loadingRequest} request={request} />
    }
  ];

  return (
    <Page title={t('request.request')}>
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading="Profile"
          links={[
            { name: 'Dashboard', href: PATH_ADMIN },
            { name: t('request.requests'), href: PATH_ADMIN.requests },
            { name: request ? request.ship.title : t('request.request') }
          ]}
        />
        <Card
          sx={{
            mb: 3,
            height: 280,
            position: 'relative'
          }}
        >
          <RequestCover request={request} />

          <TabsWrapperStyle>
            <Tabs
              value={currentTab}
              scrollButtons="auto"
              variant="scrollable"
              allowScrollButtonsMobile
              onChange={handleChangeTab}
            >
              {PROFILE_TABS.map((tab) => (
                <Tab
                  disableRipple
                  key={tab.value}
                  value={tab.value}
                  icon={tab.icon}
                  label={capitalCase(tab.title)}
                />
              ))}
            </Tabs>
          </TabsWrapperStyle>
        </Card>

        {PROFILE_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </Container>
    </Page>
  );
}
