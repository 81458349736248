import PulsatingDots from '@/components/PulsatingDots';
import { CardHeader, Grid, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DetailCard } from '../../../../../../layouts/styles';

UserInfo.propTypes = {
  user: PropTypes.object,
  loading: PropTypes.bool
};

export default function UserInfo({ user, loading }) {
  const { t } = useTranslation();

  if (loading) {
    return (
      <DetailCard>
        <CardHeader title={t('request.user_info')} />
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '100px' }}
        >
          <Grid item xs={3}>
            <PulsatingDots size={15} />
            <Typography variant="body2">{t('user.loading_details')}</Typography>
          </Grid>
        </Grid>
      </DetailCard>
    );
  }

  if (!user) {
    return (
      <DetailCard>
        <CardHeader title={t('request.user_info')} />
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '100px' }}
        >
          <Grid item xs={3}>
            <Typography variant="body2">{t('no_data')}</Typography>
          </Grid>
        </Grid>
      </DetailCard>
    );
  }

  return (
    <DetailCard>
      <CardHeader title={t('request.user_info')} />
      <Stack spacing={2} sx={{ p: 3 }}>
        <Stack direction="row">
          <Grid container>
            <Grid item xs={3}>
              <Typography variant="body2">{t('user.name')}:</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body2">
                {user.firstName} {user.lastName}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2">{t('user.email')}:</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body2">{user.email}</Typography>
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </DetailCard>
  );
}
