import useStore from '@/store/use-store';
import { LoadingButton } from '@mui/lab';
import { Box, Card, CircularProgress, Container, Grid, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Form, FormikProvider, useFormik } from 'formik';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
import Page from '../components/Page';
import { PATH_CLIENT } from '../routes/paths';

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(4),
  textAlign: 'left'
}));

const DetailCard = styled(Card)(({ theme }) => ({
  ...theme.typography.h4,
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(1),
  textAlign: 'center'
}));

export default function Help() {
  const { t } = useTranslation();
  const { sendHelpMessage, sendingMessage } = useStore((state) => state);

  const Schema = Yup.object().shape({
    message: Yup.string().required(t('required'))
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      message: ''
    },
    validationSchema: Schema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        sendHelpMessage(values.message).then(() => {
          toast.success(t('message.sent'));
        });
        setSubmitting(false);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        setErrors(error);
      }
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;
  return (
    <Page title={t('help')}>
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading={t('help')}
          links={[{ name: 'Dashboard', href: PATH_CLIENT.root }, { name: t('help') }]}
        />
        <Card>
          <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <DetailCard sx={{ p: 3 }}>
                    <LabelStyle>{t('send_message')}</LabelStyle>
                    <TextField
                      {...getFieldProps('message')}
                      fullWidth
                      multiline
                      minRows={6}
                      maxRows={14}
                      label={t('message')}
                      error={Boolean(touched.message && errors.message)}
                      helperText={touched.message && errors.message}
                    />
                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                      <LoadingButton
                        loading={sendingMessage || isSubmitting}
                        loadingIndicator={<CircularProgress color="inherit" size={16} />}
                        type="submit"
                        variant="contained"
                        disabled={!isEmpty(errors)}
                      >
                        {t('send')}
                      </LoadingButton>
                    </Box>
                  </DetailCard>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </Card>
      </Container>
    </Page>
  );
}
