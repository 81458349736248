import useStore from '@/store/use-store';
import infoOutline from '@iconify/icons-eva/info-outline';
import { Icon } from '@iconify/react';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Form, FormikProvider, useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

ReportForm.defaultProps = {
  submitButtonLabel: 'save',
  cancelButtonLabel: 'cancel',
  showOnlyEssential: false
};

ReportForm.propTypes = {
  ship: PropTypes.object,
  submitCallback: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  submitButtonLabel: PropTypes.string,
  cancelButtonLabel: PropTypes.string,
  report: PropTypes.object,
  showOnlyEssential: PropTypes.bool
};

const IconStyle = styled(Icon)(({ theme }) => ({
  width: 20,
  height: 20,
  marginTop: 1,
  flexShrink: 0,
  marginRight: theme.spacing(2)
}));

export default function ReportForm({
  ship,
  submitCallback,
  onCancel,
  submitButtonLabel,
  cancelButtonLabel,
  report,
  showOnlyEssential
}) {
  const { t } = useTranslation();
  const createReport = useStore((state) => state.createReport);
  const [enginesList, setEngines] = useState([]);
  const { getEngines } = useStore((state) => state);
  const [reportOverwrite, setReportOverwrite] = useState(false);
  const { getReportByYear, loadingReport } = useStore((state) => state);

  useEffect(() => {
    let isMounted = true;
    getEngines(ship.id).then((res) => isMounted && setEngines(res));
    return () => {
      isMounted = false;
    };
  }, [ship.id, getEngines]);

  const ReportSchema = Yup.object().shape({
    year: Yup.number().required(t('required')),
    unitsTransported: Yup.number(),
    distanceTraveled: Yup.number(),
    tonKilometerPerformance: Yup.number(),
    engineOperatingHours: Yup.array().of(
      Yup.object().shape({
        engine: Yup.object().shape({
          id: Yup.number(),
          title: Yup.string()
        }),
        engineOperatingHours: Yup.number().required(t('required'))
      })
    )
  });

  const onYearChange = (year) => {
    if (!loadingReport) {
      getReportByYear(ship.id, year).then((res) => setReportOverwrite(!!res?.id));
    }
  };

  function getEngineOperatingHours(engine) {
    const eoh = report?.engineOperatingHours?.find((eoh) => eoh.engine.id === engine.id);
    return eoh ? eoh.operatingHours : '';
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: report?.id || '',
      ship: ship.id,
      year: report?.year || new Date().getFullYear() - 1,
      unitsTransported: report?.unitsTransported || '',
      distanceTraveled: report?.distanceTraveled || '',
      tonKilometerPerformance: report?.tonKilometerPerformance || '',
      engineOperatingHours: enginesList.map((engine) => ({
        engine,
        engineOperatingHours: getEngineOperatingHours(engine)
      }))
    },
    validationSchema: ReportSchema,
    onSubmit: (values, { setSubmitting, setErrors }) => {
      if (values.id === '') delete values.id;
      try {
        const newReport = { ...values };
        if (showOnlyEssential || newReport.unitsTransported === '')
          delete newReport.unitsTransported;
        if (showOnlyEssential || newReport.distanceTraveled === '')
          delete newReport.distanceTraveled;
        if (showOnlyEssential || newReport.tonKilometerPerformance === '')
          delete newReport.tonKilometerPerformance;

        const engineOperatingHours = { ...values.engineOperatingHours };
        newReport.engineOperatingHours = Object.keys(engineOperatingHours).map((key) => ({
          operatingHours: engineOperatingHours[key].engineOperatingHours,
          year: newReport.year,
          engine: engineOperatingHours[key].engine.id
        }));
        createReport(newReport);
        setSubmitting(false);
        submitCallback(newReport);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        setErrors(error);
      }
    }
  });

  const { values, errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Stack spacing={3}>
              {!showOnlyEssential && (
                <>
                  <TextField
                    fullWidth
                    label={t('report.year')}
                    {...getFieldProps('year')}
                    error={Boolean(touched.year && errors.year)}
                    helperText={touched.year && errors.year}
                    type="number"
                    onFocus={(e) =>
                      e.target.addEventListener(
                        'wheel',
                        (e) => {
                          e.preventDefault();
                        },
                        { passive: false }
                      )
                    }
                    onBlur={() => onYearChange(values.year)}
                  />
                  {reportOverwrite && (
                    <Stack direction="row">
                      <IconStyle icon={infoOutline} />
                      <Typography variant="body2">{t('report.year_taken')}</Typography>
                    </Stack>
                  )}
                  <TextField
                    fullWidth
                    label={t('report.units_transported')}
                    {...getFieldProps('unitsTransported')}
                    error={Boolean(touched.unitsTransported && errors.unitsTransported)}
                    helperText={touched.unitsTransported && errors.unitsTransported}
                    type="number"
                    onFocus={(e) =>
                      e.target.addEventListener(
                        'wheel',
                        (e) => {
                          e.preventDefault();
                        },
                        { passive: false }
                      )
                    }
                  />
                  <TextField
                    fullWidth
                    label={t('report.distance_traveled')}
                    {...getFieldProps('distanceTraveled')}
                    error={Boolean(touched.distanceTraveled && errors.distanceTraveled)}
                    helperText={touched.distanceTraveled && errors.distanceTraveled}
                    type="number"
                    onFocus={(e) =>
                      e.target.addEventListener(
                        'wheel',
                        (e) => {
                          e.preventDefault();
                        },
                        { passive: false }
                      )
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" style={{ paddingRight: '1rem' }}>
                          km
                        </InputAdornment>
                      )
                    }}
                  />
                  <TextField
                    fullWidth
                    label={t('report.ton_km_performance')}
                    {...getFieldProps('tonKilometerPerformance')}
                    error={Boolean(
                      touched.tonKilometerPerformance && errors.tonKilometerPerformance
                    )}
                    helperText={touched.tonKilometerPerformance && errors.tonKilometerPerformance}
                    type="number"
                    onFocus={(e) =>
                      e.target.addEventListener(
                        'wheel',
                        (e) => {
                          e.preventDefault();
                        },
                        { passive: false }
                      )
                    }
                  />
                </>
              )}
              <Typography align="left" variant="body2">
                {t('engine.running_hours_year', { year: values.year })}
              </Typography>
              {values.engineOperatingHours.map((rh, index) => (
                <TextField
                  fullWidth
                  key={rh.engine.id}
                  label={`${t('engine.running_hours_year', { year: values.year })} - ${
                    rh.engine.title
                  }`}
                  {...getFieldProps(`engineOperatingHours.${index}.engineOperatingHours`)}
                  type="number"
                  onFocus={(e) =>
                    e.target.addEventListener(
                      'wheel',
                      (e) => {
                        e.preventDefault();
                      },
                      { passive: false }
                    )
                  }
                  error={Boolean(
                    touched?.engineOperatingHours?.[index]?.engineOperatingHours &&
                      errors?.engineOperatingHours?.[index]?.engineOperatingHours
                  )}
                  helperText={
                    touched?.engineOperatingHours?.[index]?.engineOperatingHours &&
                    errors?.engineOperatingHours?.[index]?.engineOperatingHours
                  }
                />
              ))}
            </Stack>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-start' }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    onClick={() => onCancel()}
                  >
                    {t(cancelButtonLabel)}
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                  <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                    {t(submitButtonLabel)}
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
