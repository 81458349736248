import { useTranslation } from 'react-i18next';
import { Card, Container } from '@mui/material';
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { PATH_CLIENT } from '../../routes/paths';
import AdminRequestList from '../../components/_dashboard/admin/request/AdminRequestList';
// ----------------------------------------------------------------------

export default function AdminRequest() {
  const { t } = useTranslation();

  return (
    <Page title={t('request.requests')}>
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading={t('request.requests')}
          links={[{ name: 'Dashboard', href: PATH_CLIENT.root }, { name: t('request.requests') }]}
        />
        <Card>
          <AdminRequestList />
        </Card>
      </Container>
    </Page>
  );
}
