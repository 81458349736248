import OverviewTableHead from '@/components/overview-table/overview-table-head';
import {
  type NestedKeyOf,
  type Order,
  type TableHeadItem,
  applySortFilter,
  calculateEmptyRows,
  getComparator
} from '@/components/overview-table/table-utils';
import useStore from '@/store/use-store';
import type { Ship } from '@/types/ship';
import refreshOutline from '@iconify/icons-eva/refresh-outline';
import { Icon } from '@iconify/react';
import {
  Card,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Toolbar,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { format, formatDistanceToNow } from 'date-fns';
import { nl } from 'date-fns/locale';
import type React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RequestMoreMenu from './RequestMoreMenu';

interface Request {
  id: number;
  ship: Ship;
  createdOn: string;
  status: string;
}

type SortableRequestKeys = 'id' | 'ship.title' | 'createdOn' | 'status';

type SortableRequest = {
  [key: string]: unknown;
} & Record<SortableRequestKeys, string>;

const DetailCard = styled(Card)(({ theme }) => ({
  ...theme.typography.h4,
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(1)
}));

const RootStyle = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'space-between'
});

const RightBox = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '1rem'
});

const RequestList = () => {
  const { t } = useTranslation();
  const loading = useStore((state) => state.loadingRequests);
  const getRequests = useStore((state) => state.getRequests);
  const requests = useStore((state) => state.requests);

  const [orderBy, setOrderBy] = useState<NestedKeyOf<SortableRequest>>('createdOn');
  const [order, setOrder] = useState<Order>('desc');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    getRequests();
  }, [getRequests]);

  const TABLE_HEAD: TableHeadItem<SortableRequest>[] = [
    { id: 'ship.title', label: t('ship.title'), sortable: true },
    { id: 'status', label: t('status'), sortable: true },
    { id: 'createdOn', label: t('request.last_action'), sortable: true },
    { id: '', label: t('actions'), alignRight: true }
  ];

  // Transform requests to sortable format
  const sortableRequests = useMemo(
    () =>
      requests.map((request: Request) => ({
        id: request.id,
        'ship.title': request.ship.title,
        createdOn: request.createdOn,
        status: request.status
      })),
    [requests]
  );

  const handleRequestSort = (property: NestedKeyOf<SortableRequest>) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number.parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = calculateEmptyRows(page, rowsPerPage, requests.length);
  const isRequestEmpty = requests.length === 0;

  const sortedRequests = useMemo(
    () =>
      applySortFilter<SortableRequest>(
        sortableRequests,
        getComparator<SortableRequest>(order, orderBy)
      ),
    [sortableRequests, order, orderBy]
  );

  const filteredRequests = useMemo(() => {
    const requestMap = new Map(requests.map((request) => [request.id, request]));

    return sortedRequests
      .map((sortedRequest) => requestMap.get(sortedRequest.id))
      .filter((request): request is Request => request !== undefined);
  }, [sortedRequests, requests]);

  const formatDate = (date: string) => {
    const dateObj = new Date(date);
    return {
      formatted: format(dateObj, 'dd MMM yyyy HH:mm', { locale: nl }),
      relative: formatDistanceToNow(dateObj, { locale: nl, addSuffix: true }),
      iso: dateObj.toISOString()
    };
  };

  return (
    <DetailCard aria-labelledby="request-list-title">
      <RootStyle>
        <Typography
          id="request-list-title"
          variant="h4"
          sx={{
            my: 'auto',
            fontSize: '1.5rem',
            fontWeight: 'bold'
          }}
        >
          {t('my_requests')}
        </Typography>
        <RightBox>
          {loading && (
            <Typography
              component="span"
              variant="body2"
              sx={{ fontStyle: 'italic', color: 'text.secondary' }}
              aria-live="polite"
            >
              {t('refreshing')}
            </Typography>
          )}
          <IconButton onClick={() => getRequests()} aria-label={t('refresh')} color="primary">
            <Icon icon={refreshOutline} aria-hidden="true" />
          </IconButton>
        </RightBox>
      </RootStyle>

      <TableContainer sx={{ p: 1 }}>
        <Table
          aria-label={t('request.table_aria_label')}
          aria-busy={loading}
          aria-describedby={isRequestEmpty ? 'no-requests-message' : undefined}
        >
          <OverviewTableHead
            order={order}
            orderBy={orderBy}
            headLabel={TABLE_HEAD}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {filteredRequests
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                const { id, ship, createdOn, status } = row;
                const dateInfo = formatDate(createdOn);

                return (
                  <TableRow
                    hover
                    key={id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {ship.title}
                    </TableCell>
                    <TableCell>
                      <Typography
                        component="span"
                        sx={(theme) => ({
                          color: theme.palette.text.secondary,
                          display: 'inline-flex',
                          alignItems: 'center',
                          gap: '0.5rem'
                        })}
                      >
                        {t(status)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <time dateTime={dateInfo.iso} title={dateInfo.formatted}>
                        {dateInfo.formatted} ({dateInfo.relative})
                      </time>
                    </TableCell>
                    <TableCell align="right">
                      <RequestMoreMenu
                        id={id}
                        aria-label={t('request.actions.menu', { ship: ship.title })}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }} aria-hidden="true">
                <TableCell colSpan={4} />
              </TableRow>
            )}
          </TableBody>
          {isRequestEmpty && !loading && (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={4} sx={{ py: 3 }}>
                  <Paper>
                    <Typography
                      id="no-requests-message"
                      gutterBottom
                      align="center"
                      variant="subtitle1"
                    >
                      {t('request.no_requests')}
                    </Typography>
                  </Paper>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={requests.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage={t('table.rows_per_page')}
        getItemAriaLabel={(type) => t(`table.${type}_page`)}
        labelDisplayedRows={({ from, to, count }) => t('table.displayed_rows', { from, to, count })}
      />
    </DetailCard>
  );
};

export default RequestList;
