import { Box, keyframes, styled } from '@mui/material';
import type React from 'react';
import { useTranslation } from 'react-i18next';

const pulse = keyframes`
    0% { transform: scale(1); opacity: 1; }
    50% { transform: scale(1.5); opacity: 0.5; }
    100% { transform: scale(1); opacity: 1; }
`;

const PulsatingDot = styled(Box)(({ size, color }: { size: number; color: string }) => ({
  width: `${size}px`,
  height: `${size}px`,
  backgroundColor: color,
  borderRadius: '50%',
  display: 'inline-block',
  margin: `0 ${size / 3}px`,
  animation: `${pulse} 1s infinite ease-in-out`,
  '@media (prefers-reduced-motion: reduce)': {
    animation: 'none'
  }
}));

interface LoadingDotsProps {
  size: number;
  color?: string;
  'aria-label'?: string;
}

const PulsatingDots: React.FC<LoadingDotsProps> = ({ size, color, 'aria-label': ariaLabel }) => {
  const { t } = useTranslation();
  const defaultLabel = t('loading');

  return (
    <Box display="flex" justifyContent="center" alignItems="center" width="100%" aria-live="polite">
      {/* Hidden text for screen readers */}
      <span className="sr-only">{ariaLabel || defaultLabel}</span>{' '}
      <PulsatingDot size={size} color={color ? color : '#00953b'} sx={{ animationDelay: '0ms' }} />
      <PulsatingDot
        size={size}
        color={color ? color : '#6bc72a'}
        sx={{ animationDelay: '150ms' }}
      />
      <PulsatingDot
        size={size}
        color={color ? color : '#00953b'}
        sx={{ animationDelay: '300ms' }}
      />
    </Box>
  );
};

export default PulsatingDots;
