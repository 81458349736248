import useStore from '@/store/use-store';
import { validateFileSize } from '@/utils/validateFileArray.js';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  LinearProgress,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import UploadFile from '../../upload/upload-file';

FuelConsumptionForm.propTypes = {
  ship: PropTypes.object,
  submitCallback: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  date: PropTypes.string,
  submitButtonLabel: PropTypes.string,
  cancelButtonLabel: PropTypes.string,
  consumption: PropTypes.array,
  singleEdit: PropTypes.bool
};

export default function FuelConsumptionForm({
  ship,
  submitCallback,
  onCancel,
  date,
  consumption,
  submitButtonLabel = 'save',
  cancelButtonLabel = 'cancel',
  singleEdit = false
}) {
  const { getShipFuelTypes, loadingFuelTypes } = useStore((state) => state);
  const { t } = useTranslation();
  const { uploadFile } = useStore((state) => state);
  const [fuelTypes, setFuelTypes] = useState([]);
  const [fuelUsage, setFuelUsage] = useState([]);
  const addFuelConsumption = useStore((state) => state.addFuelConsumption);
  const maxUploadSize = import.meta.env.VITE_APP_MAX_UPLOAD;

  useEffect(() => {
    let isSubscribed = true;
    getShipFuelTypes(ship.id).then((res) => {
      if (isSubscribed) {
        const fuel = [{ fuel: '', quantity: '' }];
        const fuelTypes = res.map((fuelType) => ({ fuelType, fuels: fuel }));
        setFuelTypes(fuelTypes);
      }
    });
    return () => (isSubscribed = false);
  }, [ship, getShipFuelTypes]);

  useEffect(() => {
    const fuelsAboard = [];

    fuelTypes.forEach((fuelType) => {
      const ft = {
        id: fuelType.fuelType.id,
        description: fuelType.fuelType.description,
        consumptions: []
      };
      // If singleEdit is active, only add the fuelType that has a corrosponding consumption
      if (!singleEdit || consumption.find((fu) => fu.id === ft.id)) fuelsAboard.push(ft);
    });

    if (consumption) {
      console.log(consumption);
      consumption.forEach((cons) => {
        console.log(cons);
        const newConsumption = {
          quantity: cons.quantity || '',
          date: cons.date || '',
          fuel: cons.fuel.id || '',
          uom: cons.fuel.uom || '',
          title: cons.fuel.fuel || '',
          files: cons.files || [],
          id: cons.id || ''
        };

        const i = fuelsAboard.findIndex((x) => x.id === cons.fuel.fuelType.id);

        if (i <= -1) {
          const ft = {
            id: cons.fuel.fuelType.id,
            description: cons.fuel.fuelType.description,
            consumptions: [newConsumption]
          };
          fuelsAboard.push(ft);
        } else {
          const ft = fuelsAboard[i];
          let existingConsumptions = ft.consumptions;

          existingConsumptions = [...existingConsumptions, newConsumption];
          ft.consumptions = existingConsumptions;
        }
      });
    }

    fuelsAboard.forEach((fuel) => {
      if (fuel.consumptions.length === 0 && !singleEdit) {
        fuel.consumptions = [
          {
            quantity: '',
            date: '',
            fuel: '',
            uom: '',
            title: '',
            files: [],
            id: ''
          }
        ];
      }
    });

    setFuelUsage(fuelsAboard);
  }, [fuelTypes, consumption, singleEdit]);

  const Schema = Yup.object().shape({
    ship: Yup.number(),
    fuelConsumption: Yup.array().of(
      Yup.object().shape({
        consumptions: Yup.array().of(
          Yup.object().shape({
            quantity: Yup.number().required(t('required')),
            fuel: Yup.number().required(t('required')),
            files: Yup.array()
              .min(1, t('required'))
              .test('is-big-file', t('max_file_size', { size: maxUploadSize }), validateFileSize)
          })
        )
      })
    )
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ship: ship.id,
      fuelConsumption: fuelUsage
    },
    validationSchema: Schema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const { fuelConsumption } = values;

      await fuelConsumption.map(async (currentFuelConsumption) => {
        currentFuelConsumption.consumptions.map(async (consumption) => {
          consumption.fuel = Number.parseInt(consumption.fuel, 10);
          const promises = [];
          const existingFiles = [];
          for (let i = 0; i < consumption.files.length; i += 1) {
            const file = consumption.files[i];
            if (file instanceof File) promises.push(uploadFile(file));
            else existingFiles.push(file);
          }

          consumption.files = [...existingFiles];
          const newFiles = await Promise.all(promises);
          newFiles.map((file) => {
            consumption.files.push(file.fileName);
            return file;
          });
          consumption.date = date;
          if (consumption.id === '') delete consumption.id;
          await addFuelConsumption(consumption, ship.id);
        });
        return currentFuelConsumption;
      });
      submitCallback();
    }
  });

  const { values, errors, touched, isSubmitting, handleSubmit, setFieldValue, getFieldProps } =
    formik;

  const getFuelOptions = useCallback(
    (fuelType) => {
      const ft = fuelTypes.find((f) => f.fuelType.id === fuelType.id);
      if (!ft) return [];
      return ft.fuelType.fuels;
    },
    [fuelTypes]
  );

  useEffect(() => {
    values.fuelConsumption.forEach((fuelType, index) => {
      fuelType.consumptions.forEach((consumption, consumptionIndex) => {
        const fuelOptions = getFuelOptions(fuelType);
        if (fuelOptions.length === 1) {
          const singleFuelTypeId = fuelOptions[0].id;
          const currentValue = getFieldProps(
            `fuelConsumption.${index}.consumptions.${consumptionIndex}.fuel`
          ).value;
          if (currentValue !== singleFuelTypeId) {
            setFieldValue(
              `fuelConsumption.${index}.consumptions.${consumptionIndex}.fuel`,
              singleFuelTypeId
            );
          }
        }
      });
    });
  }, [getFieldProps, getFuelOptions, setFieldValue, values.fuelConsumption]);

  const handleFuelConsumptionDrop = useCallback(
    (acceptedFiles, fuelConsumptionId, fuelConsumptionFiles) => {
      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      );
      const totalFiles = [...fuelConsumptionFiles, ...newFiles];
      setFieldValue(fuelConsumptionId, totalFiles);
    },
    [setFieldValue]
  );

  const handleRemoveAll = (fuelConsumptionId) => {
    setFieldValue(fuelConsumptionId, []);
  };

  const handleRemove = (file, fuelConsumptionId, fuelConsumptionFiles) => {
    const filteredItems = fuelConsumptionFiles.filter((_file) => _file !== file);
    setFieldValue(fuelConsumptionId, filteredItems);
  };

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            {loadingFuelTypes ? (
              <Grid item xs={3}>
                <LinearProgress size={80} />
                <Typography variant="body2">{t('engine.loading_fuel_types')}</Typography>
              </Grid>
            ) : (
              <Stack spacing={3}>
                <FieldArray
                  name="fuelConsumption"
                  render={() =>
                    values.fuelConsumption.map((fuelType, index) => (
                      <div key={`ft${index}`}>
                        <Typography align="left" variant="body2">
                          {fuelType.description !== 'Ureum'
                            ? t('report.total_fuel_consumption', {
                                fuel: fuelType.description.toLowerCase()
                              })
                            : t('report.total_ureum_consumption')}
                        </Typography>
                        <FieldArray
                          name={`fuelConsumption.${index}.consumptions`}
                          render={(fccHelpers) =>
                            fuelType.consumptions.map((consumption, consumptionIndex) => {
                              const consumptionError =
                                errors?.fuelConsumption?.[index]?.consumptions?.[consumptionIndex];
                              const consumptionTouched =
                                touched?.fuelConsumption?.[index]?.consumptions?.[consumptionIndex];

                              return (
                                <Grid
                                  container
                                  spacing={2}
                                  style={{ padding: '1rem 0' }}
                                  key={`fc${consumptionIndex}`}
                                >
                                  <Grid item xs={6}>
                                    <TextField
                                      fullWidth
                                      label={t('report.fuel_consumption')}
                                      {...getFieldProps(
                                        `fuelConsumption.${index}.consumptions.${consumptionIndex}.quantity`
                                      )}
                                      type="number"
                                      onFocus={(e) =>
                                        e.target.addEventListener(
                                          'wheel',
                                          (e) => {
                                            e.preventDefault();
                                          },
                                          { passive: false }
                                        )
                                      }
                                      error={Boolean(
                                        consumptionTouched?.quantity && consumptionError?.quantity
                                      )}
                                      helperText={
                                        consumptionTouched?.quantity && consumptionError?.quantity
                                      }
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment
                                            position="end"
                                            style={{ paddingRight: '1rem' }}
                                          >
                                            <i>
                                              <b>{consumption?.uom}</b>
                                            </i>
                                          </InputAdornment>
                                        )
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Grid container>
                                      <Grid item xs={8}>
                                        <TextField
                                          select
                                          type="number"
                                          onFocus={(e) =>
                                            e.target.addEventListener(
                                              'wheel',
                                              (e) => {
                                                e.preventDefault();
                                              },
                                              { passive: false }
                                            )
                                          }
                                          fullWidth
                                          error={Boolean(
                                            consumptionTouched && consumptionError?.fuel
                                          )}
                                          helperText={consumptionTouched && consumptionError?.fuel}
                                          label={t('report.fuel_consumption')}
                                          {...getFieldProps(
                                            `fuelConsumption.${index}.consumptions.${consumptionIndex}.fuel`
                                          )}
                                          SelectProps={{ native: true }}
                                        >
                                          {getFuelOptions(fuelType).length > 1 && (
                                            <option>{t('choose')}</option>
                                          )}
                                          {getFuelOptions(fuelType).map((option) => (
                                            <option key={option.id} value={option.id}>
                                              {option.fuel}
                                            </option>
                                          ))}
                                        </TextField>
                                      </Grid>
                                      {fuelType.consumptions.length > 1 &&
                                        getFuelOptions(fuelType).length > 1 && (
                                          <Grid item xs={4}>
                                            <Button
                                              variant="contained"
                                              color="error"
                                              onClick={() => fccHelpers.remove(consumptionIndex)}
                                            >
                                              -
                                            </Button>
                                          </Grid>
                                        )}
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <div>
                                      <Typography align="left" variant="body2">
                                        {t('report.fuel_consumption_proof')}
                                      </Typography>
                                      <UploadFile
                                        showPreview
                                        maxSize={maxUploadSize}
                                        accept="image/*,.pdf,.doc,.docx"
                                        files={consumption.files}
                                        onDrop={(e) =>
                                          handleFuelConsumptionDrop(
                                            e,
                                            `fuelConsumption.${index}.consumptions.${consumptionIndex}.files`,
                                            consumption.files
                                          )
                                        }
                                        onRemove={(f) =>
                                          handleRemove(
                                            f,
                                            `fuelConsumption.${index}.consumptions.${consumptionIndex}.files`,
                                            consumption.files
                                          )
                                        }
                                        onRemoveAll={() =>
                                          handleRemoveAll(
                                            `fuelConsumption.${index}.consumptions.${consumptionIndex}.files`
                                          )
                                        }
                                        error={Boolean(
                                          consumptionTouched?.files && consumptionError?.files
                                        )}
                                      />
                                    </div>
                                  </Grid>
                                  {!singleEdit &&
                                    getFuelOptions(fuelType).length > 1 &&
                                    consumptionIndex + 1 ===
                                      values.fuelConsumption[index].consumptions.length && (
                                      <Grid item xs={12}>
                                        <Button
                                          variant="contained"
                                          color="success"
                                          onClick={() =>
                                            fccHelpers.push({
                                              quantity: '',
                                              date: '',
                                              fuel: '',
                                              uom: '',
                                              title: '',
                                              files: [],
                                              id: ''
                                            })
                                          }
                                        >
                                          {t('report.add_fuel_consumption')}
                                        </Button>
                                      </Grid>
                                    )}
                                </Grid>
                              );
                            })
                          }
                        />
                      </div>
                    ))
                  }
                />
              </Stack>
            )}
            <Grid container spacing={3} padding={3}>
              <Grid item xs={12} md={6}>
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-start' }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="error"
                    onClick={() => onCancel()}
                  >
                    {t(cancelButtonLabel)}
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                  <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                    {t(submitButtonLabel)}
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
