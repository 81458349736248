import { DetailCard, LabelStyle } from '@/layouts/styles.js';
import useStore from '@/store/use-store';
import { LoadingButton } from '@mui/lab';
import { Box, CardContent, Grid, Modal, Stack, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Form, FormikProvider, useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

const ModalDiv = styled('div')({
  position: 'fixed',
  width: '80%',
  minWidth: '200px',
  height: '90%',
  minHeight: '300px',
  overflow: 'auto',
  backgroundColor: 'rgba(0 , 0, 0, 0)',
  boxShadow: '0',
  padding: '0'
});

const ModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
};
InviteMemberModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  organization: PropTypes.object
};

export default function InviteMemberModal({ isOpen, close, organization }) {
  const { t } = useTranslation();

  const { inviteUser } = useStore((state) => state);

  const Schema = Yup.object().shape({
    email: Yup.string().email(t('email_invalid')).required(t('email_required'))
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: ''
    },
    validationSchema: Schema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      inviteUser(organization.id, values.email)
        .then(() => {
          toast.success(t('invitation.send'));
          close();
        })
        .catch((e) => {
          if (e === 'invite/pending') {
            toast.error(t('invitation.pending'));
          } else if (e === 'invite/double') {
            toast.error(t('invitation.already_member'));
          }
        });
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;
  return (
    <Modal open={isOpen} onClose={() => close()}>
      <ModalDiv style={ModalStyle()}>
        <DetailCard sx={{ p: 3 }}>
          <CardContent>
            <LabelStyle>{t('invite_member')}</LabelStyle>
            <FormikProvider value={formik}>
              <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <LabelStyle>{t('organization.invite_text')}</LabelStyle>
                    <Stack spacing={3}>
                      <TextField
                        fullWidth
                        label={t('email_adress')}
                        {...getFieldProps('email')}
                        error={Boolean(touched?.email && errors?.email)}
                        helperText={touched?.email && errors?.email}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                      <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                        {t('invite')}
                      </LoadingButton>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            </FormikProvider>
          </CardContent>
        </DetailCard>
      </ModalDiv>
    </Modal>
  );
}
