import type {
  CreateEngineOperatingHoursDTO,
  EngineOperatingHours
} from '@/types/engine-operating-hours';
import type { Ship } from './ship';

/**
 * Base interface for AnnualShipReport
 */
export interface AnnualShipReport {
  id: number;
  ship: Ship;
  year: number;
  unitsTransported: number | null;
  distanceTraveled: number | null;
  tonKilometerPerformance: number | null;
  added: string;
  engineOperatingHours: EngineOperatingHours[];
}

/**
 * DTO for creating/updating an AnnualShipReport
 * Omits id and makes ship optional (can be provided by context)
 * Makes added optional as it's set server-side by default
 */
export interface CreateAnnualShipReportDTO {
  id?: number | null;
  ship: number; // Just the ship ID for creation/update
  year: number;
  unitsTransported?: number | null;
  distanceTraveled?: number | null;
  tonKilometerPerformance?: number | null;
  added?: string;
  engineOperatingHours?: CreateEngineOperatingHoursDTO[];
}

/**
 * Validation schema for the form values
 * Extends CreateAnnualShipReportDTO with any additional form-specific fields
 */
export interface AnnualShipReportFormValues
  extends Omit<CreateAnnualShipReportDTO, 'engineOperatingHours'> {
  engineOperatingHours: EngineOperatingHoursFormValues[];
}

export interface EngineOperatingHoursFormValues {
  engine: { id: number; title: string };
  engineOperatingHours: number;
}

export const transformToCreateAnnualShipReportDTO = (
  formValues: AnnualShipReportFormValues
): CreateAnnualShipReportDTO => {
  const { engineOperatingHours, year, ...restValues } = formValues;

  return {
    ...restValues,
    year,
    engineOperatingHours: engineOperatingHours.map((item) => ({
      engine: item.engine.id,
      operatingHours: item.engineOperatingHours,
      year
    }))
  };
};

/**
 * API response type for AnnualShipReport
 * Useful when the API returns a different shape than the base interface
 */
export interface AnnualShipReportResponse extends Omit<AnnualShipReport, 'ship'> {
  ship: number; // API might return just the ID instead of the full ship object
}

/**
 * Type guard to check if an object is an AnnualShipReport
 */
export function isAnnualShipReport(obj: unknown): obj is AnnualShipReport {
  const report = obj as Record<string, unknown>;

  return (
    obj !== null &&
    typeof obj === 'object' &&
    typeof report.id === 'number' &&
    typeof report.year === 'number' &&
    typeof report.added === 'string' &&
    report.ship !== null &&
    typeof report.ship === 'object' &&
    Array.isArray(report.engineOperatingHours) &&
    (report.unitsTransported === null || typeof report.unitsTransported === 'number') &&
    (report.distanceTraveled === null || typeof report.distanceTraveled === 'number') &&
    (report.tonKilometerPerformance === null || typeof report.tonKilometerPerformance === 'number')
  );
}
