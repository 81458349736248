import { MoreMenuButton } from '@/components/MoreMenuButton';
import { PATH_ADMIN } from '@/routes/paths.js';
import useStore from '@/store/use-store';
import arrowheadDownOutline from '@iconify/icons-eva/arrowhead-down-outline';
import arrowheadUpOutline from '@iconify/icons-eva/arrowhead-up-outline';
import editFill from '@iconify/icons-eva/edit-fill';
import emailOutline from '@iconify/icons-eva/email-outline';
import peopleOutline from '@iconify/icons-eva/people-outline';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import { Icon } from '@iconify/react';
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, generatePath } from 'react-router-dom';
import { toast } from 'react-toastify';

UserMoreMenu.propTypes = {
  id: PropTypes.number,
  email: PropTypes.string,
  isAdmin: PropTypes.bool
};

export default function UserMoreMenu({ id, email, isAdmin }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const { deleteUser, passwordReset, impersonateUser, promoteAdminUser, demoteAdminUser } =
    useStore((state) => state);
  const { t } = useTranslation();

  const onDeleteClick = () => {
    deleteUser(id)
      // .then((res) => console.log(res))
      .catch((err) => {
        if (err.detail === 'ships/non-empty') {
          toast.error('Gebruiker niet verwijderd, deze heeft schepen.');
        }
      });
    setIsOpen(false);
  };

  const onImpersonateClick = () => {
    impersonateUser(email);
  };

  const onResetPasswordClick = () => {
    passwordReset(email);
    setIsOpen(false);
  };

  const onPromoteClick = () => {
    promoteAdminUser(id).then(() => toast.success('Gebruiker gepromoveerd'));
    setIsOpen(false);
  };

  const onDemoteClick = () => {
    demoteAdminUser(id).then(() => toast.success('Gebruiker gedemoveerd'));
    setIsOpen(false);
  };

  return (
    <>
      <MoreMenuButton isOpen={isOpen} setIsOpen={setIsOpen} menuId="user-more-menu" ref={ref} />

      <Menu
        id="user-more-menu"
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          component={RouterLink}
          to={generatePath(PATH_ADMIN.user, { id })}
          sx={{ color: 'text.secondary' }}
        >
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary={t('edit')} primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem sx={{ color: 'text.secondary' }} onClick={() => onResetPasswordClick()}>
          <ListItemIcon>
            <Icon icon={emailOutline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary={t('user.mail_password')}
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>

        <MenuItem sx={{ color: 'text.secondary' }} onClick={() => onDeleteClick()}>
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary={t('delete')} primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem
          sx={{ color: 'text.secondary' }}
          onClick={() => (isAdmin ? onDemoteClick() : onPromoteClick())}
        >
          <ListItemIcon>
            <Icon
              icon={isAdmin ? arrowheadDownOutline : arrowheadUpOutline}
              width={24}
              height={24}
            />
          </ListItemIcon>
          <ListItemText
            primary={isAdmin ? t('demote') : t('promote')}
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>

        <MenuItem sx={{ color: 'text.secondary' }} onClick={() => onImpersonateClick()}>
          <ListItemIcon>
            <Icon icon={peopleOutline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary={t('impersonate')} primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
}
