import PropTypes from 'prop-types';
import { CardContent, Modal } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { DetailCard, LabelStyle } from '../../../layouts/styles';
import FuelConsumptionForm from './FuelConsumptionForm';
import { fDate } from '../../../utils/formatTime';

const ModalDiv = styled('div')({
  position: 'fixed',
  width: '80%',
  minWidth: '200px',
  height: '90%',
  minHeight: '300px',
  overflow: 'auto',
  backgroundColor: 'rgba(0 , 0, 0, 0)',
  boxShadow: '0',
  padding: '0'
});

const ModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
};
FuelConsumptionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  request: PropTypes.object
};

export default function FuelConsumptionModal({ isOpen, close, request }) {
  const { t } = useTranslation();
  const dateFrom = request?.beginDate;
  const dateUntil = request?.endDate;

  return (
    <Modal open={isOpen} onClose={() => close()}>
      <ModalDiv style={ModalStyle()}>
        {isOpen && (
          <DetailCard sx={{ p: 3 }}>
            <CardContent>
              <LabelStyle>
                {t('fuel_consumption.fill_period', {
                  from: fDate(dateFrom),
                  to: fDate(dateUntil)
                })}
              </LabelStyle>
              <FuelConsumptionForm
                submitCallback={() => close()}
                onCancel={() => close()}
                date={dateUntil}
                ship={request.ship}
              />
            </CardContent>
          </DetailCard>
        )}
      </ModalDiv>
    </Modal>
  );
}
