import type { Engine } from '@/types/engine';
import type { Ship } from '@/types/ship.ts';

interface WizardState {
  wizardShip: Ship | null;
}

interface WizardActions {
  setWizardShip: (wizardShip: Ship | null) => void;
  updateWizardShipEngines: (engines: Engine[]) => void;
}

type SetState = (
  partial:
    | WizardState
    | Partial<WizardState>
    | ((state: WizardState) => WizardState | Partial<WizardState>),
  replace?: boolean | undefined
) => void;

const initialState: WizardState = {
  wizardShip: null
};

const wizardSlice = (set: SetState): WizardState & WizardActions => ({
  ...initialState,
  setWizardShip: (wizardShip: Ship | null) => {
    set({ wizardShip });
  },
  updateWizardShipEngines: (engines: Engine[]) => {
    set((state) => ({
      wizardShip: state.wizardShip
        ? {
            ...state.wizardShip,
            engines
          }
        : null
    }));
  }
});

export default wizardSlice;
