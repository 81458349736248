import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { MHidden } from '../components/@material-extend';

const HeaderStyle = styled('div')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7)
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  '& > p': {
    padding: theme.spacing(2, 0, 0, 0)
  }
}));

// ----------------------------------------------------------------------

AuthLayout.propTypes = {
  children: PropTypes.node
};

export default function AuthLayout({ children }) {
  return (
    <HeaderStyle>
      <div />
      <MHidden width="smDown">
        <ContentStyle>{children}</ContentStyle>
      </MHidden>
    </HeaderStyle>
  );
}
