import backgroundImage from '@/_assets/login_image.png';
import LoginForm from '@/components/authentication/login/login-form';
import useStore, { type StoreState } from '@/store/use-store';
import { Card, Container, Link, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { Theme } from '@mui/material/styles';
import i18n from 'i18next';
import { type ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { MHidden } from '../components/@material-extend';
import Page from '../components/Page';
import AuthLayout from '../layouts/AuthLayout';
import LanguagePopover from '../layouts/dashboard/LanguagePopover';

const RootStyle = styled(Page)<{ theme?: Theme }>(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(() => ({
  width: '100%',
  maxWidth: 520,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'cover',
  borderRadius: '0px',
  // Add alt text for background image
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.5)' // Improve contrast for better accessibility
  }
}));

const ContentStyle = styled('main')(({ theme }) => ({
  maxWidth: 520,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

export default function Login(): ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { authenticated, loadingUser, user, loggedOut, refresh } = useStore(
    (state: StoreState) => state
  );

  useEffect(() => {
    if (loggedOut) {
      // @ts-ignore
      toast.success(i18n.t('logged_out'), {
        role: 'alert'
      });
      refresh();
    }
    if (authenticated && 'email' in user && !loadingUser) {
      navigate('/', { replace: true });
    }
  }, [authenticated, navigate, loggedOut, refresh, user, loadingUser]);

  return (
    <RootStyle title="Login" aria-label="Login page">
      <AuthLayout>
        <nav aria-label={t('language_and_registration_navigation')}>
          <Stack
            direction="column"
            sx={{
              justifyContent: 'flex-start',
              alignItems: 'flex-end'
            }}
          >
            <LanguagePopover />
            <Typography
              variant="body2"
              component="div"
              sx={{
                mt: { md: 1 }
              }}
            >
              {t('no_account')}? &nbsp;
              <Link
                underline="none"
                variant="subtitle2"
                component={RouterLink}
                to="/register"
                sx={{
                  '&:focus': {
                    outline: '2px solid #1976d2',
                    outlineOffset: '2px'
                  },
                  '&:hover': {
                    textDecoration: 'underline'
                  }
                }}
              >
                {t('register')}
              </Link>
            </Typography>
          </Stack>
        </nav>
      </AuthLayout>

      <MHidden width="mdDown">
        <SectionStyle role="img" aria-label="Decorative login background image" />
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <header>
            <Stack sx={{ mb: 5 }}>
              <Typography
                variant="h2"
                component="h1"
                sx={{
                  color: 'text.primary',
                  fontWeight: 'bold'
                }}
              >
                Login
              </Typography>
            </Stack>
          </header>

          <LoginForm />

          <MHidden width="smUp">
            <Typography variant="body2" align="center" component="div" sx={{ mt: 3 }}>
              {t('no_account')}?&nbsp;
              <Link
                variant="subtitle2"
                component={RouterLink}
                to="/register"
                sx={{
                  '&:focus': {
                    outline: '2px solid #1976d2',
                    outlineOffset: '2px'
                  },
                  '&:hover': {
                    textDecoration: 'underline'
                  }
                }}
              >
                {t('register')}
              </Link>
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
