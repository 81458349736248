import useStore from '@/store/use-store';
import { CardHeader, Grid, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useDownloadList from '../../../../hooks/useDownloadList';
import { DetailCard } from '../../../../layouts/styles';
import { fDate, fYear } from '../../../../utils/formatTime';

EngineHoursTable.propTypes = {
  ship: PropTypes.object,
  year: PropTypes.number.isRequired
};

export default function EngineHoursTable({ ship, year }) {
  const { t } = useTranslation();
  const [engines, setEngines] = useState([]);
  const { getEngines } = useStore((state) => state);
  const { downloadButton } = useDownloadList();

  useEffect(() => {
    if (ship) {
      getEngines(ship.id).then((res) => setEngines(res));
    }
  }, [getEngines, ship]);

  const hourReading = [];
  engines.forEach((engine) =>
    engine.hourReadings.forEach((hr) => {
      if (fYear(hr.added) === year.toString()) {
        hr.engineTitle = engine.title;
        hourReading.push(hr);
      }
    })
  );

  return (
    <DetailCard>
      <CardHeader title={t('engine.hours')} />
      {hourReading.length === 0 ? (
        <Typography sx={{ pt: 1, pl: 2, pb: 1 }} variant="body2">
          {t('no_engine_hours_for_given_year', { year })}
        </Typography>
      ) : (
        <Stack spacing={2} sx={{ p: 3 }}>
          {hourReading.map((reading) => (
            <Grid key={reading.id} container>
              <Grid item xs={3}>
                <Typography variant="body2">{t('engine.title')}:</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="body2">{reading.engineTitle}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2">{t('engine.hours')}:</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="body2">{reading.reading}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2">{t('date_added')}:</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="body2">{fDate(reading.added)}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2">{t('engine.proof_no_limit')}:</Typography>
              </Grid>
              <Grid style={{ cursor: 'pointer' }} item xs={1}>
                {downloadButton(reading.proof.fileName)}
              </Grid>
              <Grid item alignItems="center" justifyContent="center" xs={8}>
                <Typography sx={{ pt: 0.5 }} variant="body2">
                  {reading.proof.originalName}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Stack>
      )}
    </DetailCard>
  );
}
