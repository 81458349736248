import HeaderBreadcrumbs from '@/components/HeaderBreadcrumbs';
import Page from '@/components/Page';
import EngineInfoStep from '@/components/_dashboard/label-request/steps/engine-info-step';
import FuelUsageStep from '@/components/_dashboard/label-request/steps/fuel-usage-step';
import IntroductionStep from '@/components/_dashboard/label-request/steps/introduction-step';
import RequestOverviewStep from '@/components/_dashboard/label-request/steps/request-overview-step';
import ShipInfoStep from '@/components/_dashboard/label-request/steps/ship-info-step';
import YearReportStep from '@/components/_dashboard/label-request/steps/year-report-step';
import { ConfigurableWizard, type WizardStep } from '@/components/wizard/wizard';
import { PATH_CLIENT } from '@/routes/paths';
import useStore from '@/store/use-store';
import type { Ship } from '@/types/ship';
import showToast from '@/utils/show-toast';
import { Card, Container } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const getStepFromSessionStorage = (ship: Ship | null) => {
  const sessionStep = Number(window.sessionStorage.getItem('step')) || 0;

  if (sessionStep > 1 && !ship) {
    return 1;
  }
  return sessionStep;
};

export default function LabelRequest() {
  const { t } = useTranslation();
  const { wizardShip } = useStore((state) => state);
  const [sessionValue, setSessionValue] = useState(getStepFromSessionStorage(wizardShip));

  // Update session storage for step
  useEffect(() => {
    const currentStorageValue = Number(window.sessionStorage.getItem('step'));
    if (sessionValue && currentStorageValue !== sessionValue) {
      window.sessionStorage.setItem('step', String(sessionValue));
    }
  }, [sessionValue]);

  const wizardSteps: WizardStep[] = [
    {
      label: t('introduction.title'),
      description: t('introduction.title'),
      component: <IntroductionStep />
    },
    {
      label: t('ship.ship'),
      description: t('ship.ship'),
      component: <ShipInfoStep />
    },
    {
      label: t('engine.information'),
      description: t('engine.information'),
      component: <EngineInfoStep />
    },
    {
      label: t('report.fuel_consumption'),
      description: t('report.fuel_consumption'),
      component: <FuelUsageStep />
    },
    {
      label: t('introduction.report'),
      description: t('introduction.report'),
      component: <YearReportStep />
    },
    {
      label: t('overview'),
      description: t('overview'),
      component: <RequestOverviewStep />
    }
  ];

  const handleComplete = async (): Promise<void> => {
    try {
      console.log('Form submitted');
    } catch (error) {
      console.error('Submission error:', error);
      throw error;
    }
  };

  const handleStepChange = (newStep?: number, direction?: 'forward' | 'backward'): void => {
    const currentStep = sessionValue;
    let nextStep = currentStep;

    if (direction) {
      nextStep = direction === 'forward' ? currentStep + 1 : currentStep - 1;
    } else if (newStep !== undefined) {
      nextStep = newStep;
    }

    // Check of de nieuwe step geldig is
    if (nextStep < 0 || nextStep >= wizardSteps.length) {
      showToast(t('no_step'), 'warning');
      return;
    }

    // Als we hier komen is de step geldig
    setSessionValue(nextStep);
  };

  return (
    <Page title={t('new_request')}>
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading={t('new_request')}
          links={[{ name: 'Dashboard', href: PATH_CLIENT.root }, { name: t('new_request') }]}
        />
        <Card sx={{ color: 'text.primary', marginBottom: 1 }}>
          <ConfigurableWizard
            steps={wizardSteps}
            onComplete={handleComplete}
            onStepChange={handleStepChange}
            nextButtonText={t('next')}
            backButtonText={t('back')}
            submitButtonText={t('save')}
            showStepNumbers={true}
            initialStep={sessionValue}
          />
        </Card>
      </Container>
    </Page>
  );
}
