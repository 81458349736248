import { LoadingButton } from '@mui/lab';
import { Box, Grid, Stack, TextField } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import 'react-confirm-alert/src/react-confirm-alert.css';
import useStore from '@/store/use-store';
import { toast } from 'react-toastify';
import { LabelStyle } from '../../../layouts/styles';

OrganizationForm.propTypes = {
  organization: PropTypes.object
};

export default function OrganizationForm({ organization }) {
  const [localOrganization, setLocalOrganization] = useState(organization);
  const { t } = useTranslation();
  const { updateOrganization } = useStore((state) => state);

  const OrganizationSchema = Yup.object().shape({
    title: Yup.string().required(t('required')),
    email: Yup.string().email(t('email_invalid')).required(t('email_required'))
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: localOrganization.title || '',
      email: localOrganization.email || '',
      id: localOrganization.id || ''
    },
    validationSchema: OrganizationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      updateOrganization(values)
        .then((res) => {
          setLocalOrganization(res);
          toast.success(t('organization.updated'));
        })
        .catch(() => toast.error(t('organization.update_error')));
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;
  const disabled = !organization?.isAdmin;
  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <LabelStyle>{t('organization.details')}</LabelStyle>
            <Stack spacing={3}>
              <TextField
                fullWidth
                label={t('organization.title')}
                {...getFieldProps('title')}
                error={Boolean(touched?.title && errors?.title)}
                helperText={touched?.title && errors?.title}
                disabled={disabled}
              />
              <TextField
                fullWidth
                label={t('email_adress')}
                {...getFieldProps('email')}
                error={Boolean(touched?.email && errors?.email)}
                helperText={touched?.email && errors?.email}
                disabled={disabled}
              />
            </Stack>
            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                {t('save')}
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
