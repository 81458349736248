import Loader from '@/components/Loader';
import { DetailCard } from '@/layouts/styles';
import useStore from '@/store/use-store';
import type { Ship } from '@/types/ship';
import { CardHeader, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import type React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface EngineInfoProps {
  ship?: Ship | null;
}

interface EngineDetailRowProps {
  label: string;
  value: string | number | undefined;
  testId?: string;
}

const EngineDetailRow: React.FC<EngineDetailRowProps> = ({ label, value, testId }) => (
  <>
    <Grid size={3}>
      <Typography variant="body2" component="dt" sx={{ fontWeight: 'medium' }}>
        {label}:
      </Typography>
    </Grid>
    <Grid size={9}>
      <Typography variant="body2" component="dd" sx={{ margin: 0 }} data-testid={testId}>
        {value ?? '-'}
      </Typography>
    </Grid>
  </>
);

const EngineInfo: React.FC<EngineInfoProps> = ({ ship }) => {
  const { t } = useTranslation();
  const { engines, getEngines, loadingEngines } = useStore((state) => state);

  useEffect(() => {
    const fetchEngines = async () => {
      if (ship?.id) {
        try {
          await getEngines(ship.id);
        } catch (error) {
          console.error('Failed to fetch engines:', error);
        }
      }
    };

    fetchEngines();
  }, [ship?.id, getEngines]);

  if (loadingEngines) {
    return (
      <DetailCard>
        <CardHeader
          title={t('request.engine_info')}
          component="h2"
          aria-label={t('request.engine_info_loading')}
        />
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: '100px' }}
        >
          <Loader text={t('engine.loading_details')} />
        </Grid>
      </DetailCard>
    );
  }

  if (!engines || engines.length === 0) {
    return (
      <DetailCard>
        <CardHeader title={t('request.engine_info')} component="h2" />
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: '100px' }}
        >
          <Grid size={3}>
            <Typography variant="body2" role="alert" aria-live="polite">
              {t('no_engines')}
            </Typography>
          </Grid>
        </Grid>
      </DetailCard>
    );
  }

  return (
    <DetailCard>
      <CardHeader title={t('request.engine_info')} component="h2" id="engine-info-header" />
      <Stack spacing={2} sx={{ p: 3 }} aria-labelledby="engine-info-header">
        {engines.map((engine) => (
          <div key={engine.id} aria-label={t('engine.details', { title: engine.title })}>
            <Grid container component="dl" spacing={1}>
              <EngineDetailRow
                label={t('engine.title')}
                value={engine.title}
                testId={`engine-title-${engine.id}`}
              />
              <EngineDetailRow
                label={t('engine.type')}
                value={engine.type?.description}
                testId={`engine-type-${engine.id}`}
              />
              <EngineDetailRow
                label={t('engine.power')}
                value={engine.power}
                testId={`engine-power-${engine.id}`}
              />
              <EngineDetailRow
                label={t('engine.current_hours')}
                value={engine?.latestReading?.reading}
                testId={`engine-hours-${engine.id}`}
              />
            </Grid>
          </div>
        ))}
      </Stack>
    </DetailCard>
  );
};

export default EngineInfo;
