import useStore from '@/store/use-store';
import type { FC } from 'react';
import './locales/i18n';
import ScrollToTop from '@/components/ScrollToTop';
import ThemeLocalization from '@/components/ThemeLocalization';
import ConfirmProvider from '@/providers/ConfirmProvider';
import Router from '@/routes';
import ThemeConfig from '@/theme/index';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import './style.css';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { nl } from 'date-fns/locale';

const App: FC = () => {
  useStore();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={nl}>
      <ThemeConfig>
        <ThemeLocalization>
          <ConfirmProvider>
            <ToastContainer />
            <ScrollToTop />
            <Router />
          </ConfirmProvider>
        </ThemeLocalization>
      </ThemeConfig>
    </LocalizationProvider>
  );
};

export default App;
