import PulsatingDots from '@/components/PulsatingDots';
import useStore from '@/store/use-store';
import { Button, Card, CardHeader, Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RequestInfo from './info/RequestInfo';
import UserInfo from './info/UserInfo';
import EngineInfo from './info/engine-info';
import ShipInfo from './info/ship-info';

RequestDetails.propTypes = {
  request: PropTypes.object,
  loading: PropTypes.bool
};

export default function RequestDetails({ request, loading }) {
  const { t } = useTranslation();
  const { getShip, loadingShip } = useStore((state) => state);
  const { getUser, loadingUser, impersonateUser } = useStore((state) => state);
  const [ship, setShip] = useState(null);
  const [user, setUser] = useState(null);
  console.log(user);
  useEffect(() => {
    if (request?.ship) {
      getShip(request.ship.id).then((res) => setShip(res));
      getUser(request.requester.id).then((res) => setUser(res));
    }
  }, [getShip, getUser, request]);

  if (!request) {
    return (
      <Card>
        <CardHeader title={t('request.ship_info')} />
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '100px' }}
        >
          <Grid xs={3}>
            <PulsatingDots size={15} />
          </Grid>
        </Grid>
      </Card>
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid size={12}>
        <Stack spacing={3}>
          <RequestInfo request={request} loading={loading} />
          <UserInfo user={user} loading={loadingUser} />
          <ShipInfo ship={ship} loading={loadingShip} />
          <EngineInfo ship={ship} />
          {user && (
            <Card sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', py: 1 }}>
              <Button
                variant="contained"
                color="success"
                sx={{ mx: 'auto' }}
                onClick={() => impersonateUser(user.email)}
              >
                Login als {user.firstName} {user.lastName}
              </Button>
            </Card>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
}
