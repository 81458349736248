import { Box, type BoxProps } from '@mui/material';
import PropTypes from 'prop-types';
import type React from 'react';
import { forwardRef } from 'react';
import { Helmet } from 'react-helmet-async';

interface PageProps extends BoxProps {
  children: React.ReactNode;
  title?: string;
}

const Page = forwardRef<HTMLDivElement, PageProps>(({ children, title = '', ...other }, ref) => (
  <Box ref={ref} {...other}>
    <Helmet>
      <title>{title} | Emissielabel</title>
    </Helmet>
    {children}
  </Box>
));

// PropTypes kunnen we behouden voor runtime checks
Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string
};

export default Page;
