import useStore, { type StoreState } from '@/store/use-store';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { Icon } from '@iconify/react';
import { LoadingButton } from '@mui/lab';
import { IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

interface ResetPasswordFormProps {
  token: string;
}

export default function ResetPasswordForm({ token }: ResetPasswordFormProps) {
  const { t } = useTranslation();
  const { setPassword, verifying } = useStore((state: StoreState) => state);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const Schema = Yup.object().shape({
    password: Yup.string()
      .min(8, t('too_short'))
      .test('regex', t('special_char'), (val) => {
        const regExp = /^.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?].*$/;
        return regExp.test(val || '');
      })
      .required(t('required')),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], t('password_match'))
      .required(t('required'))
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema: Schema,
    onSubmit: (values) => {
      setPassword(token, values.password).then((result) => {
        if (result === 'success') {
          toast.success(t('password_reset'));
          navigate('/login', { replace: true });
        } else {
          toast.error(result);
        }
      });
    }
  });

  if (!formik.isSubmitting && verifying) formik.setSubmitting(true);
  if (formik.isSubmitting && !verifying) formik.setSubmitting(false);

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleToggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    (<FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="current-password"
            id="password"
            type={showPassword ? 'text' : 'password'}
            label={t('password')}
            {...getFieldProps('password')}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
            aria-describedby="password-helper-text"
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={handleToggleShowPassword}
                      aria-label={t(showPassword ? 'hide_password' : 'show_password')}
                    >
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }
            }}
          />
          <TextField
            fullWidth
            id="confirm-password"
            type={showPassword ? 'text' : 'password'}
            label={t('confirm_password')}
            {...getFieldProps('confirmPassword')}
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            helperText={touched.confirmPassword && errors.confirmPassword}
            aria-describedby="confirm-password-helper-text"
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={handleToggleShowPassword}
                      aria-label={t(showPassword ? 'hide_password' : 'show_password')}
                    >
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }
            }}
          />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            aria-label={t('reset_password')}
          >
            {t('reset')}
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>)
  );
}
