import getAxiosConnection from '../utils/axios';

const initialState = {
  checkingRequirements: false,
  engines: [],
  engineTypes: [],
  engineTitles: [],
  expiredHourReadings: [],
  fuelTypes: [],
  loadingEngines: false,
  loadingEngine: false,
  loadingEngineTypes: false,
  loadingEngineTitles: false,
  loadingEngineOperatingHours: false,
  loadingFuelTypes: false,
  savingEngineOperatingHours: false,
  savingEngineHourReading: false,
  savingEngineType: false,
  savingEngine: false,
  savingEmissions: false,
  currentEngine: null,
  removingEngine: false
};

const engineSlice = (set, get) => ({
  ...initialState,
  getEngine: async (id) => {
    if (get().currentEngine && Number(get().currentEngine.id) === Number(id))
      return get().currentEngine;
    set({ loadingEngine: true });
    return getAxiosConnection()
      .get(`engine/${id}`)
      .then((res) => {
        set({ currentEngine: res.data });
        return res.data;
      })
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ loadingEngine: false });
      });
  },
  getEngines: async (ship) => {
    set({ loadingEngines: true });
    return getAxiosConnection()
      .get(`ship/${ship}/engines`)
      .then((res) => {
        const currentEngines = get().engines;
        let engines = [];
        if (Object.values(res.data).length > 0) {
          engines = res.data.map((obj) => currentEngines.find((o) => o.id === obj.id) || obj);
        }
        set({ engines });
        return engines;
      })
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ loadingEngines: false });
      });
  },
  createEngine: async (engine) => {
    set({ savingEngine: true });
    return getAxiosConnection()
      .post(`engines`, { ...engine })
      .then((res) => {
        set({ currentEngine: res.data });
        const currentEngines = [...get().engines, res.data];
        set({ engines: currentEngines });
        return res.data;
      })
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ savingEngine: false });
      });
  },
  updateEngine: async (engine) => {
    set({ savingEngine: true });
    return getAxiosConnection()
      .post(`engine/${engine.id}`, { ...engine })
      .then((res) => {
        set({ currentEngine: res.data });
        return res.data;
      })
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ savingEngine: false });
      });
  },
  createEngineHourReading: async (engineId, data) => {
    set({ savingEngineHourReading: true });
    const dataArray = new FormData();
    const keys = Object.keys(data);
    keys.forEach((key) => {
      if (key !== 'proof') {
        dataArray.append(key, data[key]);
      }
    });
    dataArray.append('file', data.proof);
    return getAxiosConnection('multipart/form-data')
      .post(`engine/${engineId}/hourReading`, dataArray)
      .then((res) => {
        set({
          currentEngine: res.data,
          engines: get().engines.map((engine) => (engine.id === res.data.id ? res.data : engine))
        });
        get().getExpiredHourReadings();
        return res.data;
      })
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ savingEngineHourReading: false });
      });
  },
  createEngineOperatingHours: async (engine, data) => {
    set({ savingEngineOperatingHours: true });
    return getAxiosConnection()
      .post(`engine/${engine}/operatingHours`, { ...data })
      .then((res) => {
        set({ currentEngine: res.data });
        return res.data;
      })
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ savingEngineOperatingHours: false });
      });
  },
  addUreumUsage: async (engine, data) => {
    set({ savingEngineOperatingHours: true });
    return getAxiosConnection()
      .post(`/engine/${engine}/ureumUsage`, { ...data })
      .then((res) => {
        set({ currentEngine: res.data });
        return res.data;
      })
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ savingEngineOperatingHours: false });
      });
  },
  getEngineTypes: async () => {
    set({ loadingEngineTypes: true });
    return getAxiosConnection()
      .get(`/enginetype`)
      .then((res) => {
        set({ engineTypes: res.data });
      })
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ loadingEngineTypes: false });
      });
  },
  createEngineType: async (engineType) => {
    set({ savingEngineType: true });
    return getAxiosConnection()
      .post(`enginetype`, { ...engineType })
      .then((res) => {
        get().getEngineTypes();
        return res.data;
      })
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ savingEngineType: false });
      });
  },
  updateEngineType: async (engineType) => {
    set({ savingEngineType: true });
    return getAxiosConnection()
      .post(`enginetype/${engineType.id}`, { ...engineType })
      .then((res) => {
        get().getEngineTypes();
        return res.data;
      })
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ savingEngineType: false });
      });
  },
  createEngineSubType: async (engineType, engineSubType) => {
    set({ savingEngineSubType: true });
    return getAxiosConnection()
      .post(`enginetype/${engineType.id}/subtype`, { ...engineSubType, engineType: engineType.id })
      .then((res) => {
        get().getEngineTypes();
        return res.data;
      })
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ savingEngineSubType: false });
      });
  },
  updateEngineSubType: async (engineType, engineSubType) => {
    set({ savingEngineSubType: true });
    return getAxiosConnection()
      .patch(`enginesubtype/${engineSubType.id}`, { ...engineSubType, engineType: engineType.id })
      .then((res) => {
        get().getEngineTypes();
        return res.data;
      })
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ savingEngineSubType: false });
      });
  },
  createEngineTitle: async (engineTitle) => {
    set({ savingEngineTitle: true });
    return getAxiosConnection()
      .post(`enginename`, { ...engineTitle })
      .then((res) => {
        get().getEngineTitles();
        return res.data;
      })
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ savingEngineTitle: false });
      });
  },
  updateEngineTitle: async (engineTitle) => {
    set({ savingEngineTitle: true });
    return getAxiosConnection()
      .post(`enginename/${engineTitle.id}`, { ...engineTitle })
      .then((res) => {
        get().getEngineTitles();
        return res.data;
      })
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ savingEngineTitle: false });
      });
  },
  getFuelTypes: async () => {
    set({ loadingFuelTypes: true });
    return getAxiosConnection()
      .get(`/fueltype`)
      .then((res) => {
        set({ fuelTypes: res.data });
      })
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ loadingFuelTypes: false });
      });
  },
  getEngineTitles: async () => {
    set({ loadingEngineTitles: true });
    return getAxiosConnection()
      .get(`/enginename`)
      .then((res) => {
        set({ engineTitles: res.data });
        return res.data;
      })
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ loadingEngineTitles: false });
      });
  },
  isEngineReportRequired: async (engineId) => {
    set({ checkingRequirements: true });
    return getAxiosConnection()
      .get(`/engine/${engineId}/reportRequired`)
      .then((res) => res.data)
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ checkingRequirements: false });
      });
  },
  isUreumReportRequired: async (engineId) => {
    set({ checkingRequirements: true });
    return getAxiosConnection()
      .get(`/engine/${engineId}/ureumRequired`)
      .then((res) => res.data)
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ checkingRequirements: false });
      });
  },
  removeEngine: async (id) => {
    set({ removingEngine: true });
    getAxiosConnection()
      .delete(`engine/${id}`)
      .then(() => {
        const oldEngines = get().engines;
        const engines = oldEngines.filter((engine) => engine.id !== id);
        set({ engines });
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        set({ removingEngine: false });
      });
  },
  setCurrentEngine: (currentEngine) => {
    set({ currentEngine });
  },
  removeCurrentEngine: () => {
    set({ currentEngine: null });
  },
  resetEngines: () => {
    set({ ...initialState }, true);
  },
  assignSubType: (engine, subType) => {
    set({ savingEngine: true });
    return getAxiosConnection()
      .get(`engine/${engine.id}/assignSubType/${subType.id}`)
      .then((res) => {
        const currentEngines = get().engines;
        let engines = [];
        engines = currentEngines.map((engine) => (engine.id === res.data.id ? res.data : engine));
        set({ engines });
        return res.data;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        set({ savingEngine: false });
      });
  },
  fillEmissionFromSubtype: (engine) => {
    set({ savingEngine: true });
    return getAxiosConnection()
      .get(`/engine/${engine.id}/fillEmissions`)
      .then((res) => {
        const currentEngines = get().engines;
        let engines = [];
        engines = currentEngines.map((engine) => (engine.id === res.data.id ? res.data : engine));
        set({ engines });
        return res.data;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        set({ savingEngine: false });
      });
  },
  createEmissionLimit: (emissionLimit, engine) => {
    set({ savingEmissions: true });
    return getAxiosConnection()
      .post(`/engine/${engine.id}/emissions`, { ...emissionLimit })
      .then((res) => res.data)
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        set({ savingEmissions: false });
      });
  },
  updateEmissionLimit: (emissionLimit) => {
    set({ savingEmissions: true });
    return getAxiosConnection()
      .patch(`/emissions/${emissionLimit.id}`, { ...emissionLimit })
      .then((res) => res.data)
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        set({ savingEmissions: false });
      });
  },
  setEngines: (engines) => {
    set({ engines });
  },
  getExpiredHourReadings: async () => {
    set({ loadingEngineOperatingHours: true });
    getAxiosConnection()
      .get(`/user/expiredHourReadings`)
      .then((res) => {
        set({ expiredHourReadings: res.data });
        return res.data;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        set({ loadingEngineOperatingHours: false });
      });
  }
});

export default engineSlice;
