import { PATH_CLIENT } from '@/routes/paths.js';
import useStore from '@/store/use-store';
import { LoadingButton } from '@mui/lab';
import { Box, Grid, InputAdornment, Stack, TextField } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { Persist } from 'formik-persist';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

ShipForm.propTypes = {
  ship: PropTypes.object,
  submitCallback: PropTypes.func
};

export default function ShipForm({ ship, submitCallback }) {
  const { t } = useTranslation();
  const { updateShip, createShip, savingShip } = useStore((state) => state);
  const { getShipTypes, shipTypes, loadingShipTypes } = useStore((state) => state);
  const { searchShipByName, searchShipByENI, searchingShip } = useStore((state) => state);
  const [foundShip, setFoundShip] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    getShipTypes();
  }, [getShipTypes]);

  const ShipSchema = Yup.object().shape({
    title: Yup.string().required(t('required')),
    eni: Yup.string().required(t('required')),
    type: Yup.number().required(t('required')),
    year: Yup.number().required(t('required')),
    length: Yup.number().nullable(true),
    width: Yup.number().nullable(true),
    payloadTonne: Yup.number().nullable(true),
    payloadQubicMeters: Yup.number().nullable(true),
    payloadTEU: Yup.number().nullable(true),
    payloadPassengers: Yup.number().nullable(true)
  });

  const findShipTypeByName = (name) => {
    if (!name) return '';
    const t = shipTypes.find((type) => type.description.toLowerCase() === name.toLowerCase());
    return t ? Number.parseInt(t.id, 10) : '';
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: ship?.id || '',
      title: ship?.title || foundShip?.shipName || '',
      eni: ship?.eni || foundShip?.eniNumber.toString() || '',
      type: ship?.type?.id || findShipTypeByName(foundShip?.shipType) || '',
      length: ship?.length || Number.parseFloat(foundShip?.mbLength) || '',
      width: ship?.width || Number.parseFloat(foundShip?.mbBeam) || '',
      payloadTonne: ship?.payloadTonne || Number.parseFloat(foundShip?.tonnage) || '',
      payloadQubicMeters: ship?.payloadQubicMeters || '',
      payloadTEU: ship?.payloadTEU || '',
      payloadPassengers: ship?.payloadPassengers || '',
      year: ship?.year || foundShip?.yearBuilt || new Date().getFullYear()
    },
    validationSchema: ShipSchema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      if (ship) values.id = ship.id;
      if (values.id === '') delete values.id;
      if (values.length === '') delete values.length;
      if (values.width === '') delete values.width;
      if (values.payloadTonne === '') delete values.payloadTonne;
      if (values.payloadQubicMeters === '') delete values.payloadQubicMeters;
      if (values.payloadTEU === '') delete values.payloadTEU;
      if (values.payloadPassengers === '') delete values.payloadPassengers;
      values.eni = values.eni.toString();
      values.type = Number.parseInt(values.type, 10);

      const executable = ship ? updateShip : createShip;
      try {
        executable(values)
          .then((res) => {
            toast.success(ship ? t('ship.edited') : t('ship.added'));
            if (submitCallback) submitCallback(res);
            else navigate(`${PATH_CLIENT.ship}/${res.id}`);
          })
          .catch((error) => {
            console.log(error);
            toast.error(t('generic_error'));
          });
        setSubmitting(false);
      } catch (error) {
        setSubmitting(false);
        setErrors(error);
      }
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const onENIFilled = (e) => {
    searchShipByENI(e.target.value).then((res) => {
      if (res.length === 1) setFoundShip(res[0]);
    });
  };

  const onTitleFilled = (e) => {
    searchShipByName(e.target.value).then((res) => {
      if (res.length === 1) setFoundShip(res[0]);
    });
  };

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3} padding={3}>
          <Grid item xs={12} md={12}>
            <Stack spacing={3}>
              <TextField
                fullWidth
                label={t('ship.eni')}
                {...getFieldProps('eni')}
                error={Boolean(touched.eni && errors.eni)}
                helperText={touched.eni && errors.eni}
                onBlur={onENIFilled}
                disabled={searchingShip}
              />
              <TextField
                fullWidth
                label={t('ship.title')}
                {...getFieldProps('title')}
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
                onBlur={onTitleFilled}
              />
              {loadingShipTypes && <TextField disabled fullWidth value={t('ship.loading_types')} />}
              {!loadingShipTypes && (
                <TextField
                  select
                  fullWidth
                  label={t('ship.type')}
                  placeholder={t('ship.type')}
                  {...getFieldProps('type')}
                  SelectProps={{ native: true }}
                  error={Boolean(touched.type && errors.type)}
                  helperText={touched.type && errors.type}
                  disabled={loadingShipTypes}
                  type="number"
                  onFocus={(e) =>
                    e.target.addEventListener(
                      'wheel',
                      (e) => {
                        e.preventDefault();
                      },
                      { passive: false }
                    )
                  }
                >
                  <option value="" />
                  {shipTypes.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.description}
                    </option>
                  ))}
                </TextField>
              )}
              <TextField
                fullWidth
                label={t('ship.length')}
                {...getFieldProps('length')}
                error={Boolean(touched.length && errors.length)}
                helperText={touched.length && errors.length}
                type="number"
                onFocus={(e) =>
                  e.target.addEventListener(
                    'wheel',
                    (e) => {
                      e.preventDefault();
                    },
                    { passive: false }
                  )
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" style={{ paddingRight: '1rem' }}>
                      m
                    </InputAdornment>
                  )
                }}
              />
              <TextField
                fullWidth
                label={t('ship.width')}
                {...getFieldProps('width')}
                error={Boolean(touched.width && errors.width)}
                helperText={touched.width && errors.width}
                type="number"
                onFocus={(e) =>
                  e.target.addEventListener(
                    'wheel',
                    (e) => {
                      e.preventDefault();
                    },
                    { passive: false }
                  )
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" style={{ paddingRight: '1rem' }}>
                      m
                    </InputAdornment>
                  )
                }}
              />
              <TextField
                fullWidth
                label={t('ship.year')}
                {...getFieldProps('year')}
                error={Boolean(touched.year && errors.year)}
                helperText={touched.year && errors.year}
                type="number"
                onFocus={(e) =>
                  e.target.addEventListener(
                    'wheel',
                    (e) => {
                      e.preventDefault();
                    },
                    { passive: false }
                  )
                }
              />
              <TextField
                fullWidth
                label={t('ship.payloadTonne')}
                {...getFieldProps('payloadTonne')}
                error={Boolean(touched.payloadTonne && errors.payloadTonne)}
                helperText={touched.payloadTonne && errors.payloadTonne}
                type="number"
                onFocus={(e) =>
                  e.target.addEventListener(
                    'wheel',
                    (e) => {
                      e.preventDefault();
                    },
                    { passive: false }
                  )
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" style={{ paddingRight: '1rem' }}>
                      ton
                    </InputAdornment>
                  )
                }}
              />
              <TextField
                fullWidth
                label={t('ship.payloadQubicMeters')}
                {...getFieldProps('payloadQubicMeters')}
                error={Boolean(touched.payloadQubicMeters && errors.payloadQubicMeters)}
                helperText={touched.payloadQubicMeters && errors.payloadQubicMeters}
                type="number"
                onFocus={(e) =>
                  e.target.addEventListener(
                    'wheel',
                    (e) => {
                      e.preventDefault();
                    },
                    { passive: false }
                  )
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" style={{ paddingRight: '1rem' }}>
                      m<sup>3</sup>
                    </InputAdornment>
                  )
                }}
              />
              <TextField
                fullWidth
                label={t('ship.payloadTEU')}
                {...getFieldProps('payloadTEU')}
                error={Boolean(touched.payloadTEU && errors.payloadTEU)}
                helperText={touched.payloadTEU && errors.payloadTEU}
                type="number"
                onFocus={(e) =>
                  e.target.addEventListener(
                    'wheel',
                    (e) => {
                      e.preventDefault();
                    },
                    { passive: false }
                  )
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" style={{ paddingRight: '1rem' }}>
                      TEU
                    </InputAdornment>
                  )
                }}
              />
              <TextField
                fullWidth
                label={t('ship.payloadPassengers')}
                {...getFieldProps('payloadPassengers')}
                error={Boolean(touched.payloadPassengers && errors.payloadPassengers)}
                helperText={touched.payloadPassengers && errors.payloadPassengers}
                type="number"
                onFocus={(e) =>
                  e.target.addEventListener(
                    'wheel',
                    (e) => {
                      e.preventDefault();
                    },
                    { passive: false }
                  )
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" style={{ paddingRight: '1rem' }}>
                      {t('passengers')}
                    </InputAdornment>
                  )
                }}
              />
            </Stack>
            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting || savingShip}>
                {ship ? t('edit') : t('save')}
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
        <Persist name={`ship-form${ship ? ship.id : ''}`} />
      </Form>
    </FormikProvider>
  );
}
