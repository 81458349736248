import PulsatingDots from '@/components/PulsatingDots';
import SearchNotFound from '@/components/SearchNotFound';
import ShipListToolbar from '@/components/_dashboard/ship/list/ShipListToolbar';
import ShipMoreMenu from '@/components/_dashboard/ship/list/ShipMoreMenu';
import OverviewTableHead from '@/components/overview-table/overview-table-head';
import {
  type NestedKeyOf,
  type Order,
  type TableHeadItem,
  applySortFilter,
  calculateEmptyRows,
  getComparator
} from '@/components/overview-table/table-utils';
import useStore, { type StoreState } from '@/store/use-store';
import {
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Toolbar,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import type React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

const DetailCard = styled(Card)(({ theme }) => ({
  ...theme.typography.h4,
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(1)
}));

const RootStyle = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'space-between'
});

const RightBox = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '1rem'
});

type SortableShipKeys = 'id' | 'title' | 'eni' | 'type' | 'engines' | 'createdBy.name';

type SortableShip = {
  [key: string]: unknown;
} & Record<SortableShipKeys, string>;

const ShipList = () => {
  const { t } = useTranslation();
  const { ships, getShips, loadingShips, deletingShip, user } = useStore(
    (store: StoreState) => store
  );

  const [orderBy, setOrderBy] = useState<NestedKeyOf<SortableShip>>('title');
  const [order, setOrder] = useState<Order>('asc');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filterName, setFilterName] = useState('');

  const isAdmin = useMemo(() => {
    // @ts-ignore //TODO aanpassen van de serializers zodat alles hetzelfde meekomt nu zijn return values niet consistent met de types.
    return user?.roles?.includes('ROLE_ADMIN') || user?.organization.roles.includes('ROLE_ADMIN');
  }, [user]);

  useEffect(() => {
    getShips();
  }, [getShips]);

  const TABLE_HEAD: TableHeadItem<SortableShip>[] = useMemo(
    () => [
      { id: 'title', label: t('ship_name'), sortable: true },
      { id: 'eni', label: 'ENI', sortable: true },
      { id: 'type', label: t('ship.type'), sortable: true },
      { id: 'engines', label: t('no_of_engines'), sortable: true },
      ...(isAdmin ? [{ id: 'createdBy.name', label: t('creator'), sortable: true }] : []),
      { id: '', label: t('actions'), alignRight: true }
    ],
    [isAdmin, t]
  );

  const handleSort = (property: NestedKeyOf<SortableShip>) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleFilterByName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterName(event.target.value);
    setPage(0);
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number.parseInt(event.target.value, 10));
    setPage(0);
  };

  // Transform ships to sortable format
  const sortableShips = useMemo(
    () =>
      ships.map(
        (ship) =>
          ({
            ...ship,
            'createdBy.name': ship.createdBy.name
          }) as SortableShip
      ),
    [ships]
  );

  const filteredShips = useMemo(() => {
    const sortedShips = applySortFilter<SortableShip>(
      sortableShips,
      getComparator<SortableShip>(order, orderBy)
    );

    if (!filterName) return sortedShips;

    return sortedShips.filter((ship) =>
      ship.title.toLowerCase().includes(filterName.toLowerCase())
    );
  }, [sortableShips, order, orderBy, filterName]);

  const emptyRows = calculateEmptyRows(page, rowsPerPage, ships.length);
  const isShipNotFound = filteredShips.length === 0 && ships.length > 0;
  const isShipsEmpty = ships.length === 0;
  const isLoading = loadingShips || deletingShip;

  return (
    <DetailCard aria-labelledby="ship-list-title">
      <RootStyle>
        <Typography
          id="ship-list-title"
          variant="h4"
          sx={{
            my: 'auto',
            fontSize: '1.5rem',
            fontWeight: 'bold'
          }}
        >
          {t('my_ships')}
        </Typography>
        <RightBox>
          <ShipListToolbar filterName={filterName} onFilterName={handleFilterByName} />
        </RightBox>
      </RootStyle>

      <TableContainer sx={{ p: 1 }}>
        <Table
          aria-label={t('ship.table_aria_label')}
          aria-busy={isLoading}
          aria-describedby={isShipsEmpty ? 'no-ships-message' : undefined}
        >
          <OverviewTableHead
            headLabel={TABLE_HEAD}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleSort}
          />
          <TableBody>
            {filteredShips
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover key={row.id}>
                    <TableCell component="th" scope="row">
                      <Typography
                        component={RouterLink}
                        to={`${row.id}`}
                        variant="subtitle2"
                        sx={{ textDecoration: 'none' }}
                      >
                        {row.title}
                      </Typography>
                    </TableCell>
                    <TableCell>{row.eni}</TableCell>
                    <TableCell>{row.type}</TableCell>
                    <TableCell>{row.engines}</TableCell>

                    {isAdmin && (
                      // @ts-ignore: 'createdBy.name' zit nog verkeerde in de API data door de custom serializer
                      <TableCell>{row.createdBy.name}</TableCell>
                    )}
                    <TableCell align="right">
                      <ShipMoreMenu
                        ship={row}
                        aria-label={t('ship.actions.menu', { name: row.title })}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={isAdmin ? 7 : 6} />
              </TableRow>
            )}
          </TableBody>

          {isShipNotFound && (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={isAdmin ? 7 : 6} sx={{ py: 3 }}>
                  <SearchNotFound searchQuery={filterName} />
                </TableCell>
              </TableRow>
            </TableBody>
          )}

          {isLoading && (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={isAdmin ? 7 : 6} sx={{ py: 3 }}>
                  <Paper>
                    <PulsatingDots size={15} aria-label={t('loading')} />
                  </Paper>
                </TableCell>
              </TableRow>
            </TableBody>
          )}

          {isShipsEmpty && !isLoading && (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={isAdmin ? 7 : 6} sx={{ py: 3 }}>
                  <Paper>
                    <Typography
                      id="no-ships-message"
                      gutterBottom
                      align="center"
                      variant="subtitle1"
                    >
                      {t('no_ships')}
                    </Typography>
                  </Paper>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={filteredShips.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage={t('table.rows_per_page')}
        getItemAriaLabel={(type) => t(`table.${type}_page`)}
        labelDisplayedRows={({ from, to, count }) => t('table.displayed_rows', { from, to, count })}
      />
    </DetailCard>
  );
};

export default ShipList;
