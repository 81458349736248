import PulsatingDots from '@/components/PulsatingDots';
import useStore from '@/store/use-store';
import cloudDownloadOutline from '@iconify/icons-eva/cloud-download-outline';
import { Icon } from '@iconify/react';
// material
import {
  Card,
  Container,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import LabelListHead from '../../components/_dashboard/label/LabelListHead';
import LabelListToolbar from '../../components/_dashboard/label/LabelListToolbar';
import { fDate } from '../../utils/formatTime';
import { getComparator } from '../../utils/tableUtils';

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_label) => {
      const credentials = _label.documentNumber + _label.ship.title;
      return credentials.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function DistributedLabel() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { loadingLabels, labels, getLabels } = useStore((state) => state);
  const { downloadLabel, downloadingLabel } = useStore((state) => state);
  const { t } = useTranslation();

  useEffect(() => {
    getLabels();
  }, [getLabels]);

  const TABLE_HEAD = [
    { id: 'documentNumber', label: t('label.documentNumber'), alignRight: false },
    { id: 'title', label: t('ship_name'), alignRight: false },
    { id: 'label', label: t('label.label'), alignRight: false },
    { id: 'valid_from', label: t('label.valid_from'), alignRight: false },
    { id: '' }
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(Number.parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - labels.length) : 0;

  const filteredLabels = applySortFilter(labels, getComparator(order, orderBy), filterName);

  const isUserNotFound = !loadingLabels && filteredLabels.length === 0;

  return (
    <Page title="labels">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('labels')}
          </Typography>
        </Stack>

        <Card>
          <LabelListToolbar
            numSelected={0}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <LabelListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={labels.length}
                  numSelected={0}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredLabels
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const {
                        id,
                        documentNumber,
                        ship,
                        climateLabel,
                        airQualityLabel,
                        dateOfGeneration
                      } = row;

                      return (
                        <TableRow hover key={id} tabIndex={-1}>
                          <TableCell align="left">{documentNumber}</TableCell>
                          <TableCell align="left">{ship?.title}</TableCell>
                          <TableCell align="left">
                            {climateLabel} {airQualityLabel}
                          </TableCell>
                          <TableCell align="left">{fDate(dateOfGeneration)}</TableCell>
                          <TableCell>
                            {downloadingLabel ? (
                              <PulsatingDots size={5} />
                            ) : (
                              <Icon
                                onClick={() => downloadLabel(id, documentNumber)}
                                icon={cloudDownloadOutline}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
                {loadingLabels && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper>
                          <PulsatingDots size={15} />
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={labels.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
