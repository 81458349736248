import { AccessibleStepper } from '@/components/wizard/accessible-stepper';
import { Box, Button, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useMemo, useRef } from 'react';
import { type ReactNode, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

export interface WizardStep {
  label: string;
  description: string;
  component: ReactNode;
  canProceed?: boolean;
  skipNext?: boolean;
}

export interface WizardProps {
  steps: WizardStep[];
  onComplete: () => void | Promise<void>;
  onStepChange?: (newStep?: number, direction?: 'forward' | 'backward') => void;
  submitButtonText?: string;
  nextButtonText?: string;
  backButtonText?: string;
  showStepNumbers?: boolean;
  initialStep?: number;
}

export interface WizardStepComponentProps {
  triggerNext?: () => Promise<void>;
  triggerPrevious?: () => Promise<void>;
  isFirstStep?: boolean;
  isLastStep?: boolean;
  setCanProceed: (canProceed: boolean) => void;
  setSkipNext: (skipNext: boolean) => void;
  registerBeforeNext: (action: () => Promise<boolean>) => void;
}

export interface WizardFormMethods {
  submit: () => Promise<boolean>;
}

export const ConfigurableWizard: React.FC<WizardProps> = ({
  steps,
  onComplete,
  onStepChange,
  submitButtonText = 'Verzenden',
  nextButtonText = 'Volgende',
  backButtonText = 'Vorige',
  initialStep = 0
}) => {
  const [activeStep, setActiveStep] = useState<number>(initialStep);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [stepStates, setStepStates] = useState<Array<{ canProceed: boolean; skipNext: boolean }>>(
    steps.map(() => ({ canProceed: true, skipNext: false }))
  );

  // Ref to store the beforeNext action for the current step.
  const beforeNextActionRef = useRef<(() => Promise<boolean>) | null>(null);

  // Clean up the ref when the component unmounts
  useEffect(() => {
    return () => {
      beforeNextActionRef.current = null;
    };
  }, []);

  const theme = useTheme();
  const { t } = useTranslation();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const setStepCanProceed = useCallback((stepIndex: number, canProceed: boolean) => {
    setStepStates((prevStates) =>
      prevStates.map((state, index) => (index === stepIndex ? { ...state, canProceed } : state))
    );
  }, []);

  const setStepSkipNext = useCallback((stepIndex: number, skipNext: boolean) => {
    setStepStates((prevStates) =>
      prevStates.map((state, index) => (index === stepIndex ? { ...state, skipNext } : state))
    );
  }, []);

  const getNextStep = useCallback(
    (currentStep: number): number => {
      if (stepStates[currentStep]?.skipNext) {
        // Skip next step if marked
        return Math.min(currentStep + 2, steps.length - 1);
      }
      return currentStep + 1;
    },
    [stepStates, steps.length]
  );

  const handleNext = useCallback(async () => {
    setIsProcessing(true);
    try {
      if (beforeNextActionRef.current) {
        const canProceed = await beforeNextActionRef.current();

        if (!canProceed) {
          return; // Stop if the action returns false
        }
        beforeNextActionRef.current = null; // Reset the ref after execution
      }
      // Action for last step
      if (activeStep === steps.length - 1) {
        await onComplete();
      } else {
        const nextStep = getNextStep(activeStep);
        setActiveStep(nextStep);
        onStepChange?.(nextStep, 'forward');
      }
    } catch (error) {
      toast.error(t('generic_error'));
      console.error('Wizard error:', error);
    } finally {
      setIsProcessing(false);
    }
  }, [activeStep, steps.length, onComplete, onStepChange, getNextStep, t]);

  const handleBack = useCallback(() => {
    // Reset the ref when moving backward
    beforeNextActionRef.current = null;

    // Go back to the previous, non-skipped, step
    let prevStep = activeStep - 1;
    while (prevStep > 0 && stepStates[prevStep - 1]?.skipNext) {
      prevStep--;
    }
    setActiveStep(prevStep);
    onStepChange?.(prevStep, 'backward');
  }, [activeStep, onStepChange, stepStates]);

  const handleSetCanProceed = useCallback(
    (canProceed: boolean) => setStepCanProceed(activeStep, canProceed),
    [activeStep, setStepCanProceed]
  );

  const handleSetSkipNext = useCallback(
    (skipNext: boolean) => setStepSkipNext(activeStep, skipNext),
    [activeStep, setStepSkipNext]
  );

  const registerBeforeNext = useCallback((action: () => Promise<boolean>) => {
    beforeNextActionRef.current = action;
  }, []);

  const currentStepCallbacks = useMemo(
    () => ({
      setCanProceed: handleSetCanProceed,
      setSkipNext: handleSetSkipNext,
      triggerNext: handleNext,
      triggerPrevious: handleBack,
      registerBeforeNext
    }),
    [handleSetCanProceed, handleSetSkipNext, handleNext, handleBack, registerBeforeNext]
  );

  return (
    <Box sx={{ width: '100%', mx: 'auto', p: { xs: 2, sm: 3 } }}>
      <AccessibleStepper
        steps={steps.map((step) => ({
          label: step.label,
          description: step.description
        }))}
        activeStep={activeStep}
        orientation={isMobile ? 'vertical' : 'horizontal'}
      />

      <Box sx={{ flex: 1, mb: 3 }}>
        {React.cloneElement(steps[activeStep].component as React.ReactElement, {
          ...currentStepCallbacks,
          isFirstStep: activeStep === 0,
          isLastStep: activeStep === steps.length - 1
        })}
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: { xs: 'column', sm: 'row' },
          gap: 2,
          mt: 'auto',
          pt: 2,
          borderTop: 1,
          borderColor: 'divider'
        }}
      >
        <Button
          onClick={handleBack}
          disabled={activeStep === 0 || isProcessing}
          variant="outlined"
          color="primary"
          fullWidth={isMobile}
          sx={{ order: { xs: 2, sm: 1 } }}
        >
          {backButtonText}
        </Button>
        <Button
          onClick={handleNext}
          disabled={isProcessing || !stepStates[activeStep]?.canProceed}
          variant="contained"
          color={activeStep === steps.length - 1 ? 'success' : 'primary'}
          fullWidth={isMobile}
          sx={{ order: { xs: 1, sm: 2 } }}
        >
          {activeStep === steps.length - 1 ? submitButtonText : nextButtonText}
        </Button>
      </Box>
    </Box>
  );
};
