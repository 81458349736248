import PulsatingDots from '@/components/PulsatingDots';
import { PATH_ADMIN } from '@/routes/paths.js';
import useStore from '@/store/use-store';
import { Container } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Page from '../../components/Page';
import UserForm from '../../components/_dashboard/admin/user/UserForm';

export default function UserCreate() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isEdit = !pathname.includes('create');
  const { id } = useParams();
  const [currentUser, setCurrentUser] = useState(null);
  const { createUser, getUser, loadingUser } = useStore((state) => state);
  let title = currentUser ? `${currentUser.firstName} ${currentUser.lastName}` : t('user.create');

  title = loadingUser ? t('user.loading') : title;

  useEffect(() => {
    if (isEdit)
      getUser(id).then((user) => {
        setCurrentUser(user);
      });
  }, [getUser, id, isEdit, setCurrentUser]);

  const saveUser = (user) => createUser(user);

  return (
    <Page title={title}>
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading={title}
          links={[
            { name: 'Dashboard', href: PATH_ADMIN.root },
            { name: t('users'), href: PATH_ADMIN.users },
            { name: title }
          ]}
        />
        {loadingUser && <PulsatingDots size={15} />}
        {!loadingUser && <UserForm onSubmit={saveUser} currentUser={currentUser} isEdit={isEdit} />}
      </Container>
    </Page>
  );
}
