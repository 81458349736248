import { SrOnly, WizardProgress } from '@/components/wizard/styles';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CircleIcon from '@mui/icons-material/Circle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Step, type StepIconProps, StepLabel, Stepper, styled } from '@mui/material';
import type React from 'react';
import { useTranslation } from 'react-i18next';

const AccessibleStepIcon = styled((props: StepIconProps) => {
  const { active, completed, className } = props;

  return (
    <div className={className} aria-hidden="true">
      {completed ? (
        <CheckCircleOutlineIcon className="step-icon-completed" aria-hidden="true" />
      ) : active ? (
        <CircleIcon className="step-icon-active" aria-hidden="true" />
      ) : (
        <RadioButtonUncheckedIcon className="step-icon" aria-hidden="true" />
      )}
    </div>
  );
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& .step-icon': {
    color: theme.palette.text.disabled,
    width: 24,
    height: 24
  },
  '& .step-icon-active': {
    color: theme.palette.primary.main,
    width: 24,
    height: 24
  },
  '& .step-icon-completed': {
    color: theme.palette.primary.main,
    width: 24,
    height: 24
  }
}));

interface AccessibleStepperProps {
  steps: Array<{
    label: string;
    description?: string;
  }>;
  activeStep: number;
  orientation?: 'horizontal' | 'vertical';
}

export const AccessibleStepper: React.FC<AccessibleStepperProps> = ({
  steps,
  activeStep,
  orientation = 'horizontal'
}) => {
  const progressLabel = `Stap ${activeStep + 1} van ${steps.length}: ${steps[activeStep].label}`;
  const { t } = useTranslation();

  return (
    <WizardProgress aria-label={t('wizard.progress')}>
      <SrOnly>{progressLabel}</SrOnly>
      <Stepper
        activeStep={activeStep}
        orientation={orientation}
        alternativeLabel={orientation === 'horizontal'}
      >
        {steps.map((step, index) => {
          const stepStatus =
            index < activeStep
              ? t('wizard.completed')
              : index === activeStep
                ? t('wizard.current')
                : t('wizard.todo');

          return (
            <Step key={step.label} aria-current={index === activeStep ? 'step' : undefined}>
              <StepLabel
                StepIconComponent={AccessibleStepIcon}
                optional={
                  step.description ? <span aria-hidden="true">{step.label}</span> : undefined
                }
              >
                {/* Status alleen voor schermlezers */}
                <SrOnly>{`${step.label} - ${stepStatus}`}</SrOnly>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {/* Live region for announcing step changes */}
      <SrOnly aria-live="polite" aria-atomic="true">
        {progressLabel}
      </SrOnly>
    </WizardProgress>
  );
};
