import PulsatingDots from '@/components/PulsatingDots';
import { Card, CardHeader, FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fYear } from '../../../../../utils/formatTime';
import EngineHoursTable from '../../../info/engine/EngineHoursTable';
import AnnualReportOverview from '../../../info/ship/AnnualReportOverview';

RequestDocuments.propTypes = {
  request: PropTypes.object,
  loading: PropTypes.bool
};

export default function RequestDocuments({ request, loading }) {
  const { t } = useTranslation();
  const { ship } = request;
  const d = new Date();
  const year = d.getFullYear() - 1;
  const [selectedYear, setSelectedYear] = useState(year);
  useEffect(() => {
    if (request) {
      setSelectedYear(fYear(request.createdOn) - 1);
    }
  }, [request, setSelectedYear]);
  if (!request || !ship || loading) {
    return (
      <Card>
        <CardHeader title={t('request.documents')} />
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '100px' }}
        >
          <Grid item xs={3}>
            <PulsatingDots size={15} />
          </Grid>
        </Grid>
      </Card>
    );
  }

  const startYear = 2017;
  const endYear = 2040;

  const yearMenuItems = Array.from({ length: endYear - startYear + 1 }, (_, i) => {
    const year = startYear + i;
    return (
      <MenuItem key={year} value={year}>
        {year}
      </MenuItem>
    );
  });

  return (
    <Grid key="adminRequestDocuments" container spacing={3}>
      <Grid item xs={12}>
        <Grid>
          <FormControl fullWidth>
            <InputLabel>{selectedYear}</InputLabel>
            <Select
              label={selectedYear}
              value={selectedYear || ''}
              onChange={(v) => setSelectedYear(v.target.value)}
              variant="standard"
            >
              {yearMenuItems}
            </Select>
          </FormControl>
        </Grid>
        <Stack spacing={3}>
          <EngineHoursTable ship={ship} year={selectedYear} />
          <AnnualReportOverview ship={ship} year={selectedYear} />
        </Stack>
      </Grid>
    </Grid>
  );
}
