import Loader from '@/components/Loader';
import type { WizardStepComponentProps } from '@/components/wizard/wizard';
import useStore, { type StoreState } from '@/store/use-store';
import type { Engine } from '@/types/engine';
import type { Ship } from '@/types/ship';
import type { ViewMode } from '@/types/view-mode.ts';
import { Box, Button, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import type React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EngineForm from '../../ship/engine/EngineForm';
import EngineTable from '../../ship/engine/EngineTable';

export const EngineInfoStep: React.FC<Partial<WizardStepComponentProps>> = ({ setCanProceed }) => {
  const { t } = useTranslation();
  const [viewMode, setViewMode] = useState<ViewMode>('overview');
  const [engine, setEngine] = useState<Engine | null>(null);

  const { wizardShip, updateWizardShipEngines } = useStore((state) => state);

  const { engines, getEngines, loadingEngines } = useStore((state: StoreState) => state);
  const { getEngine, loadingEngine } = useStore((state) => state);

  useEffect(() => {
    updateWizardShipEngines(engines);
  }, [engines, updateWizardShipEngines]);

  useEffect(() => {
    let isMounted = true;

    const fetchEngines = async () => {
      try {
        if (!wizardShip?.id) return;

        const fetchedEngines = await getEngines(wizardShip.id);
        if (!isMounted) return;

        if (fetchedEngines.length === 0) {
          setViewMode('create');
        }
      } catch (error) {
        if (!isMounted) return;
        console.error('Error fetching engines:', error);
      }
    };
    fetchEngines();

    // Cleanup function
    return () => {
      isMounted = false;
    };
  }, [wizardShip?.id, getEngines]);

  // Update canProceed based on engines
  useEffect(() => {
    if (setCanProceed) {
      const shouldProceed = engines.length > 0 && viewMode === 'overview';
      setCanProceed(shouldProceed);
    }
  }, [engines.length, viewMode, setCanProceed]);

  const handleNewClick = () => {
    setViewMode('create');
  };

  const handleEngineCreated = (newEngine: Engine) => {
    setEngine(newEngine);
    setViewMode('overview');
  };

  const handleCancel = () => {
    setViewMode('overview');
  };

  const handleEngineEdited = (updatedEngine: Engine) => {
    setViewMode('overview');
  };

  const handleEditEngine = async (id: string) => {
    const fetchedEngine = await getEngine(id);
    setEngine(fetchedEngine);
    setViewMode('edit');
  };

  if (loadingEngine || loadingEngines) {
    return (
      <Loader text={loadingEngines ? t('engine.loading_engines') : t('engine.loading_details')} />
    );
  }

  if (viewMode === 'create') {
    return (
      <Grid container spacing={3} padding={3}>
        <Grid size={12}>
          <Typography variant="h4">{t('new_engine')}</Typography>
          <Typography variant="body2">{t('engine.list_description')}</Typography>
          <EngineForm
            ship={wizardShip as Ship}
            onCancel={handleCancel}
            submitCallback={handleEngineCreated}
          />
        </Grid>
      </Grid>
    );
  }

  if (viewMode === 'edit' && engine) {
    return (
      <Grid container spacing={3} padding={3}>
        <Grid size={12}>
          <Typography variant="h4">{t('edit_engine')}</Typography>
          <EngineForm
            engine={engine}
            ship={wizardShip}
            onCancel={handleCancel}
            submitCallback={handleEngineEdited}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={3} padding={3}>
      <Grid size={12}>
        <EngineTable engines={engines} editEngine={handleEditEngine} />
      </Grid>
      <Grid size={12}>
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-start' }}>
          <Button variant="contained" onClick={handleNewClick} disabled={!wizardShip}>
            {t('create_engine')}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default EngineInfoStep;
