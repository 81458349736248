import PulsatingDots from '@/components/PulsatingDots';
import useStore from '@/store/use-store';
import editOutline from '@iconify/icons-eva/edit-outline';
import plusFill from '@iconify/icons-eva/plus-fill';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import { Icon } from '@iconify/react';
import { Button, TableCell, TableRow, Typography } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useDownloadList from '../../../../hooks/useDownloadList';
import { CardHeader, Description, DetailCard, LabelStyle } from '../../../../layouts/styles';
import { fDate } from '../../../../utils/formatTime';
import DetailCardView from '../../../@material-extend/DetailCardView';
import PaginatedTable from '../../../@material-extend/PaginatedTable';
import MoreMenu from '../../../more-menu/MoreMenu';
import MoreMenuItem from '../../../more-menu/MoreMenuItem';
import FuelConsumptionForm from '../../fuelConsumption/FuelConsumptionForm';

FuelUsageList.propTypes = {
  ship: PropTypes.object.isRequired
};

export default function FuelUsageList({ ship }) {
  const { t } = useTranslation();
  const { fuelConsumptions, getFuelConsumption, loadingFuelConsumption, removeFuelConsumption } =
    useStore((state) => state);
  const [currentConsumption, setCurrentConsumption] = useState(null);
  const [viewMode, setViewMode] = useState('overview');
  const [consumptionDate, setConsumptionDate] = useState(new Date());
  const { downloadButton } = useDownloadList();

  useEffect(() => {
    getFuelConsumption(ship.id);
  }, [ship, getFuelConsumption]);

  if (viewMode === 'create' || viewMode === 'edit') {
    return (
      <DetailCardView title={t('fuel_consumption.add')} onClose={() => setViewMode('overview')}>
        <Typography align="left" variant="body2">
          {t('date')}
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            sx={{ pb: 2 }}
            value={consumptionDate}
            onChange={(newDate) => setConsumptionDate(newDate)}
          />
        </LocalizationProvider>
        <FuelConsumptionForm
          submitCallback={() => setViewMode('overview')}
          onCancel={() => setViewMode('overview')}
          date={consumptionDate.toISOString()}
          ship={ship}
          consumption={currentConsumption ? [currentConsumption] : []}
          submitButtonLabel={viewMode === 'edit' ? t(viewMode) : t('add')}
          singleEdit={!!currentConsumption}
        />
      </DetailCardView>
    );
  }

  const editFuelUsage = (id) => {
    const consumption = fuelConsumptions.find((fc) => fc.id === id);
    setCurrentConsumption(consumption);
    setViewMode('edit');
  };

  const rowRenderer = (row) => {
    const menuItems = [
      <MoreMenuItem
        key={`menuItemEdit${row.id}`}
        onClick={() => editFuelUsage(row.id)}
        icon={editOutline}
        label={t('edit')}
      />,
      <MoreMenuItem
        key={`menuItemRemove${row.id}`}
        onClick={() => removeFuelConsumption(row.id)}
        icon={trash2Outline}
        label={t('remove')}
      />
    ];

    return (
      <TableRow hover key={row.id} tabIndex={-1}>
        <TableCell>{fDate(row.date)}</TableCell>
        <TableCell>
          {row.quantity}
          {row.fuel.uom} {row.fuel.fuel}
        </TableCell>
        <TableCell sx={{ fontSize: '1.4rem' }}>
          {row.files.map((file) => downloadButton(file.fileName))}
        </TableCell>
        <TableCell>
          <MoreMenu moreMenuItems={menuItems} />
        </TableCell>
      </TableRow>
    );
  };

  const TABLE_HEAD = [
    { id: 'date', label: t('date'), alignRight: false },
    {
      id: 'reported_consumption',
      label: t('fuel_consumption.reported_consumption'),
      alignRight: false
    },
    {
      id: 'files',
      label: t('report.fuel_consumption_proof'),
      alignRight: false
    },
    {
      id: 'actions'
    }
  ];

  return (
    <DetailCard sx={{ p: 3 }}>
      <CardHeader>
        <LabelStyle>{t('fuel')}</LabelStyle>
        <Button
          variant="contained"
          startIcon={<Icon icon={plusFill} />}
          onClick={() => setViewMode('create')}
        >
          {t('fuel_consumption.add')}
        </Button>
      </CardHeader>
      <Description>{t('fuel_consumption.overview')}</Description>
      {loadingFuelConsumption && <PulsatingDots size={15} />}
      {!loadingFuelConsumption && (
        <PaginatedTable rows={fuelConsumptions} rowRenderer={rowRenderer} header={TABLE_HEAD} />
      )}
    </DetailCard>
  );
}
