import PulsatingDots from '@/components/PulsatingDots';
import useStore from '@/store/use-store';
import { LoadingButton } from '@mui/lab';
import { Box, Container, TextField } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Form, FormikProvider, useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import useLocales from '../hooks/useLocales';
import LanguageBar from './LanguageBar';

TranslationForm.propTypes = {
  parentEntity: PropTypes.object.isRequired,
  parentEntityType: PropTypes.string.isRequired,
  translationGroup: PropTypes.string
};

export default function TranslationForm({ parentEntity, parentEntityType, translationGroup }) {
  const { t } = useTranslation();
  const { createTranslation, updateTranslation } = useStore((state) => state);
  const { getTranslations, getTranslationFields, loadingTranslations, loadingTranslationFields } =
    useStore((state) => state);
  const { currentLang } = useLocales();
  const [translations, setTranslations] = useState([]);
  const [fields, setFields] = useState([]);
  const [translation, setTranslation] = useState(null);
  const [language, setLanguage] = useState(currentLang);

  useEffect(() => {
    if (parentEntity && parentEntityType) {
      getTranslations(parentEntityType, parentEntity.id).then(setTranslations);
      getTranslationFields(parentEntityType).then(setFields);
    }
  }, [getTranslationFields, getTranslations, parentEntity, parentEntityType]);

  useEffect(() => {
    if (language && translations.length > 0) {
      setTranslation(translations.find((t) => t.language === language.value));
    } else if (language) {
      setTranslation(null);
    }
  }, [language, translations]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: fields.reduce((a, v) => ({ ...a, [v]: translation?.[v] || '' }), {}),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      values.language = language.value;
      if (translation) {
        values.id = translation.id;
        updateTranslation(parentEntityType, translation.id, values).then((res) => {
          setTranslation(res);
          toast.success(t('translation_updated'));
        });
      } else {
        createTranslation(parentEntityType, parentEntity.id, values).then((res) => {
          setTranslation(res);
          toast.success(t('translation_added'));
        });
      }
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  if (loadingTranslations || loadingTranslationFields) {
    return <PulsatingDots size={15} />;
  }

  return (
    <Container sx={{ pt: 2, pb: 2, border: 1, borderRadius: 2, borderColor: 'primary.secondary' }}>
      <LanguageBar title={t('translations')} onChangeLanguage={setLanguage} language={language} />
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            {fields?.map((tfield, index) => {
              const translationTitleField = translationGroup
                ? `${translationGroup}.${tfield}`
                : tfield;
              return (
                <Grid key={index} item xs={12} md={12}>
                  <TextField
                    fullWidth
                    label={t(translationTitleField)}
                    {...getFieldProps(tfield)}
                    error={Boolean(touched?.[tfield] && errors?.[tfield])}
                    helperText={touched?.[tfield] && errors?.[tfield]}
                  />
                </Grid>
              );
            })}
            <Grid item xs={12}>
              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                  {t('save')}
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </Container>
  );
}
