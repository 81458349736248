import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  IconButton,
  Tooltip,
  TableHead,
  Toolbar,
  CardHeader,
  Box,
  Grid
} from '@mui/material';
import { styled } from '@mui/material/styles';
import plusSquareOutline from '@iconify/icons-eva/plus-square-outline';
import Scrollbar from '../../../Scrollbar';
import { DetailCard } from '../../../../layouts/styles';
import FuelForm from './FuelForm';
import FuelTypeSelect from './FuelTypeSelect';

const RootStyle = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'space-between'
});

const IconBox = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-start',
  p: 1,
  borderRadius: 1
});

const RightBox = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center'
});

export default function FuelList() {
  const { t } = useTranslation();

  const [editRow, setEditRow] = useState(null);
  const [selectedFuelType, setSelectedFuelType] = useState(null);

  const stopEditing = (res) => {
    if (res) setSelectedFuelType(res);
    setEditRow(null);
  };

  const createFuelType = () => {
    setEditRow({});
  };

  const onRowClick = (row) => {
    setEditRow(row);
  };

  if (editRow) {
    const title = editRow.id ? t('engine_subtype.edit') : t('engine_subtype.create');
    return (
      <DetailCard sx={{ p: 3 }}>
        <CardHeader style={{ paddingBottom: '1rem' }} title={title} />
        <FuelForm
          fuel={editRow}
          fuelType={selectedFuelType}
          submitCallback={(res) => stopEditing(res)}
          onCancel={() => stopEditing()}
        />
      </DetailCard>
    );
  }

  return (
    <DetailCard sx={{ p: 3 }}>
      <RootStyle>
        <Typography variant="h6">{t('engine.subtypes')}</Typography>
        <IconBox>
          <Tooltip title={t('add')}>
            <RightBox>
              <IconButton disabled={selectedFuelType === null} onClick={() => createFuelType()}>
                <Icon icon={plusSquareOutline} />
              </IconButton>
            </RightBox>
          </Tooltip>
        </IconBox>
      </RootStyle>
      <Scrollbar>
        <Grid container direction="row" padding="1em">
          <Grid item xs={12} md={6}>
            <FuelTypeSelect
              selected={selectedFuelType}
              onSelect={(fuelType) => setSelectedFuelType(fuelType)}
            />
          </Grid>
        </Grid>
        {selectedFuelType ? (
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('fuels.fuel')}</TableCell>
                  <TableCell>{t('fuels.ttw')}</TableCell>
                  <TableCell>{t('fuels.uom')}</TableCell>
                  <TableCell>{t('fuels.specific_weight')}</TableCell>
                  <TableCell>{t('number_of_translations')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedFuelType.fuels.length === 0 && (
                  <TableRow>
                    <TableCell colSpan="9">{t('fuels.none')}</TableCell>
                  </TableRow>
                )}
                {selectedFuelType.fuels.map((row) => (
                  <TableRow hover key={row.id} tabIndex={-1}>
                    <TableCell onClick={() => onRowClick(row)}>{row.title}</TableCell>
                    <TableCell>{row.TTW}</TableCell>
                    <TableCell>{row.unitOfMeasurement}</TableCell>
                    <TableCell>{row.specificWeight}</TableCell>
                    <TableCell>{row.numberOfTranslations}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="body2">{t('fuel_type.select')}</Typography>
        )}
      </Scrollbar>
    </DetailCard>
  );
}
