import PulsatingDots from '@/components/PulsatingDots';
import useStore from '@/store/use-store';
import { LoadingButton } from '@mui/lab';
import { Container, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
import Page from '../components/Page';
import { PATH_CLIENT } from '../routes/paths';

export default function AcceptOrganizationInvitation() {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const [invite, setInvite] = useState(null);
  const uuid = searchParams.get('uuid');
  const { acceptingInvitation, acceptInvitation, loadingInvitation, getInvitation } = useStore(
    (state) => state
  );

  useEffect(() => {
    getInvitation(uuid).then((res) => setInvite(res));
  }, [getInvitation, uuid]);

  return (
    <Page title={t('organization.invite')}>
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading={t('organization.invite')}
          links={[
            { name: 'Dashboard', href: PATH_CLIENT.root },
            { name: t('organization.invite') }
          ]}
        />
        {loadingInvitation || !invite ? (
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <PulsatingDots size={15} />
          </Grid>
        ) : (
          <Grid container>
            <Grid item mb={3} xs={12}>
              {t('invitation.explanation')}
            </Grid>
            <Grid item xs={6}>
              {t('organization.title')}
            </Grid>
            <Grid item xs={6}>
              {invite.organization.title}
            </Grid>
            <Grid item xs={6}>
              {t('invitation.inviter')}
            </Grid>
            <Grid item xs={6}>
              {invite.inviter.firstName} {invite.inviter.lastName} ({invite.inviter.email})
            </Grid>
            <Grid item mt={6} xs={12}>
              <LoadingButton
                fullWidth
                size="large"
                aria-label="accept_invitation"
                type="submit"
                variant="contained"
                onClick={() => acceptInvitation(uuid)}
                loading={acceptingInvitation}
              >
                {t('invitation.accept')}
              </LoadingButton>
            </Grid>
          </Grid>
        )}
      </Container>
    </Page>
  );
}
