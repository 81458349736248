import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Scrollbar from '../../../Scrollbar';
import EngineListHead from './EngineListHead';
import EngineMoreMenu from './EngineMoreMenu';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator) {
  if (array.size === 0) return array;
  array.sort((a, b) => {
    const order = comparator(a, b);
    if (order !== 0) return order;
    return a - b;
  });

  return array;
}

EngineTable.propTypes = {
  engines: PropTypes.array,
  editEngine: PropTypes.func,
  showEngineHours: PropTypes.func
};

const LinkCell = styled(TableCell)({
  fontSize: '1.4rem',
  '&:hover': {
    color: 'green',
    cursor: 'pointer'
  }
});

export default function EngineTable({ engines, editEngine, showEngineHours }) {
  const { t } = useTranslation();

  const [orderBy, setOrderBy] = useState('title');
  const [order, setOrder] = useState('asc');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const TABLE_HEAD = [
    { id: 'title', label: t('name'), alignRight: false },
    { id: 'type', label: t('engine.type'), alignRight: false },
    { id: 'fuelType', label: t('engine.fuel_type'), alignRight: false },
    { id: 'power', label: t('engine_power'), alignRight: false },
    { id: 'hours', label: t('engine.last_hours'), alignRight: false },
    {}
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - engines.length) : 0;
  const isEnginesEmpty = engines.length === 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(Number.parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredEngines = applySortFilter(engines, getComparator(order, orderBy));

  return (
    <>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <EngineListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {filteredEngines
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  const { id, type, fuelType, title, power, hourReadings } = row;
                  return (
                    <TableRow hover key={id} tabIndex={-1}>
                      <LinkCell component="th" scope="row" onClick={() => editEngine(id)}>
                        <Typography variant="subtitle2" noWrap>
                          {title}
                        </Typography>
                      </LinkCell>
                      <TableCell align="left">{type.description}</TableCell>
                      <TableCell align="left">{fuelType.description}</TableCell>
                      <TableCell align="left">{power}</TableCell>
                      <TableCell align="left">
                        {hourReadings ? hourReadings[0]?.reading : '-'}
                      </TableCell>
                      <TableCell>
                        <EngineMoreMenu
                          showEngineHours={showEngineHours}
                          editEngine={editEngine}
                          id={id}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {isEnginesEmpty && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <Paper>
                      <Typography gutterBottom align="center" variant="subtitle1">
                        {t('no_engines')}
                      </Typography>
                    </Paper>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={engines.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
