import ForgotPasswordForm from '@/components/authentication/forgot/forgot-password-form';
import useStore, { type StoreState } from '@/store/use-store';
import { Card, Container, Link, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import backgroundImage from '../_assets/login_image.png';
import { MHidden } from '../components/@material-extend';
import Page from '../components/Page';
import AuthLayout from '../layouts/AuthLayout';
import LanguagePopover from '../layouts/dashboard/LanguagePopover';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(() => ({
  width: '100%',
  maxWidth: 520,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'cover',
  borderRadius: '0px',
  backgroundColor: '#ffffff'
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 520,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

export default function ForgotPassword() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { authenticated, reset, refresh } = useStore((state: StoreState) => state);

  useEffect(() => {
    if (authenticated) navigate('/dashboard', { replace: true });
  }, [authenticated, navigate]);

  useEffect(() => {
    if (reset) {
      refresh();
      navigate('/login', { replace: true });
    }
  }, [reset, navigate, refresh]);

  return (
    <RootStyle title={t('forgot_password')}>
      <AuthLayout>
        <LanguagePopover />
        <Typography
          variant="body2"
          sx={{
            mt: { md: -2 }
          }}
        >
          <Link
            underline="none"
            variant="subtitle2"
            component={RouterLink}
            to="/login"
            aria-label={t('navigate_to_login')}
          >
            {t('login')}
          </Link>
        </Typography>
      </AuthLayout>

      <MHidden width="mdDown">
        <SectionStyle role="img" aria-label={t('decorative_image_ships')} />
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom tabIndex={0}>
              {t('forgot_password')}
            </Typography>
          </Stack>

          <ForgotPasswordForm />

          <MHidden width="smUp">
            <Typography variant="body2" align="center" sx={{ mt: 3 }} tabIndex={0}>
              {t('no_account')}?&nbsp;
              <Link
                variant="subtitle2"
                component={RouterLink}
                to="/register"
                aria-label={t('navigate_to_register')}
              >
                {t('register')}
              </Link>
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
