import useStore from '@/store/use-store';
import plusSquareOutline from '@iconify/icons-eva/plus-square-outline';
import refreshOutline from '@iconify/icons-eva/refresh-outline';
import { Icon } from '@iconify/react';
import {
  Box,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DetailCard } from '../../../../layouts/styles';
import { fStringCapitalizeFirstLetter } from '../../../../utils/formatString';
import Scrollbar from '../../../Scrollbar';
import EngineTypeForm from './EngineTypeForm';

const RootStyle = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'space-between'
});

const IconBox = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-start',
  p: 1,
  borderRadius: 1
});

const RightBox = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center'
});

export default function EngineTypeList() {
  const { t } = useTranslation();
  const { getEngineTypes, loadingEngineTypes: loading, engineTypes } = useStore((state) => state);

  useEffect(() => {
    getEngineTypes();
  }, [getEngineTypes]);

  const [editRow, setEditRow] = useState(null);

  const stopEditing = () => {
    setEditRow(null);
  };

  const createEngineType = () => {
    setEditRow({});
  };

  const onRowClick = (row) => {
    setEditRow(row);
  };

  if (editRow) {
    const title = editRow.id ? t('engine_type.edit') : t('engine_type.create');
    return (
      <DetailCard sx={{ p: 3 }}>
        <CardHeader style={{ paddingBottom: '1rem' }} title={title} />
        <EngineTypeForm
          engineType={editRow}
          submitCallback={() => stopEditing()}
          onCancel={() => stopEditing()}
        />
      </DetailCard>
    );
  }

  return (
    <DetailCard sx={{ p: 3 }}>
      <RootStyle>
        <Typography variant="h6">{t('engine_type.types')}</Typography>
        <IconBox>
          <Tooltip title={t('refresh')}>
            <RightBox>
              {loading && (
                <Typography
                  align="right"
                  variant="body2"
                  sx={{ fontStyle: 'italic', color: 'text.secondary' }}
                >
                  {t('refreshing')}...
                </Typography>
              )}
              <IconButton onClick={() => getEngineTypes()}>
                <Icon icon={refreshOutline} />
              </IconButton>
            </RightBox>
          </Tooltip>

          <Tooltip title={t('add')}>
            <RightBox>
              <IconButton onClick={() => createEngineType()}>
                <Icon icon={plusSquareOutline} />
              </IconButton>
            </RightBox>
          </Tooltip>
        </IconBox>
      </RootStyle>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('engine_type.code')}</TableCell>
                <TableCell>{t('engine_type.description')}</TableCell>
                <TableCell>{t('engine_type.post_treatment_optional')}</TableCell>
                <TableCell>{t('engine_type.ureum_optional')}</TableCell>
                <TableCell>{t('engine_type.fuels')}</TableCell>
                <TableCell>{t('number_of_translations')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {engineTypes.map((row) => (
                <TableRow hover key={row.id} tabIndex={-1}>
                  <TableCell onClick={() => onRowClick(row)}>{row.code}</TableCell>
                  <TableCell>{row.description}</TableCell>
                  <TableCell>
                    {row.postTreatmentOptional
                      ? fStringCapitalizeFirstLetter(t('yes'))
                      : fStringCapitalizeFirstLetter(t('no'))}
                  </TableCell>
                  <TableCell>
                    {row.ureumOptional
                      ? fStringCapitalizeFirstLetter(t('yes'))
                      : fStringCapitalizeFirstLetter(t('no'))}
                  </TableCell>
                  <TableCell>{row.fuelTypes.length}</TableCell>
                  <TableCell>{row.numberOfTranslations}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
    </DetailCard>
  );
}
