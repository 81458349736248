import PulsatingDots from '@/components/PulsatingDots';
import { DetailCard } from '@/layouts/styles.js';
import useStore from '@/store/use-store';
import { fStringCapitalizeFirstLetter } from '@/utils/formatString.js';
import { fDate } from '@/utils/formatTime.js';
import arrowheadDownOutline from '@iconify/icons-eva/arrowhead-down-outline';
import { Icon } from '@iconify/react';
import { Box, Card, CardHeader, Grid, IconButton, Stack, Typography } from '@mui/material';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import useDownloadList from '../../../../../hooks/useDownloadList';
import EngineSubTypeSelect from '../../../ship/engine/EngineSubTypeSelect';
import EngineTypeSelect from '../../../ship/engine/EngineTypeSelect';
import EmissionLimitsForm from '../../../ship/engine/emission-limits/EmissionLimitsForm';

RequestEngines.propTypes = {
  request: PropTypes.object,
  loading: PropTypes.bool
};

export default function RequestEngines({ request, loading }) {
  const { t } = useTranslation();

  const { assignSubType, fillEmissionFromSubtype, getEngines, loadingEngines, updateEngine } =
    useStore((state) => state);
  const [engines, setEngines] = useState([]);
  const { downloadLink } = useDownloadList();

  useEffect(() => {
    if (request.ship) {
      getEngines(request.ship.id).then((res) => setEngines(res));
    }
  }, [request.ship, getEngines]);
  if (!request || loading || loadingEngines) {
    return (
      <Card>
        <CardHeader title={t('request.engine_info')} />
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '100px' }}
        >
          <Grid item xs={3}>
            <PulsatingDots size={15} />
          </Grid>
        </Grid>
      </Card>
    );
  }

  function onSubTypeSelect(engine, e) {
    assignSubType(engine, e).then((res) => {
      let newEngines = [];
      newEngines = engines.map((engine) => (engine.id === res.id ? res : engine));
      setEngines(newEngines);
    });
  }

  function onTypeSelect(engine, e) {
    engine.type = e;
    if (engine.name !== '_alt') delete engine.title;
    if (engine.name === '_alt') delete engine.name;
    engine.emissionLimits = null;
    delete engine.subType;
    const updatedEngine = { ...engine };
    delete updatedEngine.latestReading;
    delete updatedEngine.hourReadings;

    updateEngine(updatedEngine).then(() => {
      toast.success(i18n.t('saved'));
    });
  }

  function updateEmissionLimitsFromSubtype(engine) {
    fillEmissionFromSubtype(engine).then((res) => {
      let newEngines = [];
      newEngines = engines.map((engine) => (engine.id === res.id ? res : engine));
      setEngines(newEngines);
    });
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {engines.map((engine) => (
          <DetailCard key={engine.id}>
            <CardHeader title={engine.title} />
            <Stack spacing={2} sx={{ p: 3 }}>
              <Grid container>
                <Grid item xs={3}>
                  <Typography variant="body2">{t('engine.fuel_type')}:</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body2">{engine.fuelType?.description}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2">{t('engine.post_treatment')}:</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body2">
                    {engine.postTreatment
                      ? fStringCapitalizeFirstLetter(t('yes'))
                      : fStringCapitalizeFirstLetter(t('no'))}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2">{t('engine.power')}:</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body2">{engine.power}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2">{t('engine.hours')}:</Typography>
                </Grid>
                <Grid item xs={9}>
                  {engine.hourReadings.map((reading, index) => (
                    <Typography key={index} variant="body2">
                      {downloadLink(
                        reading.proof.fileName,
                        `[${fDate(reading.added)}] - ${reading.proof.originalName}`
                      )}
                    </Typography>
                  ))}
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2">{t('engine.statements')}:</Typography>
                </Grid>
                <Grid item xs={9}>
                  {engine.installationStatements.map((statement, index) => (
                    <Typography key={`statement${index}`} variant="body2">
                      {downloadLink(
                        statement.fileName,
                        `[${fDate(statement.added)}] - ${statement.originalName}`
                      )}
                    </Typography>
                  ))}
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2">{t('engine.measurement_reports')}:</Typography>
                </Grid>
                <Grid item xs={9}>
                  {engine.measurementReports.map((report, index) => (
                    <Typography key={`mr${index}`} variant="body2">
                      {downloadLink(
                        report.fileName,
                        `[${fDate(report.added)}] - ${report.originalName}`
                      )}
                    </Typography>
                  ))}
                </Grid>
                <Grid item xs={3} sx={{ mt: 2 }}>
                  <Grid item sx={{ pt: 2, pb: 2 }} xs={8} />
                  <Typography variant="body2">{t('engine.type')}:</Typography>
                </Grid>
                <Grid item xs={8} sx={{ mt: 2 }}>
                  <EngineTypeSelect
                    sx={{ pt: 4, pb: 4 }}
                    onSelect={(e) => onTypeSelect(engine, e)}
                    engine={engine}
                    selected={engine.type}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={{ pt: 4, pb: 4 }} variant="body2">
                    {t('engine_subtype.title')}:
                  </Typography>
                </Grid>
                <Grid item sx={{ pt: 2, pb: 2 }} xs={8}>
                  <EngineSubTypeSelect
                    onSelect={(e) => onSubTypeSelect(engine, e)}
                    engine={engine}
                    selected={engine.subType}
                  />
                </Grid>
                <Grid item sx={{ pt: 2, pb: 2 }} xs={1}>
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <IconButton
                      disabled={engine.subType === null}
                      onClick={() => updateEmissionLimitsFromSubtype(engine)}
                    >
                      <Icon icon={arrowheadDownOutline} width={20} height={20} />
                    </IconButton>
                  </Box>
                </Grid>
                {engine.fuelType.showEmissions && (
                  <>
                    <Grid item xs={3}>
                      <Typography sx={{ pt: 4, pb: 4 }} variant="body2">
                        {t('engine.emission_limits')}:
                      </Typography>
                    </Grid>
                    <Grid item sx={{ pt: 2, pb: 2 }} xs={9}>
                      <EmissionLimitsForm engine={engine} />
                    </Grid>
                  </>
                )}
              </Grid>
            </Stack>
          </DetailCard>
        ))}
      </Grid>
    </Grid>
  );
}
