import PulsatingDots from '@/components/PulsatingDots';
import useStore from '@/store/use-store';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Icon } from '@iconify/react';
import {
  Button,
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { fDate } from '../../../utils/formatTime';
import Scrollbar from '../../Scrollbar';
import HoursListHead from './HoursListHead';
import HoursModal from './HoursModal';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator) {
  if (array.size === 0) return array;
  array.sort((a, b) => {
    const order = comparator(a, b);
    if (order !== 0) return order;
    return a - b;
  });

  return array;
}

const CardHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(4)
}));

const DetailCard = styled(Card)(({ theme }) => ({
  ...theme.typography.h4,
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(1),
  textAlign: 'center'
}));

export default function HoursList() {
  const { t } = useTranslation();
  const loading = useStore((state) => state.loadingEngineOperatingHours);
  const readings = useStore((state) => state.expiredHourReadings);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentEngine, setCurrentEngine] = useState(null);

  const [orderBy, setOrderBy] = useState('title');
  const [order, setOrder] = useState('asc');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const TABLE_HEAD = [
    { id: 'engine.ship.title', label: t('ship.title'), alignRight: false },
    { id: 'engine.title', label: t('engine.title'), alignRight: false },
    { id: 'reading', label: t('engine.last_hours'), alignRight: false },
    { id: 'added', label: t('date_added'), alignRight: false },
    { id: '' }
  ];

  const onAddHoursClick = (engine) => {
    setCurrentEngine(engine);
    setModalIsOpen(true);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - readings.length) : 0;
  const isMeasurementsEmpty = readings.length === 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (modalIsOpen) {
      document.body.querySelector('#modal-heading')?.focus();
    }
  }, [modalIsOpen]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(Number.parseInt(event.target.value, 10));
    setPage(0);
  };
  const filteredMeasurements = applySortFilter(readings, getComparator(order, orderBy));

  const reading = readings.find((r) => r.engine.id === currentEngine?.id);

  return (
    <DetailCard sx={{ p: 3 }}>
      <CardHeader>
        <Typography
          id="required-hours-heading"
          tabIndex="0"
          component="h2"
          aria-level="1"
          className="sr-only"
        >
          {t('engine.required_hours')}
        </Typography>
      </CardHeader>
      <CardContent sx={{ p: 0 }}>
        <Typography id="required-hours-description" sx={{ pb: 2 }} variant="body2" align="left">
          {t('engine.required_hours_explanation')}
        </Typography>
        <HoursModal
          close={() => setModalIsOpen(false)}
          isOpen={modalIsOpen}
          engine={currentEngine}
          reading={reading}
          aria-labelledby="modal-heading"
        />
        <Scrollbar>
          <TableContainer
            aria-labelledby="required-hours-heading"
            aria-describedby="required-hours-description"
            sx={{ minWidth: 800 }}
          >
            <Table>
              <HoursListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                onRequestSort={handleRequestSort}
              />
              {!loading && (
                <TableBody>
                  {filteredMeasurements
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { id, engine, reading, added } = row;
                      return (
                        <TableRow hover key={id} tabIndex={-1}>
                          <TableCell component="th" scope="row">
                            <Typography
                              to={`/client/ship/${engine.ship.id}`}
                              component={RouterLink}
                              variant="subtitle2"
                              noWrap
                              aria-label={`Link to ${engine.ship.title}`}
                            >
                              {engine.ship.title}
                            </Typography>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {engine.title}
                          </TableCell>
                          <TableCell>
                            <Typography>{reading}</Typography>
                          </TableCell>
                          <TableCell align="left">{fDate(added)}</TableCell>
                          <TableCell align="right">
                            <Button
                              variant="contained"
                              onClick={() => handleAddHoursClick(engine)}
                              aria-label={t('label.add_hours')}
                            >
                              <Icon icon={plusFill} />
                              <span className="sr-only">{t('label.add_hours')}</span>
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {!filteredMeasurements.length && (
                    <TableRow style={{ height: 53 }}>
                      <TableCell colSpan={5}>
                        <Typography>{t('label.empty_rows')}</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              )}
              {loading && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={5} sx={{ py: 3 }}>
                      <Paper>
                        <PulsatingDots size={15} aria-label="Loading" />
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
              {isMeasurementsEmpty && !loading && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={5} sx={{ py: 3 }}>
                      <Paper>
                        <Typography gutterBottom align="center" variant="subtitle1">
                          {t('label.no_labels')}
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={readings.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          aria-label="Pagination Controls"
        />
      </CardContent>
    </DetailCard>
  );
}
