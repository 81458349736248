import { DetailCard } from '@/layouts/styles';
import { PATH_CLIENT } from '@/routes/paths';
import useStore from '@/store/use-store';
import { fStringCapitalizeFirstLetter } from '@/utils/formatString';
import { LoadingButton } from '@mui/lab';
import { Container, Grid, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import PulsatingDots from '@/components/PulsatingDots';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
import Page from '../components/Page';
import OrganizationForm from '../components/_dashboard/organization/OrganizationForm';
import OrganizationMembers from '../components/_dashboard/organization/OrganizationMembers';

Organization.propTypes = {
  id: PropTypes.number
};
export default function Organization({ id }) {
  const { user } = useStore((state) => state);

  const {
    organization,
    getOrganization,
    loadingOrganization,
    savingOrganization,
    activateOrganization
  } = useStore((state) => state);

  // If no specific id given, load user organization
  if (id == null) {
    id = user.organization.id;
  }

  useEffect(() => {
    getOrganization(id);
  }, [getOrganization, id]);

  const { t } = useTranslation();

  const title = fStringCapitalizeFirstLetter(t('my_organization'));

  return (
    <Page title={title}>
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading={title}
          links={[{ name: 'Dashboard', href: PATH_CLIENT.root }, { name: title }]}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <DetailCard sx={{ p: 3 }}>
              {loadingOrganization && <PulsatingDots size={15} />}

              {!_.isEmpty(organization) && !organization.isActive && (
                <>
                  <Typography variant="body2">{t('organization.inactive')}</Typography>
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={savingOrganization}
                    sx={{ mt: 5 }}
                    onClick={() => activateOrganization(organization.id)}
                  >
                    {t('organization.create')}
                  </LoadingButton>
                </>
              )}

              {organization?.isActive && <OrganizationForm organization={organization} />}
              {organization?.isActive && <OrganizationMembers organization={organization} />}
            </DetailCard>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
