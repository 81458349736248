import type { FuelType } from '@/types/fuel';
import { validateFileSize } from '@/utils/validateFileArray';
import type { TFunction } from 'i18next';
import * as Yup from 'yup';

const fuelConsumptionValidationSchema = (
  t: TFunction,
  maxUploadSize: number,
  fuelTypes: FuelType[]
) =>
  Yup.object().shape({
    ship: Yup.number().required(t('form_errors.ship_required')),
    year: Yup.number().required(t('form_errors.year_required')),
    consumptions: Yup.array()
      .of(
        Yup.object().shape({
          quantity: Yup.number()
            .required(t('form_errors.quantity_required'))
            .typeError(t('form_errors.must_be_number')),
          fuel: Yup.number()
            .required(t('form_errors.fuel_required'))
            .typeError(t('form_errors.must_be_number')),
          date: Yup.date()
            .required(t('form_errors.date_required'))
            .typeError(t('form_errors.must_be_date'))
            .nullable(),
          files: Yup.array()
            .min(1, t('form_errors.files_required'))
            .test(
              'is-big-file',
              t('form_errors.max_file_size', { size: maxUploadSize }),
              validateFileSize
            )
        })
      )
      .test('all-fuel-types-covered', t('form_errors.missing_fuel_types'), (consumptions) => {
        if (!consumptions) return false;

        const missingTypes = fuelTypes.filter((fuelType) => {
          const fuelIdsForType = fuelType.fuels.map((f) => f.id);
          const hasMatchingFuel = consumptions.some((selected) =>
            fuelIdsForType.includes(selected.fuel)
          );

          return !hasMatchingFuel;
        });

        return missingTypes.length === 0;
      })
  });

export default fuelConsumptionValidationSchema;
