import PulsatingDots from '@/components/PulsatingDots';
import { CardHeader, Description, DetailCard, LabelStyle } from '@/layouts/styles.js';
import useStore from '@/store/use-store';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Icon } from '@iconify/react';
import { Button, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DetailCardView from '../../../@material-extend/DetailCardView';
import EngineForm from './EngineForm';
import EngineHourTable from './EngineHourTable';
import EngineTable from './EngineTable';

EngineList.propTypes = {
  ship: PropTypes.object.isRequired
};

export default function EngineList({ ship }) {
  const { t } = useTranslation();
  const [viewMode, setViewMode] = useState('overview');
  const [engine, setEngine] = useState(null);
  const { getEngine, loadingEngine, removingEngine } = useStore((state) => state);
  const { engines, getEngines, loadingEngines, setEngines } = useStore((state) => state);

  useEffect(() => {
    getEngines(ship.id);
  }, [ship.id, getEngines]);

  const finishedEditing = (engine) => {
    const list = engines.filter((e) => e.id !== engine.id);
    list.push(engine);
    setEngines(list);
    setViewMode('overview');
  };

  const onCancel = () => {
    setViewMode('overview');
  };

  const editEngine = (id) => {
    getEngine(id).then((res) => {
      setEngine(res);
      setViewMode('edit');
    });
  };

  const showEngineHours = (id) => {
    getEngine(id).then((res) => {
      setEngine(res);
      setViewMode('hour_details');
    });
  };

  if (viewMode === 'create') {
    return (
      <DetailCard sx={{ p: 3 }}>
        <CardHeader>
          <LabelStyle>{t('new_engine')}</LabelStyle>
        </CardHeader>
        <EngineForm
          ship={ship}
          onCancel={onCancel}
          submitCallback={(engine) => finishedEditing(engine)}
        />
      </DetailCard>
    );
  }

  if (viewMode === 'edit') {
    return (
      <DetailCardView title={t('edit_engine')}>
        {loadingEngine && (
          <>
            <PulsatingDots size={15} />
            <Typography variant="body2">
              {loadingEngines ? t('engine.loading_engines') : t('engine.loading_details')}
            </Typography>
          </>
        )}
        {!loadingEngine && (
          <EngineForm
            engine={engine}
            ship={ship}
            onCancel={onCancel}
            submitCallback={(engine) => finishedEditing(engine)}
          />
        )}
      </DetailCardView>
    );
  }

  if (viewMode === 'hour_details') {
    return (
      <DetailCardView onClose={onCancel} title={t('hour_overview')}>
        {loadingEngine && (
          <>
            <PulsatingDots size={15} />
            <Typography variant="body2">
              {loadingEngines ? t('engine.loading_engines') : t('engine.loading_details')}
            </Typography>
          </>
        )}
        {!loadingEngine && (
          <EngineHourTable
            engine={engine}
            onCancel={onCancel}
            submitCallback={(engine) => finishedEditing(engine)}
          />
        )}
      </DetailCardView>
    );
  }

  return (
    <DetailCard sx={{ p: 3 }}>
      <CardHeader>
        <LabelStyle>{t('engines')}</LabelStyle>
        <Button
          variant="contained"
          startIcon={<Icon icon={plusFill} />}
          onClick={() => setViewMode('create')}
        >
          {t('new_engine')}
        </Button>
      </CardHeader>
      <Description>{t('engine.list_description')}</Description>
      {(loadingEngines || loadingEngine || removingEngine) && (
        <>
          <PulsatingDots size={15} />
          <Typography variant="body2">
            {loadingEngines && t('engine.loading_engines')}
            {loadingEngine && t('engine.loading_details')}
            {removingEngine && t('engine.removing')}
          </Typography>
        </>
      )}

      {!loadingEngines && !loadingEngine && !removingEngine && (
        <EngineTable
          engines={engines}
          showEngineHours={(id) => showEngineHours(id)}
          editEngine={(id) => editEngine(id)}
        />
      )}
    </DetailCard>
  );
}
