import { Box, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { useRef, useState } from 'react';
import { MIconButton } from '../../components/@material-extend';
import MenuPopover from '../../components/MenuPopover';
import useLocales from '../../hooks/useLocales';

export default function LanguagePopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { allLang, currentLang, onChangeLang } = useLocales();
  document.documentElement.lang = currentLang?.value.substring(0, 2) || 'en';
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeLang = (value) => {
    onChangeLang(value);
    handleClose();
  };

  // Toetsenbord event handler toevoegen
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      handleOpen();
    }
    if (event.key === 'Escape') {
      handleClose();
    }
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        onKeyDown={handleKeyDown}
        tabIndex={0} // Expliciet tabIndex toevoegen
        aria-label={`Verander taal, huidige taal is ${currentLang.label}`}
        aria-expanded={open}
        aria-haspopup="true"
        role="button"
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          cursor: 'pointer', // Zorgt voor visuele indicatie dat het klikbaar is
          // Verhoog contrast voor focus state
          '&:focus-visible': {
            outline: '2px solid currentColor',
            outlineOffset: 2,
            boxShadow: '0 0 0 3px rgba(66, 153, 225, 0.5)' // Extra focus indicator
          },
          // Verhoog contrast voor hover state
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)'
          }
        }}
      >
        <img src={currentLang.icon} alt="" role="presentation" />
      </MIconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ py: 1 }}
        role="menu"
        aria-label="Taalkeuze menu"
      >
        {allLang.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === currentLang.value}
            onClick={() => handleChangeLang(option.value)}
            tabIndex={0} // Zorgt ervoor dat menu-items ook tab-baar zijn
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();
                handleChangeLang(option.value);
              }
            }}
            sx={{
              py: 1,
              px: 2.5,
              '&:focus-visible': {
                outline: '2px solid currentColor',
                outlineOffset: -2,
                backgroundColor: 'rgba(0, 0, 0, 0.08)'
              }
            }}
            role="menuitem"
          >
            <ListItemIcon>
              <Box
                component="img"
                alt=""
                src={option.icon}
                role="presentation"
                sx={{ width: 20, height: 20 }}
              />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
              {option.label}
            </ListItemText>
          </MenuItem>
        ))}
      </MenuPopover>
    </>
  );
}
