import useStore from '@/store/use-store';
import { Card, CardContent, Container, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
import Page from '../components/Page';
import FuelConsumptionList from '../components/_dashboard/fuelConsumption/FuelConsumptionList';
import HoursList from '../components/_dashboard/hours/HoursList';
import { PATH_CLIENT } from '../routes/paths';

export default function QuarterlyReport() {
  const { t } = useTranslation();

  const { getExpiredHourReadings, getQuarterlyFuelConsumptions } = useStore((state) => state);

  const readings = useStore((state) => state.expiredHourReadings);
  const fuelConsumptions = useStore((state) => state.quarterlyFuelConsumptions);

  useEffect(() => {
    getExpiredHourReadings();
    getQuarterlyFuelConsumptions();
  }, [getExpiredHourReadings, getQuarterlyFuelConsumptions]);

  return (
    <Page title={t('engine.hours')}>
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading={t('engine.quarterly_rapport')}
          links={[
            { name: 'Dashboard', href: PATH_CLIENT.root },
            { name: t('engine.quarterly_rapport') }
          ]}
        />
        {readings.length === 0 ? (
          <Card sx={{ p: 2 }}>
            <CardContent
              sx={{
                color: 'grey.800',
                p: { md: 1 },
                pl: { md: 1 }
              }}
            >
              <Typography gutterBottom variant="h4">
                {t('hours.not_required')}
                <Typography variant="body2">{t('hours.every_quarter')}</Typography>
              </Typography>
            </CardContent>
          </Card>
        ) : (
          <HoursList />
        )}
        {fuelConsumptions.length === 0 ? (
          <Card sx={{ p: 2 }}>
            <CardContent
              sx={{
                color: 'grey.800',
                p: { md: 1 },
                pl: { md: 1 }
              }}
            >
              <Typography gutterBottom variant="h4">
                {t('fuel_consumption.not_required')}
                <Typography variant="body2">{t('fuel_consumption.every_quarter')}</Typography>
              </Typography>
            </CardContent>
          </Card>
        ) : (
          <FuelConsumptionList />
        )}
      </Container>
    </Page>
  );
}
