import { PATH_CLIENT } from '@/routes/paths';
import { Card, Container } from '@mui/material';
import { upperFirst } from 'lodash';
import { useTranslation } from 'react-i18next';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
import Page from '../components/Page';
import RequestList from '../components/_dashboard/request/request-list';

interface RequestProps {
  className?: string;
}

export default function Request({ className }: RequestProps) {
  const { t } = useTranslation();
  const pageTitle = t('my_requests');

  return (
    <Page title={pageTitle} className={className}>
      <Container maxWidth="lg" component="main" aria-label={t('my_requests_section')}>
        <HeaderBreadcrumbs
          heading={pageTitle}
          links={[
            {
              name: upperFirst(t('dashboard')),
              href: PATH_CLIENT.root,
              'aria-label': t('navigate_to_dashboard')
            },
            {
              name: pageTitle,
              'aria-current': 'page'
            }
          ]}
        />
        <Card component="section">
          <RequestList />
        </Card>
      </Container>
    </Page>
  );
}
