import { MoreMenuButton } from '@/components/MoreMenuButton';
import { Menu } from '@mui/material';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';

MoreMenu.propTypes = {
  moreMenuItems: PropTypes.array.isRequired
};

export default function MoreMenu({ moreMenuItems }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <MoreMenuButton isOpen={isOpen} setIsOpen={setIsOpen} menuId="more-menu" ref={ref} />

      <Menu
        id="more-menu"
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {moreMenuItems.map((moreMenuItems) => moreMenuItems)}
      </Menu>
    </>
  );
}
