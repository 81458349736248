export function validateFileSize(files) {
  if (files) {
    if (files instanceof Array) {
      for (let i = 0; i < files.length; i += 1) {
        if (!isValid(files[i])) return false
      }
    } else {
      return isValid(files)
    }
  }
  return true
}

function isValid(file) {
  const maxUploadSize = import.meta.env.VITE_APP_MAX_UPLOAD
  return file.size <= maxUploadSize
}
