import type { WizardStepComponentProps } from '@/components/wizard/wizard.tsx';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/material/styles';
import type React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const IntroductionStep: React.FC<Partial<WizardStepComponentProps>> = ({
  setCanProceed,
  setSkipNext
}) => {
  const { t } = useTranslation();

  const List = styled('ul')(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'left',
    marginLeft: '1.5rem'
  }));

  const Li = styled('li')({
    wordWrap: 'break-word'
  });

  useEffect(() => {
    setCanProceed(true);
    setSkipNext(false);
  }, [setCanProceed, setSkipNext]);

  return (
    <Grid container spacing={3} padding={3}>
      <Grid size={12}>
        <Typography variant="h4" component="h2" sx={{ mb: 2 }}>
          {t('introduction.read_carefully')}.
        </Typography>
        <Typography variant="body2" align="left" sx={{ mb: 1.5 }}>
          {t('introduction.steps')}.
        </Typography>
        <List sx={{ mb: 2 }}>
          <Li>{t('introduction.step_1')}</Li>
          <Li>{t('introduction.step_2')}</Li>
          <Li>{t('introduction.required_documentation')}</Li>
          <List>
            <Li>{t('introduction.documentation_1')}</Li>
            <Li>{t('introduction.documentation_2')}</Li>
            <Li>{t('introduction.documentation_3')}</Li>
            <Li>{t('introduction.documentation_4')}</Li>
            <Li>{t('introduction.documentation_5')}</Li>
            <Li>{t('introduction.documentation_6')}</Li>
          </List>
          <Li>{t('introduction.report')}</Li>
          <List>
            <Li>{t('introduction.report_1')}</Li>
            <Li>{t('introduction.report_2')}</Li>
            <Li>{t('introduction.report_3')}</Li>
          </List>
        </List>

        <Typography variant="body2" align="left">
          {t('introduction.extra')}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default IntroductionStep;
