import Loader from '@/components/Loader';
import FuelConsumptionForm from '@/components/_dashboard/fuelConsumption/fuel-consumption-form';
import type { WizardFormMethods, WizardStepComponentProps } from '@/components/wizard/wizard';
import useStore from '@/store/use-store';
import type { FuelConsumption } from '@/types/fuel';
import { fDate } from '@/utils/formatTime';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import type { FormikValues } from 'formik';
import type React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const FuelUsageStep: React.FC<Partial<WizardStepComponentProps>> = ({
  setCanProceed,
  setSkipNext,
  registerBeforeNext
}) => {
  const { t } = useTranslation();
  const { wizardShip } = useStore((state) => state);
  const year = new Date().getFullYear() - 1;
  const [fuelConsumptions, setFuelConsumptions] = useState<FuelConsumption[] | null>(null);
  const { getFuelConsumption, loadingFuelConsumption } = useStore((state) => state);
  const formRef = useRef<WizardFormMethods>(null);

  useEffect(() => {
    getFuelConsumption(wizardShip.id, `${year}-01-01`, `${year}-12-31`).then((res) => {
      if (res) {
        setFuelConsumptions(
          res?.map((consumption) => ({ ...consumption, fuel: consumption.fuel.id }))
        );
      }
    });
  }, [wizardShip, getFuelConsumption, year]);

  useEffect(() => {
    setCanProceed?.(true);
    setSkipNext?.(false);
  }, [setCanProceed, setSkipNext]);

  useEffect(() => {
    if (registerBeforeNext) {
      registerBeforeNext(async () => {
        try {
          if (formRef.current) {
            return await formRef.current.submit();
          }
          return false;
        } catch (error) {
          console.error('Error during form submission:', error);
          return false;
        }
      });
    }
  }, [registerBeforeNext]);

  const handleFormStateChange = useCallback(
    ({
      isValid,
      isDirty,
      values
    }: {
      isValid: boolean;
      isDirty: boolean;
      values: FormikValues;
    }) => {
      const hasConsumptions = values.consumptions.length > 0;
      setCanProceed?.(isValid && (isDirty || hasConsumptions));
    },
    [setCanProceed]
  );

  if (loadingFuelConsumption) {
    return <Loader text={t('fuel_consumption.loading')} />;
  }

  return (
    <Grid container spacing={3} padding={3}>
      <Grid size={12}>
        <Typography variant="h4">
          {t('fuel_consumption.fill_period', {
            from: fDate(new Date(year, 0, 1)),
            to: fDate(new Date(year, 11, 31))
          })}
        </Typography>
      </Grid>
      <Grid size={12}>
        <FuelConsumptionForm
          ref={formRef}
          ship={wizardShip}
          year={year}
          showButtons={false}
          onStateChange={handleFormStateChange}
          consumptions={fuelConsumptions}
        />
      </Grid>
    </Grid>
  );
};

export default FuelUsageStep;
