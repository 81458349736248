import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import { Icon } from '@iconify/react';
import { IconButton } from '@mui/material';
import type React from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';

interface MenuButtonProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  className?: string;
  menuId: string;
}

export const MoreMenuButton: React.FC<MenuButtonProps> = forwardRef<
  HTMLButtonElement,
  MenuButtonProps
>((props, buttonRef: ForwardedRef<HTMLButtonElement>) => {
  const { isOpen, setIsOpen, className = '', menuId } = props;
  // Handler voor de Escape toets
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isOpen) {
        setIsOpen(false);
        // Focus terug naar de button wanneer menu sluit
        if (buttonRef && typeof buttonRef !== 'function' && buttonRef.current) {
          buttonRef.current.focus();
        }
      }
    },
    [isOpen, setIsOpen, buttonRef]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  return (
    <IconButton
      ref={buttonRef}
      type="button"
      onClick={() => setIsOpen(!isOpen)}
      aria-label="Open menu"
      aria-expanded={isOpen}
      aria-haspopup="true"
      aria-controls={menuId}
      className={`p-2 rounded-full 
        hover:bg-gray-100 
        focus-visible:outline-none 
        focus-visible:ring-2 
        focus-visible:ring-offset-2 
        focus-visible:ring-blue-500
        ${className}`}
    >
      <Icon icon={moreVerticalFill} width={20} height={20} aria-hidden="true" />
    </IconButton>
  );
});

MoreMenuButton.displayName = 'MoreMenuButton';
