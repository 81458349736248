import { PATH_CLIENT } from '@/routes/paths.js';
import useStore from '@/store/use-store';
import clipboardOutline from '@iconify/icons-eva/clipboard-outline';
import dropletOutline from '@iconify/icons-eva/droplet-outline';
import flashOutline from '@iconify/icons-eva/flash-outline';
import plusFill from '@iconify/icons-eva/plus-fill';
import roundPermMedia from '@iconify/icons-ic/round-perm-media';
import { Icon } from '@iconify/react';
import { Box, Button, Card, Container, Tab, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';
import { capitalCase } from 'change-case';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import HeaderBreadcrumbs from '../../HeaderBreadcrumbs';
import Page from '../../Page';
import ShipCover from './ShipCover';
import ShipDetails from './ShipDetails';
import EngineList from './engine/EngineList';
import FuelUsageList from './fuel/FuelUsageList';
import ReportList from './report/ReportList';

const TabsWrapperStyle = styled('div')(({ theme }) => ({
  zIndex: 9,
  bottom: 0,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.up('sm')]: {
    justifyContent: 'center'
  },
  [theme.breakpoints.up('md')]: {
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(3)
  }
}));

export default function ShipOverview() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { pathname } = useLocation();
  const isEdit = !pathname.includes('create');
  const [currentTab, setCurrentTab] = useState('info');
  const [ship, setShip] = useState(null);
  const { getShip, loadingShip } = useStore((state) => state);

  useEffect(() => {
    if (isEdit) getShip(id).then((res) => setShip(res));
  }, [id, getShip, isEdit]);

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const TABS = [
    {
      value: 'info',
      title: t('ship.details'),
      enabled: true,
      icon: <Icon icon={roundPermMedia} width={20} height={20} />,
      component: <ShipDetails loading={loadingShip} ship={ship} isEdit={isEdit} />
    },
    {
      value: 'engines',
      title: t('engines'),
      enabled: !!ship && isEdit,
      icon: <Icon icon={flashOutline} width={20} height={20} />,
      component: ship ? <EngineList ship={ship} /> : <></>
    },
    {
      value: 'fuel_usage',
      title: t('report.fuel_consumption'),
      enabled: !!ship && isEdit,
      icon: <Icon icon={dropletOutline} width={20} height={20} />,
      component: ship ? <FuelUsageList ship={ship} /> : <></>
    },
    {
      value: 'reports',
      title: t('year_reports'),
      enabled: !!ship && isEdit,
      icon: <Icon icon={clipboardOutline} width={20} height={20} />,
      component: ship ? <ReportList ship={ship} /> : <></>
    }
  ];

  return (
    <Page title={t('ship.details')}>
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading={t('ship.details')}
          links={[
            { name: 'Dashboard', href: PATH_CLIENT.root },
            { name: t('my_ships'), href: PATH_CLIENT.ship },
            { name: t('ship.details') }
          ]}
          action={
            <Button
              variant="contained"
              component={RouterLink}
              to="create"
              startIcon={<Icon icon={plusFill} />}
            >
              {t('new_ship')}
            </Button>
          }
        />
        <Card
          sx={{
            mb: 3,
            height: 280,
            position: 'relative'
          }}
        >
          <ShipCover ship={ship} />

          <TabsWrapperStyle>
            <Tabs
              value={currentTab}
              scrollButtons="auto"
              variant="scrollable"
              allowScrollButtonsMobile
              onChange={handleChangeTab}
            >
              {TABS.map((tab) => (
                <Tab
                  disabled={!tab.enabled}
                  disableRipple
                  key={tab.value}
                  value={tab.value}
                  icon={tab.icon}
                  label={capitalCase(tab.title)}
                />
              ))}
            </Tabs>
          </TabsWrapperStyle>
        </Card>

        {TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </Container>
    </Page>
  );
}
