import ShipList from '@/components/_dashboard/ship/list/ship-list';
import { PATH_CLIENT } from '@/routes/paths';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Icon } from '@iconify/react';
import { Button, Card, Container } from '@mui/material';
import { upperFirst } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
import Page from '../components/Page';

const Ship = () => {
  const { t } = useTranslation();
  const pageTitle = t('my_ships');

  return (
    <Page title={pageTitle}>
      <Container maxWidth="lg" component="main" aria-label={t('my_ships_section')}>
        <HeaderBreadcrumbs
          heading={pageTitle}
          links={[
            {
              name: upperFirst(t('dashboard')),
              href: PATH_CLIENT.root,
              'aria-label': t('navigate_to_dashboard')
            },
            {
              name: pageTitle,
              'aria-current': 'page'
            }
          ]}
          action={
            <Button
              variant="contained"
              component={RouterLink}
              to="create"
              startIcon={<Icon icon={plusFill} aria-hidden="true" />}
            >
              {t('new_ship')}
            </Button>
          }
        />
        <Card component="section">
          <ShipList />
        </Card>
      </Container>
    </Page>
  );
};

export default Ship;
