import getAxiosConnection from '../utils/axios';

const initialState = {
  loadingFile: false,
  savingFile: false
};

const fileSlice = (get, set) => ({
  ...initialState,
  getFile: async (id) =>
    getAxiosConnection()
      .get(`file/${id}`)
      .then((res) => res.data.originalName)
      .then((fileName) =>
        getAxiosConnection('application/json', 'blob')
          .get(`file/content/${id}`)
          .then((response) => {
            const { data } = response;
            const downloadUrl = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            return 'success';
          })
      ),
  uploadFile: async (file, onUploadProgress) => {
    if (!(file instanceof File)) {
      return file;
    }
    set({ savingFile: true });
    const dataArray = new FormData();
    dataArray.append('file', file);
    return getAxiosConnection('multipart/form-data')
      .post('files', dataArray, onUploadProgress)
      .then((res) => res.data)
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ savingFile: false });
      });
  }
});

export default fileSlice;
