import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import closeSquareOutline from '@iconify/icons-eva/close-square-outline';
import { Box } from '@mui/material';
import { CardHeader, DetailCard, LabelStyle } from '../../layouts/styles';

const DetailCardView = ({ title, onClose, children }) => (
  <DetailCard sx={{ p: 3 }}>
    <CardHeader>
      <LabelStyle>{title}</LabelStyle>
      <Box
        onClick={onClose}
        component={Icon}
        icon={closeSquareOutline}
        width={35}
        heigh={35}
        sx={{ color: 'text.primary', cursor: 'pointer' }}
      />
    </CardHeader>
    {children}
  </DetailCard>
);

DetailCardView.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired
};

export default DetailCardView;
