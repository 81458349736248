import { toast } from 'react-toastify';

const showToast = (message: string, type: 'warning' | 'error' | 'success' | 'info' = 'info') => {
  switch (type) {
    case 'warning':
      toast.warning(message);
      break;
    case 'error':
      toast.error(message);
      break;
    case 'success':
      toast.success(message);
      break;
    default:
      toast.info(message);
  }
};

export default showToast;
