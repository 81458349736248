import useStore from '@/store/use-store';
import refreshOutline from '@iconify/icons-eva/refresh-outline';
import searchFill from '@iconify/icons-eva/search-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import { Icon } from '@iconify/react';
import {
  Box,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Toolbar,
  Tooltip,
  Typography
} from '@mui/material';
// material
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

const RightBox = styled('div')(({ theme }) => ({
  padding: theme.spacing(10, 0),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

LabelListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func
};

export default function LabelListToolbar({ numSelected, filterName, onFilterName }) {
  const { t } = useTranslation();
  const { getLabels, loadingLabels } = useStore((state) => state);

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter'
        })
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {t('labels.selected', { count: numSelected })}
        </Typography>
      ) : (
        <SearchStyle
          value={filterName}
          onChange={onFilterName}
          placeholder={`${t('search_label')}...`}
          startAdornment={
            <InputAdornment position="start">
              <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          }
        />
      )}

      {numSelected > 0 ? (
        <Tooltip title={t('delete')}>
          <IconButton>
            <Icon icon={trash2Fill} />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title={t('refresh')}>
          <RightBox>
            {loadingLabels && (
              <Typography
                align="right"
                variant="body2"
                sx={{ fontStyle: 'italic', color: 'text.secondary' }}
              >
                {t('refreshing')}...
              </Typography>
            )}
            <IconButton onClick={() => getLabels(true)}>
              <Icon icon={refreshOutline} />
            </IconButton>
          </RightBox>
        </Tooltip>
      )}
    </RootStyle>
  );
}
