import type {
  NestedKeyOf,
  TableHeadItem,
  TableSortProps
} from '@/components/overview-table/table-utils';
import { Box, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import type React from 'react';

interface OverviewTableHeadProps<T extends Record<string, unknown>> extends TableSortProps<T> {
  headLabel: TableHeadItem<T>[];
}

const OverviewTableHead = <T extends Record<string, unknown>>({
  order,
  orderBy,
  headLabel,
  onRequestSort
}: OverviewTableHeadProps<T>): React.ReactElement => {
  const isSortableColumn = (id: NestedKeyOf<T> | ''): boolean => {
    if (id === '') return false;
    return headLabel.find((cell) => cell.id === id)?.sortable ?? false;
  };

  const createSortHandler = (property: NestedKeyOf<T>) => (event: React.MouseEvent<unknown>) => {
    event.preventDefault();
    onRequestSort(property);
  };

  return (
    <TableHead>
      <TableRow>
        {headLabel.map((headCell) => {
          const isSortable = isSortableColumn(headCell.id);
          const isActive = orderBy === headCell.id;

          return (
            <TableCell
              key={headCell.id}
              align={headCell.alignRight ? 'right' : 'left'}
              sortDirection={isSortable && orderBy === headCell.id ? order : false}
              sx={{
                fontWeight: 'bold',
                bgcolor: isActive ? 'grey.200' : 'transparent',
                color: isActive ? 'primary.dark' : 'inherit',
                transition: 'background-color 0.3s'
              }}
            >
              {isSortable && headCell.id !== '' ? (
                <TableSortLabel
                  active={isActive}
                  direction={isActive ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                  aria-label={
                    order === 'desc'
                      ? `${headCell.label} sorted descending`
                      : `${headCell.label} sorted ascending`
                  }
                  sx={{
                    '&.Mui-active': {
                      fontWeight: 'bold',
                      textDecoration: 'underline'
                    },
                    '&:focus-visible': {
                      outline: '2px solid #1976d2', // Een markering rond de focus
                      outlineOffset: '2px',
                      borderRadius: '4px' // Optioneel: rond de randen een beetje af
                    }
                  }}
                >
                  {headCell.label}
                  {isActive ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              ) : (
                <span>{headCell.label}</span>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default OverviewTableHead;
