import type { FormikErrors, FormikTouched } from 'formik';

/**
 * Gets the form field error message if the field has been touched and has an error.
 *
 * @template T - The type of the form values
 * @param {FormikErrors<T>} errors - The form errors object from Formik
 * @param {FormikTouched<T>} touched - The form touched object from Formik
 * @param {keyof T} field - The field name to check for errors
 * @returns {string} The error message or an empty string if no error
 * @throws {Error} If errors or touched parameters are null or undefined
 *
 * @example
 * const error = getFieldError<FormValues>(errors, touched, 'email');
 */
export const getFieldError = <T>(
  errors: FormikErrors<T>,
  touched: FormikTouched<T>,
  field: keyof T
): string => {
  if (!errors || !touched) {
    return '';
  }

  const touchedField = touched[field];
  const errorField = errors[field];

  return touchedField && errorField ? String(errorField) : '';
};
